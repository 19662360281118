import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

//Sub-Components
import { Button } from "../../../components/buttons/buttons";

//Data
import curriculum from "../../../../data/curriculum/curriculum";
import {sessionIcons} from "../sessionJump/sessionJump";
import pageText from "../../../../data/pageText";

//Style Sheet
import "./sessionListItem.css";

import {buildSearch} from "../../../../utilities/buildURL";
import Loading from "../../../components/loading/loading";

let parse = require('html-react-parser');

export function expIcon(){
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-tools"
      viewBox="0 0 16 16">
      <path
        d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.356 3.356a1 1 0 0 0 1.414 0l1.586-1.586a1 1 0 0 0 0-1.414l-3.356-3.356a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0zm9.646 10.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708zM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11z"/>
    </svg>
  )
}

function SessionListItem(props){
  const [imgLoaded, setImgLoaded] = useState(props.format === 'experiment');
  function imgComplete(){
    setImgLoaded(true);
  }
  function checkImgLoad(){
    const img = document.getElementById('sessionImg');
    if (img.complete){
      imgComplete()
    } else {
      setInterval(checkImgLoad);
    }
  }

  useEffect(()=>{
    if (!imgLoaded){
      setInterval(1000, checkImgLoad);
    }
  })
  useEffect( ()=>{
    setImgLoaded( props.format === 'experiment');
  }, [props.image, props.format]);


    const image = new Image();
    image.src = props.image;

    let wideThumb = image.naturalWidth > image.naturalHeight;

    return (
      <div
        id={"sessionWrap"}
        className="lightText position-relative d-flex flex-column justify-content-center align-items-center"
      >
        <Link
          to={{
            pathname: 'session',
            search: buildSearch({
              id: props.currentMaterial[0],
              partNo: props.currentMaterial[1],
              lang: props.language
            })
          }}
          className={'h-100'}
        >
          <button className={"sessionItem"}>
            <div className={"d-flex flex-column justify-content-center align-items-center sessionTitle"}>
              <h2 className="">{pageText.labels.sessionInfo.sessionNo(props.sessionId,props.language)}</h2>
            </div>

            <div id={"mediaEntry"} className={`sessionGraphic ${wideThumb ? "wideThumb" : ""}`}>
              <Loading loading={!imgLoaded}/>
              {
                props.format === 'experiment' ?
                  <div className={'experiment'}>
                    {sessionIcons.experiment}

                  </div>
                  :
                  <img id={'sessionImg'} src={props.image} alt={"Session Graphic"} onLoad={imgComplete} style={{opacity: imgLoaded ? '1' : '0'}}/>
              }
            </div>

            <div className={'materialTitle'}>
              <p>{parse(props.title)}</p>
              {
                props.subtitle ?
                  <p>{props.subtitle}</p> : null
              }
            </div>

          </button>
        </Link>

        <Button
          click={props.nextSession}
          iconType={"pointerRight"}
          class={`changeSession forward ${
            props.currentSession === curriculum.sessions.length
              ? "disabled"
              : ""
          }`}
        />

        <Button
          click={props.backSession}
          iconType={"pointerLeft"}
          class={`changeSession back ${
            props.currentSession === 1 ? "disabled" : ""
          }`}
        />
      </div>
    );
}

export default SessionListItem;
