import React from 'react';

import {MultiLingual} from "../model/multiLingual";
import {Link} from "react-router-dom";
import {buildSearch} from "../../utilities/buildURL";
import pageText from "../pageText";

const chapters = {
  globalWarmingExpress: [
    {
      page: 4,
      name: new MultiLingual(
        'Foreward',
        'Prólogo',
        `L'avant-propos`
      )
    },
    {
      page: 7,
      name: new MultiLingual(
        'Acknowledgments',
        'Reconocimientos',
        `Les remerciements`,
      )
    },
    {
      page: 8,
      name: new MultiLingual(
        'Characters',
        'Personajes',
        'Les personnages'
      )
    },
    {
      page: 14,
      name: new MultiLingual(
        'Map',
        'Mapa',
        'La carte'
      )
    },
    {
      page: 15,
      name: new MultiLingual(
        'Introduction',
        'Introducción',
        `L'introduction`
      )
    },
    {
      page: 21,
      name: new MultiLingual(
        'The Fluff',
        'The Fluff',
        'Le Fluff'
      )
    },
    {
      page: 28,
      name: new MultiLingual(
        'Creamy',
        'Creamy',
        'Creamy'
      )
    },
    {
      page: 33,
      name: new MultiLingual(
        'The Magic Train',
        'El Tren Mágico',
        'Le train magique'
      )
    },
    {
      page: 38,
      name: new MultiLingual(
        'New Mexico',
        'Nuevo México',
        `Le Nouveau-Mexique`
      )
    },
    {
      page: 42,
      name: new MultiLingual(
        'Fire',
        'Incendio',
        `L'incendie`
      )
    },
    {
      page: 47,
      name: new MultiLingual(
        'The Greenhouse Effect',
        'El Efecto Invernadero',
        `L'effet de serre`
      )
    },
    {
      page: 52,
      name: new MultiLingual(
        'Flora',
        'Flora',
        `La flore`
      )
    },
    {
      page: 58,
      name: new MultiLingual(
        'Edgar',
        'Edgar',
        `Edgar`
      )
    },
    {
      page: 63,
      name: new MultiLingual(
        'The Tipping Point',
        'El Momento Crítico',
        `Le point critique`
      )
    },
    {
      page: 69,
      name: new MultiLingual(
        'The Tar Sands',
        `Las Arenas Petrolíferas`,
        `Le sable bitumineux`
      )
    },
    {
      page: 80,
      name: new MultiLingual(
        'Lady Athabasca',
        'Lady Athabasca',
        `Lady Athabasca`
      )
    },
    {
      page: 86,
      name: new MultiLingual(
        'The Gulf Coast',
        'La Costa del Golfo',
        `La côte du golfe`
      )
    },
    {
      page: 95,
      name: new MultiLingual(
        'Acorn Creek',
        'Acorn Creek',
        `Le ruisseau Acorn`
      )
    },
    {
      page: 104,
      name: new MultiLingual(
        'New York City',
        'La Ciudad de Nueva York',
        `New York`
      )
    },
    {
      page: 110,
      name: new MultiLingual(
        'The Storm',
        'La Tormenta',
        `L'orage`
      )
    },
    {
      page: 119,
      name: new MultiLingual(
        'The White House',
        'La Casa Blanca',
        `La Maison-Blanche`
      )
    },
  ]
}

function buildContentsTable(contentId, language){
  return(
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <h2 className={'pageTitle'}>{pageText.labels.readLabels.contents.get(language)}</h2>
      <ol style={{
        backgroundColor: 'var(--light-blue)',
        width: "fit-content",
        borderRadius: '1rem',
        padding: '0 2rem'
      }}>
        {
          chapters[contentId].map(chapter =>{
            return(

                <Link to={{
                  pathname: `/read`,
                  search: buildSearch({
                    format: 'book',
                    contentId: contentId,
                    page: chapter.page,
                    lang: language
                  })
                }}>
                  <li>{chapter.name.get(language)}</li>
                </Link>
              )
          })
        }
      </ol>
    </div>
  )
}

export const altPages = {
  globalWarmingExpress: {
    3:{
      img: null,
      audio: new MultiLingual(
        `${process.env.PUBLIC_URL}/assets/discussion/emptyAudio.mp3`,
        `${process.env.PUBLIC_URL}/assets/discussion/emptyAudio.mp3`,
      ),
      text: new MultiLingual(
        [buildContentsTable('globalWarmingExpress', 'eng')],
        [buildContentsTable('globalWarmingExpress', 'spa')],
        [buildContentsTable('globalWarmingExpress', 'fra')],
      )
    }
  }
}