import React from "react";
import "./video.css";
import { Button } from "../../components/buttons/buttons";
import {useHistory, useLocation} from "react-router-dom";

import YouTube from "react-youtube";
import {buildSearch } from "../../../utilities/buildURL";
import {parseParams} from "../../../utilities/parseQueries";
import {buildNextUpURL} from "../../../utilities/buildURL";

import pageText from "../../../data/pageText";
import {MaterialHeader} from "../../components/materialHeader/materialHeader";

export function Video(props) {
  const history = useHistory();
  //const params = useParams();
  const queries = parseParams(useLocation().search);

  //let partNo = params.partNo ? params.partNo : 1;

  function endHandler() {
    history.push(queries.id ?
      {path: 'session', search: buildSearch(queries)}
      :
      {path: 'library'});
  };

  return (
    <div className="videoWrap">

      {
        props.sessionInfo ?
          <MaterialHeader content={props.content} sessionInfo={props.sessionInfo} language={props.language}/>
          : null
      }

      <Button type={"close"} link={
        queries.id ?
          {
            pathname: 'map',
            search: buildSearch(queries)
          }
          :
          {
            pathname: 'library'
          }
      } />

      <YouTube
        videoId={props.content.src.get(props.language)}
        className={"video"}
        containerClassName={"videoFrame"}
        onEnd={endHandler}
      />

      {
        queries.id ?
          <Button
            text={pageText.labels.readLabels.otherMaterial.next.get(props.language)}
            link={buildNextUpURL(queries)}
          />
          : null
      }

      {/*}

      'Next Page',
        'Página siguiente',
        'Page suivante'

            <iframe 
                className='video'
                src={ props.src }
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
            />

            {*/}
    </div>
  );
}
