
import React, {useCallback, useEffect, useState} from "react";

//Sub-Components
import {PageArrow} from "./pageArrow";
import {ReaderStart} from "./readerStart/readerStart";
import Loading from "../../components/loading/loading";
import {ZoomBook} from "./zoomBook";
import {NextMaterial} from "./nextMaterial/nextMaterial";
import {DiscussionCharacter} from "./discussionCharacter";
import {PageImage} from "./pageImage";
import {TextBox} from "./textBox";

export function ReadContent(props){

  const {
    // Parent States
    page,
    imgLoad,
    pageZoom,
    // Props
    content,
    sessionInfo,
    language,
    //Methods
    nextPage,
    changePage,
    narrationControl,
    mediaLoaded,
    setImgLoad,
    setPageZoom,
    setAudioBubble
  } = props;

  let currentPageImg = content.pageData[page - 1].img;
  let currentPageText = content.pageData[page - 1].text.get(language);
  let currentTextStrict = content.pageData[page - 1].text.get(language, false);

  //States
  const [layout, setLayout] = useState({});
  let [sizeWidth, setSizeWidth] = useState(false);

  //Layout
  function toggleZoom(){
    setPageZoom(!pageZoom)
    setLayout(buildLayout());
  }
  const buildLayout = useCallback((windowWidth, windowHeight)=>{
    let newLayout = {};

    newLayout.isPortrait = windowHeight > windowWidth;
    newLayout.isMobile = windowWidth < 1000 || newLayout.isPortrait;
    newLayout.readerStart = page === content.startPage;
    newLayout.singlePage = function(){
      if (content.format === 'discussion'){
        return true;
      } else {
        if(currentPageImg && currentPageImg.naturalHeight > 0){
          console.log(currentPageImg.naturalHeight)
          return currentPageImg.naturalHeight > currentPageImg.naturalWidth;
        } else if (content.contentId === 'globalWarmingExpress' || newLayout.readerStart) {
          return true;
        }
      }
    }();
    newLayout.textBox = newLayout.readerStart || currentTextStrict;
    newLayout.sideText = newLayout.textBox && !pageZoom && !newLayout.isPortrait && newLayout.singlePage;
    newLayout.bottomArrows = newLayout.isMobile || pageZoom;
    return newLayout;
  },[page, currentPageImg, pageZoom, content, currentTextStrict])
  const checkDimensions = useCallback( ()=>{

    console.log('checkDimensions');

    const imgWrap = document.getElementById('imgWrap');
    const pageImg = document.getElementById('pageImg');
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    if (imgWrap && pageImg) {

      // Is img width maxing out frame?
      if (pageImg.offsetWidth > (imgWrap.offsetWidth * 0.80)) {

        // Does frame have height to spare?
        if (pageImg.offsetHeight < (imgWrap.offsetHeight * 0.95)) {
          // Size by height
          imgWrap.classList.add('sizeWidth');
          if (!sizeWidth){setSizeWidth(true)}
        }

        // Frame doesn't have height to spare
        else {
          // Is img landscape?
          if (pageImg.naturalWidth > pageImg.naturalHeight) {
            imgWrap.classList.add('sizeWidth');
            if (!sizeWidth){setSizeWidth(true)}
          }
          // Is window portrait?
          else if (windowWidth < windowHeight) {
            imgWrap.classList.add('sizeWidth');
            if (!sizeWidth){setSizeWidth(true)}

          } else if (imgWrap.height !== '100%'){

            imgWrap.classList.remove('sizeWidth');
            if (sizeWidth){setSizeWidth(false)}

          } else {

            imgWrap.classList.add('sizeWidth');
            if (!sizeWidth){setSizeWidth(true)}

          }
        }
      }

      // is frame width maxing out window?
      else if (imgWrap.offsetWidth > (windowWidth)){
        // Size by width
        imgWrap.classList.add('sizeWidth');
        if (!sizeWidth){setSizeWidth(true)}
      }

      // Keep default sizing
      else {
        imgWrap.classList.remove('sizeWidth');
        if (sizeWidth){setSizeWidth(false)}
      }
    }
  }, [sizeWidth]);

  //Discussions
  function processDiscussionImages(imgData){
    if (Array.isArray(imgData)){
      if (imgData.length === 1){
        if (typeof imgData === 'string'){
          return (
            <PageImage
              src={imgData[0]}
              className={ pageZoom ? 'zoom' : null }
              checkDimensions={checkDimensions}
              mediaLoaded={props.mediaLoaded}
              setImgLoad={props.setImgLoad}
            />
          )

        } else if (imgData.hasOwnProperty('label')){
          return (
            <DiscussionCharacter
              characterData={imgData}
              gridSize={1}
              zoom={pageZoom}
            />
          )
        }
        //return <img id={'pageImg'} src={imgData[0]} onLoad={checkDimensions} alt={''}/>
        return(
          <PageImage
            src={imgData[0]}
            className={ pageZoom ? 'zoom' : null }
            checkDimensions={checkDimensions}
            mediaLoaded={props.mediaLoaded}
            setImgLoad={props.setImgLoad}
          />
        )

      } else if(imgData.length > 1) {
        if ( imgData[0].hasOwnProperty('label') ){

          function checkCached(src){

            props.mediaLoaded('img', src)
          }

          return imgData.map( charData =>{
            return (
              <DiscussionCharacter
                characterData={charData}
                gridSize={imgData.length}
                zoom={pageZoom}
                imgLoaded={checkCached}
              />
            )
          })
        }
      }
    }
  }

  //DOM Functions
  function textScroll(){
    let text = document.getElementById('sideTrans');
    if (text){
      text.scrollTo(0,0);
    }
  }
  function openScroll(){
    let content = document.getElementById('imgWrap');
    if(content){
      content.scrollIntoView();
    }
  }

  useEffect( ()=>{
    textScroll();

    function handleResize() {
      setTimeout(()=>{
        const newLayout = buildLayout(
          window.innerWidth,
          window.innerHeight
        );
        setLayout(newLayout);
        checkDimensions();
      }, 500);
    }

    window.addEventListener("resize", handleResize);
    const img = document.getElementById('pageImg');
    if (img){
      img.addEventListener('complete', checkDimensions);
    }
    return function(){
      window.removeEventListener('resize', handleResize);
    }

  }, [buildLayout, checkDimensions])

  useEffect(()=>{
    setLayout(buildLayout(window.innerWidth, window.innerHeight));
  }, [page, buildLayout ])

  useEffect(openScroll, []);

  const renderedComponents = {
    pageArrow: function(position) {
      return [
        (
          <PageArrow
            type={"<"}
            className={"default"}
            page={page}
            startPage={content.startPage}
            language={language}
            bottom={ position ? 'bottom' : null }
            //Methods
            changePage={changePage}
          />
        ),
        (
          <PageArrow
            type={">"}
            className={"default"}
            page={page}
            language={language}
            bottom={ position ? 'bottom' : null }
            //Methods
            nextPage={nextPage}
          />
        )
      ]
    },
    readerStart: (
      <ReaderStart
        content={content}
        sessionInfo={sessionInfo}
        language={language}
        page={page}
        imgLoad={imgLoad}
        //Methods
        narrationControl={narrationControl}
        nextPage={nextPage}
        setAudioBubble={setAudioBubble}
        //narrationState={narrationState}
      />
    ),
    translation: (
      <TextBox textData={currentPageText}/>
    ),
    pageWrap: (
      currentPageImg ?
      <div id={'imgWrap'} style={{
        maxHeight:  '100%',
        overflowY: pageZoom ? 'auto' : '',
        //overflowX: 'hidden'
      }} className={` ${
        sizeWidth ? 'sizeWidth' : ''
      } col d-flex align-items-center justify-content-${
        pageZoom && content.format !== 'discussion' ? 'end' : 'center'
      } position-relative flex-wrap p-3`}>

        <Loading
          loading={imgLoad}
        />

        <ZoomBook
          visible={layout.singlePage && !layout.readerStart && !layout.isPortrait}
          zoomFunction={toggleZoom}
          zoomState={pageZoom}
          language={language}
        />

        {
          content.format === 'discussion' ?
            processDiscussionImages(currentPageImg)
            :
            <PageImage
              src={currentPageImg}
              className={ pageZoom ? 'zoom' : null }
              checkDimensions={checkDimensions}
              mediaLoaded={mediaLoaded}
              setImgLoad={setImgLoad}
            />
        }

      </div>
        : null
    )
  }

    return(
      <div style={{
        //border: '0.5rem solid red',
        minHeight: '100vh',
        width: '100%',
        maxWidth: '100vw'
      }} className={'container-fluid readContentWrap p-0 position-relative'}>

        {
          !layout.isMobile && !layout.isPortrait ?
            <div className={`row ${layout.bottomArrows ? 'no-gutters' : ''}  d-flex flex-row justify-content-center align-items-center p-5`} style={{
              //display: "grid",
              //gridTemplateColumns: '1fr 1fr',
              borderColor: 'green',
              height: '95vh',
              width: '100%'
            }}>
              {
                currentPageImg && currentPageImg.length ?
                  renderedComponents.pageWrap
                  :
                  null
              }
              {
                layout.sideText ?
                  <div className={'col d-lg-flex justify-content-center align-items-baseline'} style={{
                    overflowY: 'auto',
                    maxHeight: '100%',
                    borderTop: '0.5rem solid var(--light-blue)',
                    borderBottom: '0.5rem solid var(--light-blue)',
                    //borderRadius: '1rem'
                  }}>
                    {
                      layout.readerStart ?
                        renderedComponents.readerStart
                        :
                        renderedComponents.translation
                    }
                  </div>
                  : null
              }
            </div>
            :
            <div className={'row no-gutters w-100 d-flex justify-content-center'}>{renderedComponents.pageWrap}</div>
        }
        {
          !layout.singlePage || pageZoom || layout.isPortrait || layout.isMobile ?
            <div className={'row no-gutters'}>
              <div className={'col d-flex justify-content-center align-items-center mt-2 mb-5'}>
                {
                  layout.readerStart ?
                    renderedComponents.readerStart
                    :
                    renderedComponents.translation
                }
              </div>
            </div>
            : null
        }
        {
          layout.readerStart && page !== 1 ?
            <div className={'row no-gutters'}>
              <div className={'col d-flex justify-content-center align-items-center mt-2 mb-5'}>
                {renderedComponents.translation}
              </div>
            </div>
            : null
        }
        {
          sessionInfo && sessionInfo.ofParts > 1 ?
            <div className={'d-flex justify-content-end'}>
              <NextMaterial
                sessionInfo={sessionInfo} language={language}
              />
            </div>
            :null
        }

        <div className={`d-flex justify-content-between`} style={{
          position: 'fixed',
          top: layout.bottomArrows ? 'auto' : 'calc(50vh - 4rem)',
          bottom: layout.bottomArrows ? '1rem' : 'auto',
          left : '0',
          zIndex: '5',
        }}>
          {renderedComponents.pageArrow(layout.bottomArrows)[0]}
        </div>

        <div className={`d-flex justify-content-between`} style={{
          position: 'fixed',
          top: layout.bottomArrows ? 'auto' : 'calc(50vh - 4rem)',
          bottom: layout.bottomArrows ? '1rem' : 'auto',
          right : '0',
          zIndex: '5',
        }}>
          {renderedComponents.pageArrow(layout.bottomArrows)[1]}
        </div>

      </div>
      )
}