import React from "react";

export function PageImage(props) {

  function handleLoad() {
    if (props.checkDimensions) {
      props.checkDimensions();
    }

    if (props.mediaLoaded) {
      props.mediaLoaded('img', props.src);
    }
  }

  return (
    <img
      src={props.src}
      id={`pageImg`}
      onLoad={handleLoad}
      className={`${props.className}`}
      alt={""}
    />
  );
}