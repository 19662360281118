import React from "react";

//Style Sheet
import "./infoBubble.css";

export function InfoBubble(props) {
  return (
    <div
      id={props.id}
      className={`infoBubble ${props.type.indexOf('top') !== -1 ? "top" : ""} ${props.type.indexOf('left') !== -1 ? 'left' : ''}`}
      style={props.visible ? { opacity: "1" } : { opacity: "0" }}
    >
      {
        !props.type || props.type.indexOf('top') === -1
          ?
          <div className={"arrow"} /> : null
      }

      <div className={"infoMessage roundBorder"}>
        <p>{props.message}</p>
      </div>

      {
        props.type.indexOf('top') !== -1
          ?
          <div className={`arrow `} />
          : null
      }

    </div>
  );
}
// default - below element stretching right
// top - above element
// left - stretching left