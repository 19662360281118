import React, {useEffect} from "react";

// Style Sheet
import "./libraryList.css";

// Media Data
import { default as get } from "../../../../utilities/contentGetters";
import { experimentsArray} from "../../../../data/experiments/experiments";

// Sub - Components
import LibraryBook from "../libraryBook/libraryBook";
import LibraryExperiment from "../libraryExperiment/libraryExperiment";
import {discussionsArray} from "../../../../data/discussions/discussions";
import LibraryDiscussion from "../libraryDiscussion/libraryDiscussion";
import pageText from "../../../../data/pageText";

let typeLabels = pageText.labels.materialTypes;

export function LibraryBookList(props){
  function getLibraryList() {
    return get.libraryBooks().map((book) => {
      return (
        <LibraryBook
          contentId={book.contentId}
          //key={content.label}
          image={book.graphic}
          changeBook={props.changeBook}
          title={book.title.get(props.language)}
          author={book.author}
          language={props.language}
        />
      );
    });
  }
  useEffect( ()=>{
    let element = document.getElementById(props.sectionId);
    if(element){
      element.scrollIntoView();
    }
  }, [props.sectionId])
  return (
    <div id="libraryList" className={'pageBody LibraryList  p-md-2'}>
      <h2 id={'book'} className={'sectionHeader'}>{typeLabels.books.get(props.language)}</h2>

      <div id="libraryList" className="d-flex flex-wrap justify-content-center align-items-baseline">
        {getLibraryList()}
      </div>

      <h2 className={'sectionHeader'} id={'discussion'}>{pageText.labels.materialTypes.discussions.get(props.language)}</h2>
      <div className="d-flex flex-wrap justify-content-center align-items-baseline">
        {
          discussionsArray.map( discussion =>{
            return(
              <LibraryDiscussion
                contentId={discussion.contentId}
                //key={content.label}
                image={discussion.graphic}
                changeBook={props.changeBook}
                title={discussion.title.get(props.language)}
                author={discussion.author}
                language={props.language}
              />
            )
          })
        }
      </div>

      <h2 className={'sectionHeader'} id={'experiment'}>{typeLabels.experiments.get(props.language)}</h2>
      <div className="d-flex flex-wrap justify-content-center align-items-baseline">
        {
          experimentsArray.map( experiment =>{
            return(
              <LibraryExperiment
                title={experiment.title.get(props.language)}
                contentId={experiment.contentId}
                language={props.language}
              />
            )
          })
        }

      </div>
    </div>
  );
}

export default LibraryBookList;
