// React
import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation,} from 'react-router-dom';

// Sub-Components
import {Audio} from "./audio";
import Congrats from "../../components/congrats/congrats";
import {ReaderControlBar} from "./readControlBar";
import {Preloader} from "./preloader/preloader";
import {MaterialHeader} from "../../components/materialHeader/materialHeader";
import {ReadContent} from "./readContent";

// Style Sheets
import "./read.css";
import "./readerControls.css";
import "./discuss.css";
import "./readMediaQueries.css";
import '../../components/table.css';

// Methods
import {parseParams} from "../../../utilities/parseQueries";
import {buildSearch} from "../../../utilities/buildURL";

export function Read(props){
  //let params = useParams();

  let history = useHistory();
  let location = useLocation();

  //PROPS
  const content = props.content;
  const { startPage } = content;

  // STATES
  const [page, setPage] =  useState(function(){
    if ( parseInt(props.page) ){
      return props.page;
    } else if (content.bookMark){
      if (props.sessionInfo){
        return content.bookMark[props.sessionInfo.sessionId] || startPage;
      } else {
        return content.bookMark.main;
      }
    } else {
      return startPage;
    }
  }()
  );
  const [status, setStatus] = useState("stop");
  const [narrationState, setNarrationState] = useState(false);
  const [audioBubble, setAudioBubble] = useState(false);
  const [showedAudioBubble, setShowedAudioBubble] = useState(false);
  const [pageZoom, setPageZoom] = useState(false);

  // Current Media Load Monitors
  const [imgLoad, setImgLoad] = useState(true);
  const [audioLoad, setAudioLoad] = useState(!!content.preloads.audio.length);
  const [cachedImgs, setCachedImgs] = useState([]);
  const [cachedAudio, setCachedAudio] = useState([]);
  const [cachedPages, setCachedPages] = useState([]);
  const [preloadingImg, setPreloadingImg] = useState("");
  const [preloadingAudio, setPreloadingAudio] = useState("");

  const url = ({
    pathname: location.pathname,
    params: location.search ? parseParams(location.search) : null
  })

  // FUNCTIONS

  // Page Control
  const changePage = useCallback( (newPage, stop)=>{
    if (newPage !== page) {

      if (stop) {
        // Stop Narration
        const audio = document.getElementById("narrator");
        if (audio) {
          audio.pause();
        }
        setStatus("stop");
      }

      // SET STATES
      setPage(newPage);
      setImgLoad(!!content.pageData[newPage - 1].img && content.pageData[newPage - 1].img.length);
      setAudioLoad(true);

      content.bookMark = function(){
        if (props.sessionInfo){
          let bookMark = content.bookMark || {};
          bookMark[props.sessionInfo.sessionId] = newPage;
          return bookMark;
        } else {
          let bookMark = content.bookMark || {};
          bookMark.main = newPage === content.endPage ? 1 : newPage;
          return bookMark;
        }
      }();

      console.log(content.bookMark);

      url.params.page = newPage;

      history.push({
        pathname: location.pathname,
        search: buildSearch(url.params)
      })

      //MISC
      setPageZoom(false);
      clearPageInput();
    }
  },[content, history, location, page, url, props.sessionInfo])
  function nextPage(e, stop) {
    //Check For Completed Book
    console.log('nextPage');
    if (page >= props.content.endPage) {
      openCompleteWindow();
    } else {
      changePage(page + 1, stop ? stop : null);
    }
  }

  // Audio Narration
  function loadNarration(page) {
    const audio = document.getElementById("narrator");
    audio.load();
  }
  const narrationControl = useCallback( (action)=>{
    const audio = document.getElementById("narrator");

    if (audio) {
      let playPromise = audio.play();

      switch (action) {
        case "play":
          console.log('play');
          if (playPromise !== undefined) {

            playPromise
              .then(() => {
                if (status !== "playing") {
                  //setTimeout(function(){setStatus('playing')},5000)
                  setStatus("playing");
                }
              })
              .catch((error) => console.log(error));
          }
          break;

        case "pause":
          audio.pause();
          setStatus("pause");
          break;

        case "restart":
          audio.pause();
          loadNarration(page);
          setStatus("stop");
          break;

        default:
          break;
      }
    }
  }, [page, status])

  // Media Loads
  const checkPageImgsCached = useCallback( (checkPage) =>{

    function checkImage(img){
      if (img.hasOwnProperty('label')){
        img = img.label.split(' ').join('').toLowerCase();
      }
      if (!cachedImgs.some( cachedImg =>{
        let cached = cachedImg.toLowerCase()
        return cached.indexOf(img.toLowerCase()) !== -1;
      })){
        allCached = false;
      }
    }

    let allCached = true;

    if (props.content.pageData[checkPage - 1]){
      let currentImages = props.content.pageData[checkPage - 1].img;

      if ( Array.isArray(currentImages) ){
        currentImages.forEach( img =>{
          checkImage(img);
        })
        return allCached;
      }
      else {
        checkImage(currentImages);
        return allCached;
      }
    } else {
      return true;
    }
  }, [cachedImgs, props.content.pageData])
  function mediaLoaded(type, src) {
    switch (type) {
      case "img":

        if (props.content.format === "book") {
          setImgLoad(false);
          let updatedImgCache = cachedImgs;
          updatedImgCache.push(src);
          setCachedImgs(updatedImgCache);

          preload("img");
        }

        if (props.content.format === "discussion") {
          let updatedImgCache = cachedImgs;
          if (Array.isArray(src)){
            src.forEach((src) => {
              updatedImgCache.push(src);
            });
          } else if (typeof src === 'string') {
            updatedImgCache.push(src);
          }
          setCachedImgs(updatedImgCache);

          if (checkPageImgsCached(page)){
            let updatedCachedPages = cachedPages;
            updatedCachedPages.push(page);
            setCachedPages(updatedCachedPages);
            console.log(updatedCachedPages);
          }

          preload("img");
        }

        if (!props.content.preloads.audio.length) {
          setNarrationState(0);
        }

        if (audioLoad) {
          //loadNarration(page);
        }

        break;

      case "audio":
        //setAudioLoad(0);

        let updatedAudioCache = cachedAudio;
        updatedAudioCache.push(src);
        setCachedAudio(updatedAudioCache);

        preload("audio");

        break;
      default:
        break;
    }

    if (status === "playing") {
      switch (type) {
        case "img":
          //if (!audioLoad) {
            narrationControl("play");
          //}
          break;

        case "audio":
          if (!imgLoad) {
            narrationControl("play");
          }
          break;
        default:
          break;
      }
    }
  }
  function preloadCached(type, src) {
    let updatedCache = type === "img" ? cachedImgs : cachedAudio;
    let setter = type === "img" ? setCachedImgs : setCachedAudio;
    let currentPreloadSetter =
      type === "img" ? setPreloadingImg : setPreloadingAudio;

    if (updatedCache.length >= 2){
      if (type === 'img'){
        if (checkPageImgsCached(page) && checkPageImgsCached(page + 1)){
          setImgLoad(false);
        }
      } else {
        setAudioLoad(false);
      }
    }

    updatedCache.push(src);
    setter(updatedCache);
    currentPreloadSetter("");
    preload(type);
  }
  function preload(type) {
    const preloads = props.content.preloads[type];
    const cached = type === "img" ? cachedImgs : cachedAudio;
    const setter = type === "img" ? setPreloadingImg : setPreloadingAudio;

    function loop(page){
      if (cached.length < preloads.length) {
        let i = page;
        while (i < preloads.length) {
          let check =
            type === "img" ? preloads[i] : preloads[i].get(props.language);

          if (!cached.includes(check)) {
            return setter(check);
          }
          i++;
        }
        if (page > 1){
          loop(0);
        }
      }
    }
    loop(page);
  }
  const checkLoaded = useCallback( ()=>{
    if (audioLoad){
      if (cachedAudio.length >= 2 || !content.narration.get(props.language, false)){
        setAudioLoad(false);
      } else {
        setTimeout(checkLoaded, 1500);
      }
    }
    if (imgLoad){
      const currentImg = document.getElementById('pageImg');
      if (currentImg){
        if (currentImg.complete){
          if (!cachedImgs.includes(currentImg.src)){
            let updatedCache = cachedImgs;
            updatedCache.push(currentImg.src);
            setCachedImgs(updatedCache);
          }
        }
      }
      if (checkPageImgsCached(page) && checkPageImgsCached(page + 1)) {
        setImgLoad(false);
      } else {
        setTimeout(checkLoaded, 1500);
      }
    }
  },[page, content, cachedImgs, cachedAudio, imgLoad, audioLoad, checkPageImgsCached, props.language])

  // DOM Functions
  function clearPageInput() {
    /*
        const pageInput = document.getElementById('pageInput');
        pageInput.value = page;
        pageInput.classList.remove('error');
         */
  }
  function openCompleteWindow() {
    const congrats = document.getElementById("complete");
    congrats.style.display = "inline-flex";
  }
  function showAudioBubble() {
    if (!showedAudioBubble){
      setShowedAudioBubble(true);
    }
    setAudioBubble(true);
    setTimeout(hideAudioBubble, 3000);
  }
  function hideAudioBubble() {
    setAudioBubble(false);
  }

  // UseEffects
  useEffect( ()=>{
    if (status === 'playing' && !imgLoad && !audioLoad){
      narrationControl('play');
    }
    if (imgLoad || audioLoad){
      setTimeout(checkLoaded, 1500);
    }
  }, [page, status, narrationControl, imgLoad, audioLoad, checkLoaded ]);

  return (
    <div className="Read m-0 p-0 d-flex flex-column align-items-center justify-content-center">
      {<ReaderControlBar
        narrationState={narrationState}
        page={page}
        status={status}
        content={props.content}
        language={props.language}
        sessionInfo={props.sessionInfo}
        //Methods
        narrationControl={narrationControl}
        changePage={changePage}
        changeLanguage={props.changeLanguage}
        audioBubble={audioBubble}
        showAudioBubble={showAudioBubble}
        hideAudioBubble={hideAudioBubble}
        showedAudioBubble={showedAudioBubble}
      />}

      <MaterialHeader sessionInfo={props.sessionInfo} content={content} language={props.language}/>

      <ReadContent
        // States
        page={page}
        imgLoad={imgLoad || audioLoad}
        pageZoom={pageZoom}
        // Props
        content={props.content}
        sessionInfo={props.sessionInfo}
        language={props.language}
        // Methods
        nextPage={nextPage}
        changePage={changePage}
        narrationControl={narrationControl}
        mediaLoaded={mediaLoaded}
        setImgLoad={setImgLoad}
        setPageZoom={setPageZoom}
        setAudioBubble={setAudioBubble}
      />

      <div className={"misc row"}>
        <div className={"col"}>
          <Preloader
            preloads={props.content.preloads}
            preloadingImg={preloadingImg}
            preloadingAudio={preloadingAudio}
            page={page}
            //Methods
            setCachedImgs={setCachedImgs}
            preloadCached={preloadCached}
          />

          <Congrats
            language={props.language}
            changePage={changePage}
            sessionInfo={props.sessionInfo}
            format={props.content.format}
          />

          <Audio
            src={props.content.pageData[page - 1].audio.get(props.language, false)}
            language={props.language}
            nextPage={nextPage}
            mediaLoaded={mediaLoaded}
            setNarrationState={setNarrationState}
            page={page}
            startPage={content.startPage}
            narrationControl={narrationControl}
          />

        </div>
      </div>

    </div>
  )
}