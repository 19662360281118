
import React from "react";
import {sessionIcons} from "../../sessionScreen/sessionJump/sessionJump";
//Style Sheet
import "./libraryExperiment.css";
import { Link } from "react-router-dom";
import {buildSearch} from "../../../../utilities/buildURL";

function LibraryExperiment(props){
  return (
    <Link to={{
      pathname: 'experiment',
      search: buildSearch({
        contentId: props.contentId,
        lang: props.language
      })
    }}>
      <button className="libraryExpWrap">

        {sessionIcons.experiment}

        <div className="expHeader" >
          <h2>{props.title}</h2>
        </div>

      </button>
    </Link>
  );
}

export default LibraryExperiment;
