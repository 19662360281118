import React from "react";

//Images
import Train from "./newTrain.png";
import wheel from "./wheel.png";
import BG from '../splashBGscrollCrop.jpg';
import SplashBubbles from "./splashBubbles/splashBubbles";

// Style Sheet
import "./splashTrain.css";

function SplashTrain(props){
  function handleTrainLoad(){
    if (props.loaded){
      props.loaded('body');
    }
  }
  function onWheel1Load(){
    if (props.loaded){
      props.loaded('wheel1');
    }
  }
  function onWheel2Load(){
    if (props.loaded){
      props.loaded('wheel2');
    }
  }
  function onWheel3Load(){
    if (props.loaded){
      props.loaded('wheel3');
    }
  }
  function onWheel4Load(){
    if (props.loaded){
      props.loaded('wheel4');
    }
  }
  function onWheel5Load(){
    if (props.loaded){
      props.loaded('wheel5');
    }
  }
  function onWheel6Load(){
    if (props.loaded){
      props.loaded('wheel6');
    }
  }
  function onBGLoad(){
    if (props.loaded){
      props.loaded('BG');
    }
  }

  return (
    <div className="splashTrain" id={"splashTrain"}>
      <img src={Train} className="trainBody" alt={""} onLoad={handleTrainLoad}/>
      <img src={wheel} className="trainWheel one" alt={""} onLoad={onWheel1Load} />
      <img src={wheel} className="trainWheel two" alt={""} onLoad={onWheel2Load} />
      <img src={wheel} className="trainWheel three" alt={""} onLoad={onWheel3Load} />
      <img src={wheel} className="trainWheel four" alt={""} onLoad={onWheel4Load} />
      <img src={wheel} className="trainWheel five" alt={""} onLoad={onWheel5Load} />
      <img src={wheel} className="trainWheel six" alt={""} onLoad={onWheel6Load} />
      <img src={BG} style={{opacity: '0', position: 'fixed', height: '1px', width: '1px'}}className="trainWheel six" alt={""} onLoad={onBGLoad} />
      <SplashBubbles />
    </div>
  );
}
export default SplashTrain;
