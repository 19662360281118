import { Button } from "../../components/buttons/buttons";
import React from "react";
import {buildSearch} from "../../../utilities/buildURL";
//import {useLocation} from "react-router-dom";
//import {parseParams} from "../../../utilities/parseQueries";

export function ReadClose(props) {

  function buildLink() {
    if (props.sessionInfo) {

      return({
        pathname: 'map',
        search: buildSearch({
          id: props.sessionInfo.sessionId,
          partNo: props.sessionInfo.partNo,
          lang: props.language
        })
      })

    } else {

      return({
        pathname: 'library',
        search: buildSearch({
          section: props.librarySection
        })
      })
    }
  }

  return <Button type={"close"} link={buildLink()} id={"mobileClose"} />;
}
