// React
import React, {useCallback, useEffect,  useState} from "react";

// React Components
import {Route, useHistory, useLocation} from "react-router-dom";

// Sub-Components
import { Splash } from "../views/splash/splash";
import Library from "../views/library/library";
import SessionMap from "../views/sessionScreen/sessionMap";
//import { materialRouter } from "../../utilities/material";
import { AboutGWE } from "../views/aboutGWE/aboutGWE";
import { Guide } from "../views/guide/guide";
import TopNav from "../components/topnav/topnav";
import {Experiment} from "../views/experiment/experiment";
import { Resources } from "../views/resources/resources";
import { Read } from "../views/read/read";

//Data
import books from "../../data/books/books";
import "../../data/curriculum/curriculum";
import {experiments} from "../../data/experiments/experiments";
import {parseParams} from "../../utilities/parseQueries";
import {parsePage} from "../../utilities/parseQueries";

// Style Sheets
import "./App.css";
import "./colors.css";
import "./fonts.css";
import "./elements.css";
import "./animations.css";
import discussions from "../../data/discussions/discussions";
//import curriculum from "../../data/curriculum/curriculum";
//import {Video} from "../views/video/video";
//import {NextUp} from "../views/nextUp/nextUp";
import {SessionRouter} from "../components/sessionRouter/sessionRouter";
import {buildSearch} from "../../utilities/buildURL";
//import * as path from "path";




export function App(props) {

  let location = useLocation();
  let history = useHistory();
  let pathname = location.pathname;
  let queryStr = location.search;
  const queries = parseParams(queryStr);

  const suppLangs = ['eng', 'spa', 'fra'];
  const [language, setLanguage] = useState(queries.lang && suppLangs.includes(queries.lang) ? queries.lang : "eng");
  const [page, setPage] = useState(parsePage(pathname));

  const content = function(){
    if (pathname.indexOf('/read') !== -1){
      if (queries.format === 'book'){
        return books[queries.contentId]
      } else if (queries.format === 'discussion'){
        return discussions[queries.contentId]
      }
    } else if (pathname.indexOf('/experiment') !== -1){
      return experiments[queries.contentId]
    }
  }()

  function selectLanguage(newLang) {
    let queries = parseParams(location.search);
    if (queries.lang !== newLang){
      queries.lang = newLang;
      history.push({
        pathname: location.pathname,
        search: buildSearch(queries)
      })
    }
    setLanguage(newLang);
  }

  const updatePage = useCallback(
    ()=>{
      setPage(parsePage(pathname))
    },
    [pathname]
  )

  useEffect( ()=>{
    updatePage()
  }, [ updatePage ])

  return (
    <div className="App container-fluid">

      <div className={"row w-100 m-0"}>
        <div className={"col no-gutters p-0"}>
          <TopNav
            language={language}
            page={page}
            suppLangs={suppLangs}
            selectLanguage={selectLanguage}
          />
        </div>
      </div>

      <div className={"row w-100 h-100 m-0"}>
        <div className={"col no-gutters p-0 h-100"}>

          <Route exact path="/">
            <Splash
              language={language}
              suppLangs={suppLangs}
              selectLanguage={selectLanguage}
            />
          </Route>

          <Route path={"/guide"}>
            <Guide language={language}/>
          </Route>

          <Route path="/map" >
            <SessionMap
              sessionId={queries.sessionId}
              partNo={queries.partNo}
              language={language}
            />
          </Route>

          <Route path={"/library"}>
            <Library
              language={language}
              sectionId={queries.section}
            />
          </Route>

          <Route path="/resources">
            <Resources language={language} />
          </Route>

          <Route path="/aboutGWE">
            <AboutGWE language={language}/>
          </Route>

          <Route path='/read'>
            {
              content ?
                <Read
                  content={content}
                  language={language}
                  page={parseInt(queries.page)}
                />
                :
                <Library language={language}/>
            }
          </Route>

          <Route path='/experiment'>
            {
              content ?
                <Experiment content={content} language={language}/>
                :
                <Library sectionId='experiment' language={language}/>
            }
          </Route>

          <Route path='/session'>
            <SessionRouter language={language}/>
          </Route>

        </div>
      </div>
    </div>
  );
}
