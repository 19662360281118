import React from "react";

// Sub-Components
//import { SummaryEntry } from "../../components/summary/summary";
import { Button } from "../../components/buttons/buttons";

import pageText from "../../../data/pageText";

// Style Sheets
import "./nextUp.css";
import {useLocation } from "react-router-dom";
//import AboutGWE from "../../components/aboutGWE/aboutGWE";
import curriculum from "../../../data/curriculum/curriculum";
import {parseParams} from "../../../utilities/parseQueries";
import {buildNextSessionURL, buildSearch, buildURL} from "../../../utilities/buildURL";

export function NextUp(props) {
  //const params = useParams();
  const status = props.status;

  let queries = parseParams(useLocation().search);
  delete queries.page;

  function courseComplete() {
    return (
      <div className="nextUpBody">
        <h1 className={"nextUpTitle label"}>{pageText.messages.courseComplete.get(props.language)}</h1>
        <p className="label">{pageText.messages.courseCongrats.get(props.language)}</p>

        <Button link={'/aboutGWE'} text={pageText.buttons.nav.aboutGWE.get(props.language)} language={props.language}/>

        <Button
          text={pageText.buttons.completions.backSessions.get(props.language)}
          link={{
            pathname: 'map',
            search: buildSearch({
              id: 1,
              lang: props.language
            })
          }}
          language={props.language}
          iconType={"leftArrow"}
        />

        <Button
          text={pageText.buttons.completions.repeatSession.get(props.language)}
          link={{
            pathname: 'session',
            search: buildSearch({
              id: queries.id,
              lang: queries.lang
            })
          }}
          language={props.language}
          iconType={"leftArrow"}
        />
      </div>
    );
  }
  function sessionComplete() {
    function repeatSession() {
      curriculum.sessions[queries.id - 1].material.forEach((material) => {

        if (material.content.bookMark) {
          material.content.bookMark = 0;
        }
      });
      queries.id -= 1;
      window.location.href = buildURL('session', queries);
    }

    return (
      <div className="nextUpBody">
        <h1 className={"nextUpTitle label"}>{pageText.messages.sessionComplete.get(props.language)}</h1>
        <p className="label">{pageText.messages.sessionCongrats.get(props.language)}</p>

        <Button
          text={pageText.buttons.completions.continueSession.get(props.language)}
          link={buildNextSessionURL(queries)}
          language={props.language}
          iconType={"rightArrow"}
        />

        <Button
          text={pageText.buttons.completions.backSessions.get(props.language)}
          link={{
            pathname: 'map',
            search: buildSearch(queries)
          }}
          language={props.language}
          iconType={"leftArrow"}
        />

        <Button
          text={pageText.buttons.completions.repeatSession.get(props.language)}
          click={repeatSession}
          language={props.language}
          iconType={"leftArrow"}
        />
      </div>
    );
  }
  /*
  function nextMaterial(material) {
    return (
      <div className="nextUpBody">
        <h1 className="nextUpTitle label">Next Up:</h1>

        <div className={"row"}>
          <SummaryEntry material={props.material} language={props.language} />
        </div>

        <Button
          text="Continue"
          link={`/s${material.sessionInfo.sessionId}/p${material.sessionInfo.partNo}`}
          language={props.language}
          iconType={"rightArrow"}
        />

        <Button
          text="Back To Sessions"
          link={`/map/s-${material.sessionInfo.sessionId}/p-${material.sessionInfo.partNo}`}
          language={props.language}
          iconType={"leftArrow"}
        />
      </div>
    );
  }
   */
  function checkStatus() {
    if (status === "sessionComplete") {
      return sessionComplete();
    } else if (status === "courseComplete") {
      return courseComplete();
    } else {
      queries.partNo ++;
      delete queries.next;
      //return nextMaterial( props.material );
      //let url = buildURL('session', queries);
      window.location.href = buildURL('session', queries) //`#/s${props.material.sessionInfo.sessionId}/p${props.material.sessionInfo.partNo}`;
    }
  }

  return (
    <div className="nextUp controlBox roundBorder darkShadow">
      {checkStatus()}
    </div>
  );
}
