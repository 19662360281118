export function buildURL(path,params){
  return '#/' + path + buildSearch(params);
}
export function buildNextUpURL(params){
  let search = params;
  search.next = 1;
  let url = 'session' + buildSearch(search);
  return url;
}
export function buildNextUpSearch(params){
  params.next = 1;
  return buildSearch(params);
}
export function buildNextSessionURL(params){
  let search = params;
  search.id += 1;
  search.partNo = 1;
  delete search.next;
  let url = 'session' + buildSearch(search);
  return url;
}
export function buildSearch(params){
  let search = '';
  if (params){
    search += '?';
    for( let key in params){
      search += `&${key}=${params[key]}`
    }
    return search;
  }
}
const tests = [
  {
    path: 'session',
    params: {
      sessionId: 2,
      lang: 'fra'
    }
  },
  {
    path: 'read',
    params: {
      contentId: 'globalWarmingExpress',
      format: 'book',
      page: 4,
      lang: 'spa'
    }
  }
]
tests.forEach( test => buildURL(test.path, test.params));