export const translationsFra = {
  commonGround: {
    1:[
    ],
    2:[
      `Puissions-nous prendre nos décisions pour la septième génération.`
    ],
    3:[
      `Il y a longtemps, on a établi un village au terrain communal.`
    ],
    4:[
      `Le terrain communal était le terrain d'entente possédé par tout le monde du village.`,
      `Tous les villageois pouvaient emmener leurs moutons au terrain d'entente pour y brouter.`,
    ],
    5:[
      `Mais, il y a eu un problème.`,
      `Un villageois qui avait beaucoup de moutons se servait plus du terrain d'entente qu'un villageois qui avait peu de moutons ou rein du tout.`,
    ],
    6:[
      `Et parce que l'herbe commune était libre, les gens y mettaient autant de moutons qu'ils pouvaient y brouter.`,
      `Il n'y avait pas assez d'herbe pour tous.`,
      `Ce n'était pas bon pour le terrain communal, ni pour les moutons ni pour les villageois.`,
      `Alors les gens ont fait une des deux choses.`,
    ],
    7:[
      `Certains villageois sont restés dans le village, mais ils ont élaboré un plan ensemble.`,
      `Ils ont accepté de garder les communs luxuriants et verts et de mieux les partager.`,
      `Chaque personne ne pouvait mettre qu'un mouton sur les communs déménager.`,
    ],
    8:[
      `Il y avait toujours un autre endroit où aller.`,
      `De nos jours, le monde ressemble beaucoup à ce village.`,
      `Maintenant nos terrains d'entente sont les parcs, les réserves naturelles, les ressources naturelles, et les eaux et l'air de notre terre entière.`,
      `Aujourd'hui nous avons presque le même problème des villageois.`,
    ],
    9:[
      `De nos jours chaque pêcheur essaie d'attraper autant de poissons que possible dans la mer commune.`,
      `De cette façon, le pêcheur a plus de poisson à vendre - à court terme.`,
    ],
    10:[
      `Mais il y a de moins en moins de poissons.`,
      `Ce n'est pas bon pour les poissons, ni pour les mers, ni pour les gens.`,
    ],
    11:[
      `Aujourd'hui, chaque entreprise de bois veut abattre autant d'arbres que possible pour vendre sous forme de bois, de papier et de combustible.`,
      `Plus l'entreprise de bois abat le nombre d'arbres, plus elle gagne d'argent - à court terme.`,
    ],
    12:[
      `Mais après avoir abattu autant d'arbres, il y a de moins en moins de forêts.`,
      `Ce n'est pas bon pour les arbres, ni pour les créatures des forêts ni pour le sol de la forêt.`,
    ],
    13:[
      `Nous utilisons notre pétrole, notre gaz et notre charbon pour chauffer nos maisons et faire fonctionner nos véhicules.`,
      `Les entreprises les utilisent pour fabriquer des plastiques et d'autres produits chimiques.`,
      `De cette façon, nous pouvons rester au chaud, voyager de longues distances et visiter des magasins pleins de choses incroyables à acheter - à court terme.`,
    ],
    14:[
      `Mais un jour, ces combustibles fossiles seront épuisés.`
    ],
    15:[
      `On a tous besoin d'eau pour cuisiner, boire et se laver.`,
      `Les fermes ont besoin d'eau pour les cultures et le bétail.`,
      `Et les entreprises ont besoin d'eau pour refroidir les équipements et nettoyer les déchets.`,
      `Nous pompons donc autant que possible notre eau commune.`,
      `Ça marche bien – à court terme.`,
    ],
    16:[
      `Mais au fil du temps, les puits s'assèchent et les déchets polluent l'eau.`,
      `Il n'y a pas assez d'eau potable pour tout le monde, les fermes et les entreprises.`,
      `L'eau douce, les combustibles fossiles, les forêts, les poissons - un par un,
      , nous détruisons les ressources naturelles qui soutiennent nos vies.`,
    ],
    17:[
      `Alors, voici notre question commune:`,
      `Si notre pays, nos entreprises et chacun de nous bénéficient davantage à court terme en utilisant autant de ressources naturelles que possible, alors qu'est-ce qui nous empêchera de détruire notre monde entier - notre terrain d'entente?`,
      `Nous devons répondre à cette question ensemble, car aujourd'hui, nous sommes différents de ces villageois d'autrefois d'une manière très importante.`,
    ],
    18:[
      `Nous n'avons nulle part ailleurs où aller.`
    ]
  },
  livingSunlight:{
    1:[
      `La lumière vivante du soleil`,
      `Comment les plantes donnent vie à la terre`,
    ],
    2:[
      `Écoutez-moi.`,
      `Faites cette chose-ci.`,
      `Posez votre main sur votre cœur et sentez-le.`,
      `Sentez votre cœur battre, battre, battre.`,
      `Sentez comment vous êtes chaud.`,
      `C'est ma lumière, vivante en toi.`,
    ],
    3:[
      `Je suis ton soleil, ton étoile d'or.`,
      `Je brûle.`,
      `Ma lumière-énergie éclate dans tous les sens.`,
      `La plupart disparaît dans l'espace sans fin.`,
      `Mais une quantité minuscule arrive à ta petite planète, la Terre.`,
    ],
    4:[
      `Je rechauffe la terre et les mers, fais fondre les glaciers, crée les vents.`,
      `Je fais tout ça.`,
      `Mais je fais beaucoup plus...`,
    ],
    5:[
      `Ma lumière devient l'energie pour toute la vie sur la Terre.`,
      `Toutes les choses vivantes – y compris TOI – pulse avec ma lumière et continue à la circuler en rond de la Terre.`,
      `Comment les choses vivantes le font-elles?`,
      `C'est quoi votre secret?`,
    ],
    6:[
      `Votre secret commence avec les plantes – les plantes vertes.`,
      `Les plantes aspirent de l'eau – H20 – de la Terre.`,
      `De jour, les plantes vertes attrapent mon énergie avec leur chlorophylle.`,
      `Ensuite...KAZAP !`,
      `Les plantes utilisent mon énergie et elles déconstruisent l'eau – le H2O en H et O2, l'hydrogène et l'oxygène.`,
      `Mais comme les plantes déconstruisent l'eau, elles attrapent mon énergie dans les petits paquets.`,
    ],
    7:[
      `Pendant ces temps, les plantes respirent.  (Oui, les plantes respirent!)`,
      `Elles expirent de l'oxygène qu'elles déconstruisaient de l'eau et elles inspirent du dioxyde de carbone – CO2 – de l'air.`,
      `En ce moment, les plantes utilisent les paquets de mon énergie et du dioxyde de carbone pour construire – CHUNKA-CHUNKA-CHUNKA...`,
    ],
    8:[
      `...du sucre!  Et avec ce sucre, les plantes construisent toutes leurs parties.  Toutes les feuilles et tiges et jus, toutes les graines et fruits et fleurs de toutes les plantes de la Terre sont construits du sucre – le sucre de l'air et de l'eau en utilisant ma lumière-énergie.`,
      `C'est la photosynthèse – la fabrication de la vie avec la lumière du soleil, ma lumière.`,
      `C'est mon cadeau de l'énergie à vous.`,
    ],
    9:[
      `Mais attendez!  Vous n'êtes pas vert!`,
      `Vous n'avez pas de feuilles, ni de chlorophylle.`,
      `Vous ne pouvez pas attraper ma lumière.  Vos parents ne peuvent pas l'attraper, ni vos amis, vos professeurs, ni les reptiles, les insectes, les poissons, les oiseaux, les mammifères dans le monde entier.`,
      `Alors....comment obtenez-vous mon énergie? Le savez-vous?`,
    ],
    10:[
      `Oui, vous mangez des plantes.`,
      `Quand vous mangez leurs feuilles et tiges et jus, quand vous mangez leurs graines et fruits et fleurs, vous mangez mon énergie, ma lumière vivante.`
    ],
    11:[
      `Et les plantes font plus que vous donner de la nourriture.`,
      `Rappelez-vous pendant le processus de photosynthèse les plantes vertes respirent de l'oxygène?`,
      `Qu'est-ce qui se passe avec l'oxygène?`,
      `Il remplit l'air.`,
      `Tout l'oxygène que les créatures vivants respirent vient des plantes vertes.`,
    ],
    12:[
      `Inspirez.`,
      `Sentez l'oxygène qui coule dans votre nez, bouche, dans tout votre corps.`,
      `L'oxygène est un cadeau des plantes vertes à vous.`,
      `Votre corps utilise l'oxygène avec les sucres des plantes.`,
      `Votre corps les brûle lentement pour faire l'énergie que vous utilisez pour bouger, grandir et vivre.`,
      `Sans plantes, vous n'avez pas d'oxygène.`,
      `Sans plantes, vous n'avez pas de nourriture.`,
      `Sans plantes, vous ne pouvez pas vivre.`,
      `Sans plantes, il n'y a pas de vie sur la Terre.`,
    ],
    13:[
      `Maintenant, expirez.`,
      `Ce n'est pas de l'oxygène que vous respirez.`,
      `Quand vous utilisez les sucres des plantes pour de l'énergie, les sucres se rompent dans votre corps, en rechangent dans l'eau et le dioxyde de carbone.`,
      `Et maintenant, vous respirez du dioxyde de carbone et les plantes l'inspirent.`, `Elles vont l'utiliser pour construite plus de sucres – leur nourriture et celle d'autres choses vivantes.`,
    ],
    14:[
      `Alors, vous voyez?`,
      `La vie continue à circuler en rond de votre planète, la Terre, par la photosynthèse et par vous-mêmes.`,
      `Vous partagez la vie avec toutes les choses vivantes.`,
    ],
    15:[
      `Mettez votre main au-dessus de votre cœur et sentez-vous.`,
      `Sentez ma lumière dedans.`,
      `Vous gardez ma lumière et l'animer.`,
      `Vous êtes la lumière du soleil vivant.`,
    ]
  },
  myLight:{
    1:[

    ],
    2:[
      `Quand vous voyez les lumières de la ville la nuit, elles ressemblent aux étoiles tombées sur la terre.`,
      `Ces lumières sont la lumière des étoiles - ma lumière.`,
    ],
    3:[
      `Je suis votre soleil d'or.`,
      `Vous voyez mon éclat comme la lumière.`,
    ],
    4:[
      `Chaque jour je rechauffe votre terre et l'eau.`,
      `Petites gouttes d'eau chaude montent et créent les nuages.`,
    ],
    5:[
      `Les nuages se refroidissent.`,
      `Mon énergie tombe comme la pluie.`,
      `L'eau coule des ruisseaux aux rivières qui portent mon énergie en bas, en bas, en bas.`,
    ],
    6:[
      `Un barrage!  Vous, les humaines, vous arrêtez l'écoulement.  Mon énergie est piègée.`,
      `Chut!`,
      `De l'eau coule à toute vitesse dans un tunnel aux turbines géantes.`,
      `Psssiiii!!`,
      `L'eau fait tourner les turbines en rond.`,
      `Elle fait tourner mon énergie aux générateurs, qui fait de l'électricité.`,
      `Maintenant mon énergie est de l'électricité.`,
      `Elle s'écoule dans le réseau cuivre.`,
    ],
    7:[
      `Les fils bourdonnent.`,
      `L’électricité coule, les fils vibrent en bougeant mon énergie à vos villages et villes.`,
    ],
    8:[
      `Chaque jour je brille sur la terre et réchauffe l'air.`,
      `L'air chaude monte.`,
      `L'air froide afflue et fait le vent.`,
      `Chut! Chut!`,
      `Le vent pousse les lames des turbines en rond en tournant mon énergie aux générateurs qui font l'électricité.`,
      `L'électricité afflue dans le réseau cuivre à vos villages et villes.`,
    ],
    9:[
      `Les plantes vertes attrapent ma lumière et utilisent mon énergie pour faire des feuilles et des tiges.`
    ],
    10:[
      `Mon énergie construit chaque arbuste et fleur, roseau et cactus, fruit et arbre.`,
      `Ma lumière nourrit toutes les plantes sur la Terre.`,
      `Des plantes sont mangées.`,
      `Mon énergie coule à tous qui les mangent.`,
    ],
    11:[
      `Des autres plantes meurent et sont enterrées.`,
      `Certaines étaient enterrées il y a des millions et millions d'années et elles sont devenues le charbon.`,
      `À ce temps, toute mon énergie est restée dans la terre retenue dans le charbon.`,
    ],
    12:[
      `Craque!  Rhaaa !!`,
      `De nos jours, vous, les humains, vous creusez du charbon.`,
      `Vous en transporter et le brûler.`,
      `Le feu réchauffe l'eau et la transforme en vapeur.`,
      `Chut!`,
      `Le vapeur coule à toute vitesse contre les turbines géantes qui tournent en rond.`,
      `Elles envoient l'énergie aux générateurs qui font de l'électricité.`,
      `Des centrales alimentées au charbon, l'électricité s'écoule dans le réseau cuivre à vos villages et vos villes.`,
    ],
    13:[
      `Quand l'obscurité tombe, vous appuyez l'interrupteur.`,
      `À l'intérieur d'une ampoule, un fil devient chaud.`,
      `Le fil rougeoie.`,
      `À l'intérieur d'une lampe fluorescente, le gaz est stimulé.`,
      `Il rougeoie.`,
    ],
    14:[
      `Quand vous allumez les lumières la nuit, elles ressemblent aux étoiles tombées sur la terre.`,
      `Ces lumières sont l'énergie du soleil d'or, piègé et transformé par votre terre, et par vous-mêmes.`,
      `Pour un instant, ma lumière illumine vos villages et vos villes.`,
    ],
    15:[
      `Ensuite, comme la lumière des étoiles, d'où elle venait, elle disparaît encore dans l'espace.`
    ]
  },
  riversOfSunlight:{
    2:[
      `Je suis votre soleil.`,
      `Je rechauffe vos journées.`,
      `J'éclaire votre terre.`,
      `Mais de toutes mes planètes, il n'y a qu'une qui grouille de vie – votre Terre.`,
      `Pourquoi?`,
      `Parce qu'elle est couverte de l'eau – H20 – qui bouge toujours, change toujours, d'une liquide à un solide à la vapeur et vice-versa.`,
      `Ensemble, l'eau et moi, nous donnons la vie à votre planète bleue et à vous.`,
    ],
    3:[
      `Presque tout l'eau de la Terre est dans l'eau de mer.`,
      `Pour vous, les océans semblent très profonds.`,
      `Mais ils sont en fait une fine pellicule qui couvre la plupart de votre planète.`,
      `Écoutez-moi!  Si toute l'eau du monde était enroulée en boule, la boule serait seulement grande comme cela par rapport à la Terre.`,
      `Une boule de l'eau douce serait seulement grande comme cela`,
      `et la plupart est enterrée come la glace solide ou de l'eau enterrée sous terre.`,
      `Ce qui reste?`,
      `Juste ce petit peu d'eau douce.`,
      `Cependent, ce petit peu est assez pour garder tous vivants sur terre.`,
      `Parce que l'eau bouge – elle se cycle.`,
      `Elle est utilisée sans cesse.`,
    ],
    4:[
      `Buvez un verre d'eau.`,
      `Sentez l'eau coule dans votre corps.`,
      `La majeure partie de votre corps est faite d'eau.`,
      `L'eau transporte de la nourriture et de l'oxygène à chaque cellule et aide à garder votre température parfaite.`,
      `L'eau que vous buvez reste à votre intérieur pour plusieurs jours et ensuite continue à rinces vos déchets.`,
      `Comme l'eau coule et se cycle à votre intérieur, elle coule et se cycle dans votre Terre – en gardant tous vivants sur terre.`,
      `D'où vient-t-elle l'eau?`,
      `Où va-t-elle?`,
      `Qu'est-ce qui la fait bouger?`,
    ],
    5:[
      `C'est moi, votre soleil!`,
      `Je soulève de l'eau des mers en rechauffant les surfaces des océans.`,
      `Voyez comment ma chaleur fait trembler les molécules de H20 jusqu'à ce qu'elles font un saut dans l'air en laissant leur sel dans la mer.`,
      `C'est l'évaporation!`,
      `L'eau liquide devient un gaz: la vapeur d'eau.`,
      `Les molécules de l'eau pure et douce flottent....en haut...`,
    ],
    6:[
      `...et se dispersent.`,
      `Elles rejoignent un cocon de vapeur d'eau qui enveloppe votre Terre entière.`,
      `Ce cocon attrape un peu de ma lumière-énergie et aide à garder parfaite la température de la Terre pour la vie.`,
      `Une partie de la vapeur d'eau monte encore plus haut vers les parties les plus froides de l'atmosphère .`,
      `Quand une molécule de vapeur d'eau attrape une particule de poussière, soudain des millions d'autres s'emparent pour former une goutte.`,
      `La vapeur d'eau devient encore une liquide.`,
      `Les gouttes s'accumulent dans les nuages.`,
      `Elles deviennent plus gros, plus lourds jusqu'à ce qu'elles se déversent du ciel sous forme de pluie ou de neige et retombent dans la mer.`,
      `Mais un peu de la vapeur d'eau au-dessus de la mer....`,
    ],
    7:[
      `rejoint une rivière de vapeur d'eau volant dans le ciel.`,
      `Mes vents soufflent la rivière à travers le ciel vers la terre.`,
      `Maintenant, il pleut et il neige là-bas.`,
      `La neige s'accumule au sommet des montagnes en hiver.`,
      `Au printemps, elle fond et avec la pluie, elle coule aux ruisseaux, rivières et lacs.`,
      `Voyez qu'un peu d'eau s'infiltre profondément dans le sable et le gravier.`,
      `Ils retiennent l'eau comme une éponge géante posée sur un sol de roche solide.`,
      `Ces éponges souterraines gorgées d'eau sont des aquifères.`,
      `Les aquifères sont des comptes d'épargne d'eau douce qui gardent de l'eau profonde et immobile pendant des milliers d'années.`,
    ],
    8:[
      `Au-dessus d'eux, je garde l'eau en mouvement.`,
      `Je brille sur les lacs, les rivières et le sol détrempé.`,
      `Encore, de l'eau s'évapore dans l'air.`,
      `Je brille sur les arbres et les plantes.`,
      `Ils puisent l'eau du sol et utilise ma lumière pour la photosynthèse en faisant plus de plantes qui nourrissent tous les êtres vivants sur Terre.`,
      `En photosynthétisant, les plantes pompent de la vapeur d'eau dans l'air.`,
      `Il s'élève pour rejoindre la rivière géante dans le ciel.`,
    ],
    9:[
      `Mes vents poussent la rivière volante, donc la pluie et la neige tombent sur les montagnes, les prairies, les`,
      `forêts et les déserts, en nourrissant les plantes là-bas.`,
      `Et ainsi, l'eau se cycle en rond, encore et encore.`,
      `Qu'est-ce qui se passerait si je ne bougeait pas de l'eau?`,
      `Il n'y aurait pas de pluie, ni rivières, ni vie sur votre planète, si je ne bougeait pas de l'eau.`,
      `Et je bouge beaucoup d'eau!`,
    ],
    10:[
      `Chaque année, j'évapore cent quadrillions de litres d'eau douce des mers.`,
      `Chaque année, mes vents soufflent un dixième - dix quadrillions de litres d'eau sur la terre.`,
      `Le reste retombe dans la mer.`,
      `Mais si je continue à déplacer de l'eau douce des mers vers la terre, pourquoi les mers ne deviennent-elles`,
      `pas de plus en plus salées?`,
      `Parce que chaque année, les fleuves du monde renvoient dix quadrillions de litres d'eau douce aux mers,`,
      `remplaçant ce qui a été perdu.`,
      `Je continue à faire bouger l'eau, en la cyclant de la mer à la terre, dans les airs et vice-versa.`,
      `Je garde le cycle en équilibre.`,
      `Et je cycle l'eau dans les mers aussi.`,
    ],
    11:[
      `Oui, je déplace une rivière géante dans les mers.`,
      `Tout comme l'eau circule en vous, pour vous nourrir, vider vos déchets et réguler votre température, la`,
      `rivière océanique fait de même dans toutes les mers.`,
      `Mais, comment?`,
      `Ma lumière réchauffe votre équateur régulièrement toute au long de l'année, de sorte que l'eau de surface`,
      `y reste très chaude.`,
      `Mes vents aident à déplacer un large courant d'eau chaude vers l'ouest, jusqu'à ce que...`,
      `le courant rebondisse contre la terre - les Amériques - et rebondit dans des vortex tourbillonnants.`,
    ],
    12:[
      `Voyez comment le courant chaud charge le nord puis vire vers l'Europe?`,
      `C'est le Gulf Stream - une partie de l'énorme fleuve océanique.`,
      `Au fur et à mesure que le Gulf Stream coule, la chaleur monte de ses eaux, en réchauffant l'air au-dessus.`,
      `Mes vents soufflent cet air chaud sur la terre, où il chauffe la terre.`,
      `Si le Gulf Stream ne coulait pas, les hivers en Europe seraient beaucoup plus froids.`,
      `Comme la chaleur du Gulf Stream s'émane, l'eau du Gulf Stream se refroidit.`,
      `Dans l'extrême nord, une partie gèle en glace solide, expulsant son sel dans la mer.`,
    ],
    13:[
      `Plus léger que l'eau, la glace flotte.`,
      `Le voyez-vous suspendu dans la mer supersaturée de sel?`,
      `L'eau salée est plus lourde que l'eau douce.`,
      `L'eau froide est plus lourde que l'eau chaude.`,
      `L'eau froide contient plus de l'oxygène que l'eau chaude.`,
      `Ainsi, les eaux arctiques froides, lourdes et riches en oxygène plongent et deviennent une cascade`,
      `colossale dans la mer.`,
      `Trois kilometres de profondeur, elle plonge – en poussant la rivière géante le long du fond de l'océan.`,
      `Cette artère d'eau massive est le grand tapis roulant océanique.`,
    ],
    14:[
      `Le tapis roulant serpente dans l'océan profond et sombre, fournissant de l'oxygène aux créatures des`,
      `grands fonds et rassemblant des nutriments qui flottent d'en haut.`,
      `Près de l'Antarctique, le courant se divise en deux.`,
      `Finalement, les deux courants montent jusqu'à l'eau de surface éclairée par le soleil, apportant au`,
      `photoplancton les nutriments qui nourrissent toute la vie océanique.`,
      `Ma chaleur réchauffe les courants.`,
      `La pluie qui tombe dilue leur eau salée.`,
      `Tournant vers l'ouest, les courants se rejoignent et se confondent enfin avec le Gulf Stream pour`,
      `recommencer le cycle.`,
      `Poussé par les changements de sel et de température, avancé par MOI, votre soleil, le grand tapis roulant`,
      `maintient les océans en vie et régule la température de la Terre.`,
    ],
    15:[
      `Oui, mon énergie solaire fait circuler l'eau autour de la planète, dans les mers, le ciel et la terre, donnant`,
      `vie à votre monde.`,
      `L'eau en mouvement change également le paysage terrestre.`,
      `Goutte à goutte, l'eau peut éroser la roche dure.`,
      `Pendant des siècles, il sculpte de profonds canyons dans la terre.`,
      `En s'écoulant sur les roches, l'eau extrait les minéraux essentiels - les nutriments - les livrant à tous les`,
      `êtres vivants, y compris VOUS.`,
      `L'eau s'infiltre dans les fissures et gèle, en brisant les rochers en morceaux, entraînant les gravats vers les`,
      `plaines.`,
      `D'énormes glaciers - des rivières d'eau gelée - creusent des vallées profondes des imposantes montagnes.`,
      `L'eau est douce et cédante mais très puissante.`,
    ],
    16:[
      `Pendant des milliards d'années, moi, votre soleil, j'ai fait circuler de l'eau autour de la Terre alors que vos`,
      `ancêtres habitaient près de lacs et de rivières, afin qu'ils aient suffisamment d'eau.`,
      `Ils ont construit de grandes civilisations en inventant des systèmes hydrauliques - creusant des puits,`,
      `construisant des barrages, des canaux et des aqueducs pour contrôler l'écoulement de l'eau.`,
      `Au fur et à mesure que la population augmentait, l'eau pouvait nourrir des cultures de plus en plus grandes`,
      `et de plus gros troupeaux d'animaux.`,
    ],
    17:[
      `La quantité totale d'eau sur votre planète sera toujours la même.`,
      `Mais de nos jours, plus de sept milliards de personnes vivent sur la Terre, déversant des déchets dans les`,
      `rivières, les lacs et les eaux côtières en utilisant et en déplaçant trop d'eau.`,
      `Certaines rivières s'assèchent.`,
      `Certains aquifères sont vidés plus rapidement que la pluie ne peut les reconstituer.`,
      `L'équilibre hydrique dans le monde est en train de changer.`,
      `De plus en plus d'endroits ont trop peu d'eau.`,
      `La sécheresse assèche les récoltes.`,
      `Plus d'endroits en ont trop.`,
      `Les inondations débordent la terre.`,
      `Au fur et à mesure que votre Terre se réchauffe, l'équilibre changera encore plus.`,
      `Le niveau de la mer augmentera, submergeant les villes côtières.`,
    ],
    18:[
      `Ceci, je le promets.`,
      `Moi, votre soleil, je ferai ma part pour garder l'eau de la Terre propre et fluide.`,
      `Ferez-vous votre part? Trouverez-vous des moyens d'utiliser l'eau avec parcimonie et de la garder propre?`,
      `Rappelez-vous:`,
      `Vous partagez l'eau de la Terre avec tout ce qui est vivant, et votre vie dépend du réseau de la vie.`,
    ]
  },
  globalWarmingExpress:{
    1:[
      `L'express de réchauffement climatique`,
      `par Marina Weber`,
      'Les illustrations par Joanna Whysner',
      `L'avant-propos par le sénateur Tom Udall`,
    ],
    2:[
      `À notre professeur de la troisième année , Barbara McCarthy, qui nous a lancés dans ce voyage.`
    ],
    3:[
      `Le sommaire`,
      `L'avant-propos`,
      `Les remerciements`,
      `Les personnages`,
      `La carte`,
      `L'introduction`,
      `Le Fluff`,
      `Creamy`,
      `Le train magique`,
      `Le Nouveau-Mexique`,
      `L'incendie`,
      `L'effet de serre`,
      `La flore`,
      `Edgar`,
      `Le point critique`,
      `Le sable bitumineux`,
      `LadyAthabasca`,
      `La côte du golfe`,
      `Le ruisseau Acorn`,
      `New York`,
      `L'orage`,
      `La Maison-Blanche`,
    ],
    4:[
      `L'avant-propos`,
      `Mon père disait que chaque génération a rendez-vous avec la terre. Nous détenons la terre en fiducie. C'est notre héritage de ceux qui nous ont précédés. C'est notre obligation envers ceux qui nous suivent. Nous sommes les gardiens de cette planète, de tous ses habitats et de ses trésors naturels.`,
      `Il y a cinq ans, Marina Weber m'a contacté. Elle m'a dit qu'elle travaillait sur un livre sur le réchauffement climatique et a demandé mon aide. Marina était peut-être très jeune, seulement neuf ans, mais elle était très déterminée. Elle était fidèle à sa parole. Elle a écrit son livre et quel livre mémorable il s'est avéré être.`,
      `Marina et son amie talentueuse, Joanna Whysner, qui a fourni de merveilleuses illustrations, ont créé une fable moderne. Ce faisant, elles sonnent l'alarme sur le défi très réel du changement climatique.`, `Dans leur histoire, deux jeunes filles se rendent à Washington, DC dans un train magique. Leur mission: dire au monde qu'il faut changer. Leur leçon: nous pouvons le changer si nous travaillons tous ensemble.`,
    ],
    5:[
      `À chaque étape du voyage, elles rencontrent des animaux blessés, rendus orphelins ou les deux, à cause du changement climatique. Elles voient les dommages causés à notre air, à notre eau et à nos terres.   Au Nouveau-Mexique il y a  des incendies et de la sécheresse est à New York un ouragan; le message est clair. La planète se réchauffe. La Terre est en péril. Nous devons tous faire quelque chose à ce sujet.`,
      `Comme l'explique Marina, nous ne pouvons pas continuer à étouffer la planète sous l'effet des gaz à effet de serre. Notre monde surchauffe. Sans que nous fassions tous notre part, ''la Terre ne peut pas se défaire de sa couverture''.`,
      `Marina et Joanna et leurs amis les animaux, dont un pingouin nommé The Fluff, un perroquet savant nommé Inoah, un ourson noir nommé Tomàs et un ange gardien, un canari, nommé Croissant, continuent. Leur voyage à travers notre pays se fait à bord d'un train alimenté par l'espoir. Et leur appel à nous tous est le suivant: nous devons continuer aussi. Nous ne pouvons pas abandonner non plus.`,
      `L'express de réchauffement climatique est un appel au réveil sur le grand défi du changement climatique - et j'espère que nous entendrons tous.`,
    ],
    6:[
      `Merci Marina pour cette aventure remarquable et pour nous avoir rappelé, comme mon père l'a fait à son époque, que nous devons prendre soin de notre planète. Nous devons le protéger pour votre génération et pour les générations à venir.  - Tom Udall, sénateur des États-Unis du nouveau-Mexique`
    ],
    7:[
      `Les remerciements`,
      `Nous tenons à remercier les nombreuses personnes qui ont contribué à faire de ce livre une réalité.  Nos parents qui ont soutenu l'idée d'écrire et d'illustrer un livre; Amy Bianco pour tant d'aide avec la science, Maggie Blanchard, pour la conception, le sénateur Tom Udall, pour avoir écrit l'avant-propos, et le membre du Congrès Ben Ray Lujan, pour nous avoir écoutés et avoir cru en nous.`,
      `Nous voulons également remercier tous ceux qui font partie du mouvement «Global Warming Express», le conseil d'administration, le conseil consultatif, les stagiaires, les bénévoles, les merveilleux donateurs, le docteur Leslie Lakind, le maire Javier Gonzales, le conseiller Peter Ives,  l'ancien maire David Coss et tous les enfants de GWE partout dans le monde. Merci de vous être joints et d'avoir contribué à faire la différence.`,
      `--Marina & Joanna`
    ],
    8:[
      `Les personnages`,
      `L'auteur Marina Weber est née à Seattle et vit maintenant à Sante Fe, au Nouveau-Mexique, où elle va à la Desert Academy. Marina est une avocate passionnée depuis l'âge de six ans. Marina joue elle-même dans l'histoire. Elle croit qu'il faut réparer les torts et aider les autres à être vus, entendus et aidés. Elle est également intrépide et déterminée lorsqu'elle termine sa quête. Au cours de son voyage, Marina apprend une grande leçon sur le pouvoir limité des adultes et sa propre puissance émergente, et elle apprend que tous les voyages - en fait, presque aucun - ne se déroulent en ligne droite ou n'aboutissent pas au résultat escompté. Marina en vient à croire au pouvoir du voyage et à celui des voyageurs.`
    ],
    9:[
      `L'illustratrice, Joanna Whysner va aussi à la Desert Academy.  Elle a déménageé de Sleepy Hollow, New York, quand elle avait 8 ans.  Joanna est une artiste sérieuse depuis un jeune âge et a remporté de nombreux prix pour son art. Dans l'histoire, Joanna crée son monde sur papier, en lignes, en couleurs et en formes. Son sens des bêtises et son humour l'aident à transformer chaque dessin en commentaire. Pourtant, le sens de l'amusement de Joanna est remis en question par les images terribles et réelles dont elle est témoin dans le train. Le voyage la pousse à regarder sous la surface de ses images et à trouver le courage de dépeindre ce qui fait peur aussi bien que ce qui est agréable. Elle se rend compte que la vérité doit être dite, mais qu'elle peut être acceptée et embrassée.`,
      `Le Fluff est un manchot empereur de l'Antarctique. Le Fluff est un chef pratique et persuasif qui se rend compte qu'il y a quelque chose qui ne va pas dans son monde et qu'il doit aider à y remédier.`,
      `Croissant est un canari qui est pilote pour le Pastry Express.  Il tient les parents informés de leurs déplacements grâce à des notes jaunes.   Vous pouvez le voir dans des illustrations dans le livre.`,
    ],
    10:[
      `Creamy est un phoque femelle du Groenland née au Groenland et élevé dans le zoo de San Diego. Elle a un état d'esprit positif et est compatissante envers les autres, mais elle s'attend à tous les défis qu'elle rencontre au cours de son voyage.`,
      `Inoah est un perroquet femelle amazonien que Joanna garde comme animal de compagnie. Grâce à Inoah, tous les animaux et les filles apprennent tout ce qu'ils doivent savoir sur les causes et les effets du réchauffement climatique. `
    ],
    11:[
      `Tomàs Ascension Leyba Gonzales est un ours noir dont la famille vit sur les mêmes montagnes au Nouveau-Mexique depuis des générations. Lui et son amie Sally, une salamandre des montagnes de Jemez, sont secourus par les filles et emmenés à bord du train.`,
      `Flora est un ours polaire de l'ouest de la baie d'Hudson qui se dirige vers le parc national des Glaciers par voie terrestre. Flora est une petite ours dure mais elle apprend que pour aller de l'avant, elle doit être flexible et optimiste.`,
    ],
    12:[
      `Edgar est un bouc de montagne qui vit dans le parc national des Glaciers. La perte des glaciers et de sa prairie alpine lui a laissé nulle part où aller, alors il décide de rejoindre le voyage.`,
      `Lauren est un caribou des bois boréal qui vit en Alberta, au Canada. Elle a désespérément besoin d'échapper au paysage lunaire que sa maison est devenue avec l'extraction des sables bitumineux et de trouver un endroit plus sûr où vivre.`,
      `Lady Athabasca est une grue blanche qui passe ses étés dans ses aires de reproduction ancestrales dans le parc national Wood Buffalo, Alberta, Canada. Elle propose gracieusement d'escorter les filles et leurs amis sur la côte du golfe du Texas pour en savoir plus sur le déversement de Deepwater Horizon.`,
    ],
    13:[
      `Au golfe, ils rencontre Zolo, un petit canard bavard qui a perdu sa famille lors du déversement de Deepwater Horizon.`,
      `Bobbi Sue Sunfish est un poisson-lune vert du ruisseau Acorn Fork dans le Kentucky. Bobbi Sue ne s'en fiche pas du réchauffement climatique, mais lorsqu'elle se rend compte que les efforts pour le ralentir allaient nettoyer l'eau, elle décide de monter à bord.`,
      `Zingo est un rat brun qui vit dans les égouts de New York. C'est un vieux hippie qui risque de devenir complètement blasé jusqu'à ce qu'il rencontre Marina et Joanna. Avec la lueur militante de retour dans ses yeux, il enseigne aux filles le pouvoir de l'activisme politique.`,
    ],
    14:[],
    15:[
      `Bonjour!  Je m'appelle Marina et je suis ici pour vous parler du réchauffement climatique. Je vais  à la Desert Academy de Santa Fe, au Nouveau-Mexique. Je m'intéresse au changement climatique depuis la troisième année, quand j'ai appris que le réchauffement climatique détruisait les habitats des animaux dans la forêt tropicale. Le réchauffement climatique est très important pour moi car je veux que mes enfants, mes petits-enfants et arrière-petits-enfants aient une vie heureuse sans avoir à se soucier de l'environnement.`,
      `Notre Terre se réchauffe principalement à cause des gaz que nous pompons dans l'atmosphère lorsque nous brûlons des combustibles fossiles pour conduire nos voitures, chauffer nos maisons, alimenter nos appareils et fabriquer des objets.`,
    ],
    16:[
      `Nous devons réfléchir à ce que nous faisons à la Terre car elle évolue rapidement! Maintenant, nous avons peu de temps pour ralentir le réchauffement climatique. Continuez à lire et vous verrez ce que je veux dire.`,
      `J'ai écrit au président Obama et il m'a toujours répondu. Au moins, je pensais qu'il m'écrivait quand j'ai réalisé que toutes les réponses de lui étaient à peu près les mêmes.`,
      `Voici ce que dit l'une des lettres:`,
    ],
    17:[
      `La Maison-Blanche`,
      `le 7 avril 2010`,

      `Cher élève:`,
      `Merci pour votre aimable note. Vos paroles réfléchies rejoignent un chœur d'Américains désireux de conduire notre nation vers un brillant avenir.`,
      `Chaque jour, je suis inspiré par les messages d'espoir et de détermination que j'ai reçus d'étudiants de partout au pays. Les États-Unis ont besoin de jeunes comme vous qui étudient dur, servent votre communauté et rêvent de grands rêves. Notre pays est confronté à de grands défis, mais nous les surmonterons si nous travaillons ensemble.`,
      `L'avenir des États-Unis sera déterminé par notre volonté de nous soutenir mutuellement et d'avancer en tant que nation unifiée.  Avec votre aide, nous nous appuyons sur ce que nous avons déjà accompli et poserons une nouvelle fondation pour un progrès réel et durable.`,
      `Sincèrement,`,
      `Barak Obama`,
    ],
    18:[
      `L'année dernière, j'ai appris que c'est ce qu'on appelle une lettre type. Probablement un collègue du Presdent a lu ma lettre et m'a répondu. Même si j'ai écrit à la main mes lettres au président, il ne les a manifestement pas lues. J'ai donc décidé d'écrire un livre sur le réchauffement climatique et de l'apporter au président.`,
      `Voici ma bonne amie, Joanna. C'est une artiste incroyable, alors je lui ai demandé: "Joanna, je me demandais - et tu peux dire non - si tu ferais des images pour illustrer mon livre."`,
    ],
    19:[
      `Je dois montrer comment les habitats des animaux sont détruits, pour que les adultes comprennent que nous devons faire quelque chose contre le réchauffement climatique. Le Dr James Hansen, un célèbre climatologue, m'a dit que les animaux travaillent dur pour s'adapter au changement climatique, mais je pense qu'ils ont besoin de notre aide. Veux-tu m'aider?"`,
      `Joanna a adoré l'idée du livre et elle a dit: "Appelons cela L'express de réchauffement climatique et invitons d'autres personnes à monter à bord." Alors maintenant, je vais vous parler d L'express de réchauffement climatique  et de notre voyage pour voir le président à Washington, DC.`,
      `L'histoire commence en Antarctique ...`,
    ],
    20:[],
    21:[
      `Par une chaude après-midi de printemps au Cimetière des Pingouins sur Queen Maud Land, en Antarctique, un jeune manchot empereur nommé Fluff pleurait pour sa mère. Elle était décédée après avoir avalé un morceau de plastique flottant dans l'océan.`,
      `Après l'enterrement, sa colonie a offert ses sympathies et est partie, laissant The Fluff tout seul sur un rocher, regardant la mer. Il se souvenait de s'être assis sur ce rocher quand il était beaucoup plus jeune, par une journée de printemps froide et ensoleillée quand ils avaient enterré son grand-père. Maintenant, le rocher était sous l'eau et le soleil lui faisait chaud sur le dos.`,
    ],
    22:[
      `"Pourquoi", s'est-il demandé, ''l’eau est-elle si haute et le soleil si chaud?''`,
      `Il a enlevé son chapeau et s’est gratté la tête avec sa nageoire.`,
      `"Si ça continue," a-t-il pensé, "bientôt il n’y aura plus de Cimetière."`,
      `Le Fluff était très intelligent et avait toujours été d’une grande aide pour sa colonie. Quand un autre pingouin avait un problème, The Fluff avait toujours une solution, mais il ne pouvait pas comprendre ce qui se passait dans son pays. Pour la première fois de sa vie, il a eu peur.`,
    ],
    23:[
      `"Autant que je déteste partir," a-t-il pensé, "je dois trouver la réponse." Son père avait été un capitaine de marine pingouin qui a fait naufrage en mer quand The Fluff était vraiment petit. Tout ce dont The Fluff avait pour se souvenir de lui était son petit canot de sauvetage. Le Fluff regardait la mer, se sentant seul au monde. "J'ai besoin de trouver mon amie, Creamy, a-t-elle pensé." Mais comment puis-je aller la voir? "`
    ],
    24:[
      `Une ampoule s'est allumée dans sa tête. ''Le canot de sauvetage de mon père'', a-t-il pensé. Je peux utiliser ça."`,
      `Le Fluff a pris la mer de Weddell alors que le soleil se couchait.  Il surfait sur les vagues toute la journée et flottait sur le courant la nuit, rêvant de sa rencontre avec Creamy. Au réveil un matin, il a aperçu la terre. Il apprendrait plus tard qu'il était dans le golfe de Californie.`,
    ],
    25:[],
    26:[
      `Puis The Fluff, qui avait vécu toute sa vie dans l'eau froide et la glace, a senti soudain une brise chaude d'été et senti la délicieuse odeur de crevettes. En approchant du rivage, il savait qu'il était loin de chez lui mais beaucoup plus proche de sa chère Creamy. Affamé, inquiet et se sentant soudain très chaud, The Fluff a amarré son bateau et est allé chercher la climatisation et une douche froide.`,
      `Alors qu'il était couché dans son lit d'hôtel cette nuit-là, il s'est demandé comment il retrouverait son ami. Pour autant qu'il sache, elle était toujours au zoo de San Diego.`,
    ],
    27:[
      `Le Fluff est tombé dans un sommeil profond, rempli de rêves gênants. Des images de sa mère et de son père allaient et venaient alors qu'il se retournait et se retournait. Dans chaque image de sa maison, l'océan rampait de plus en plus haut. Le Fluff s'eest réveillé en sursaut. Il s'est assis et il a  réalisé qu'il avait besoin de plus que son vieille amie.  Il avait besoin de l'aide de beaucoup d'autres pour lui-même et son monde. Il avait besoin de l'aide de ceux en qui il n'avait jamais fait confiance ou même rencontrés auparavant.`
    ],
    28:[
      `Creamy`,
      `Le lendemain matin, Creamy s'est réveillée dans sa caverne en plastique au son de fracas et de coups. Un chahut avait surgi juste à l'extérieur de son enclos. Les gardiens de zoo allaient et venaient, criant à propos d'un pingouin qui semblait s'être échappé.`
    ],
    29:[
      `Creamy écoutait alors que l'agitation se rapprochait. Un jeune pingouin de la taille de Creamy se dandinait vers l'enceinte du phoque où vivait Creamy. Alors qu'il ouvrait la porte avec son bec et se précipitait à l'intérieur, il s'est approché de Creamy et demandé: "Où puis-je me cacher?" Elle se précipitait avec le nouveau pingouin vers des roches artificielles à proximité. ''Je suis Creamy'', lui a-t-elle dit. The Fluff l'a regardaée avec étonnement. ''Je t'ai enfin trouvée'', a-t-il dit. "Tu te souviens de moi? Je t'ai rendu visite au zoo quand j'avais 6 ans, alors que je voyageais avec mon père, le capitaine de la marine. Je t'avais dit que je reviendrais te libérer un jour."`
    ],
    30:[
      `"Je me souviens de toi," a-t-elle crié Creamy. "Tu avais des plumes qui dépassaient partout sur toi." "Ouais, c'est pourquoi mon père m'a appelé The Fluff." Puis il a dit: "Pouvez-vous m'aider? Ma terre a besoin d'aide. Vous avez besoin d'aide." Creamy était un phoque femelle du Groenland. Comme tous les phoques du Groenland, Creamy avait été laissée sur la glace lorsqu'elle est née au large de l'est du Groenland alors qu'elle n'avait que 12 jours. Elle n'était pas encore prête à nager quand, à cause du réchauffement climatique, la glace sur laquelle elle se trouvait avait fondu tôt. Elle se serait noyée si deux biologistes au bon cœur ne l'avaient pas trouvée dans l'eau et ne l'avaient pas sauvée. Ils l'ont envoyée au zoo de San Diego et maintenant elle ne reverrait plus jamais ses parents.`
    ],
    31:[
      `Depuis lors, Creamy se demandait ce qui arrivait à la planète. Elle avait toujours été très gentille et avait essayé d'aider ceux qui en avaient besoin. Creamy a eu l'idée de demander aux autres animaux du zoo s'ils savaient quoi que ce soit sur ce qui se passait sur la planète. De cette façon, elle a découvert beaucoup de choses sur l'environnement.`,
      `Maintenant, The Fluff l'avait retrouvée et ils avaient beaucoup de choses à dire. Ils ont parlé jusque tard dans la nuit. Ils ont réalisé qu'ils étaient tous les deux seuls maintenant. Ils avaient besoin l'un de l'autre.  Et ils devaient trouver un moyen d'aider le monde à arrêter de changer si rapidement.`,
      `Ils ont décidé qu'ils avaient besoin d'une sorte de transport humain hors du zoo. «J'ai entendu dire qu'il y avait un vieux train de zoo qui n'a pas été utilisé depuis plus de 30 ans, mais cela pourrait fonctionner», a déclaré Creamy. "On nous a toujours dit qu'il y avait quelque chose de spécial à ce sujet, mais personne ne se souvient de ce que c'était."`,
    ],
    32:[
      `"Génial!"a-t-il dit The Fluff," Allons-y. "`
    ],
    33:[
      `Le train magique`,
      `Cette nuit-là était le solstice d'été, le jour le plus long de l'année. Alors que l'obscurité tombait et que les animaux du zoo se calmaient, les 2 amis se sont faufilés hors de l'enceinte des phoques. En approchant du hangar à machines où le train abandonné était resté inutilisé pendant de nombreuses années, ils ont entendu des sons étranges, comme du métal broyé. Creamy a regardé The Fluff, ses yeux brillants d'excitation.`,
      `"Quelque chose de merveilleux va se passer," a-t-elle murmeré. "Je peux le sentir."`,
      `Regardant par les vitres crasseuses de la remise, ils ont vu le train trembler et frissonner. Ils ont vu des décennies de poussière et de rouille tomber de son corps jusqu'à ce que la vieille machine à vapeur semble scintiller. Puis le hangar a commencé à trembler et à trembler. The Fluff et Creamy tremblaient et cliquetaient aussi alors qu'ils regardaient le train doubler de taille juste devant eux. De belles bulles arc-en-ciel et des papillons flottants remplissaient le hangar et avec un crash! les grandes portes se sont ouvertes et le train est entré dans la nuit claire, éclairée par la lune, s'arrêtant devant les deux amis.`,
    ],
    34:[
      `La portière de la première voiture s'est ouverte et, avant qu'il ne sache ce qui se passait, The Fluff sentit Creamy le pousser dans le train. Creamy était juste derrière, frappant ses nageoires.`,
      `" On y va,"  a-t-elle dit en riant.`,
      `Le nez pressé contre la grande fenêtre, ils regardaient le train bondir en avant au clair de lune devant les cages d'animaux, à travers la ville endormie et vers l'océan. Juste au moment où ils pensaient aller directement dans la mer, le train a tourné brusquement à droite et s'est dirigé vers le nord jusqu'à la côte.`,
    ],
    35:[
      `"Wow! "s'est-il dit The Fluff,« Je me demande où on va?''`,
      `Épuisés par toute l'excitation, ils se sont tous deux effondrés sur des sièges confortables et ont regardé par la fenêtre le paysage qui passait à toute vitesse. Au bout d'un moment, The Fluff a eu une pensée.`,
      `" Comment circule ce train,"  a-t-il demandé. "Je ne vois pas de fumée."`,
      `"Regarde," a-t-elle répondu Creamy en montrant la fenêtre.`,
      `Des bulles et des papillons avaient rempli l'air autour du train. La vue des bulles et des papillons au clair de lune les a rendus tous les deux très heureux et pleins d'espoir. Comme ils l'ont fait, le train a semblé passer à une vitesse plus rapide.`,
      `"Pourquoi avons-nous simplement accéléré?" a-t-il demandé The Fluff. Puis il a eu une pensée inspirée. "Oh, attendez une minute. Peut-être que lorsque nous pensons à des pensées heureuses et positives, le train accélère."`,
    ],
    36:[
      `"Alors, si nous sommes tristes et négatifs, le train ralentira" s'est-elle demandée Creamy. "Essayons-le", a-t-il déclaré The Fluff. " Pensez à une pensée très triste. Pensez à…"`,
      `"- nos parents," a-t-elle dit Creamy.`,
      `Ils se sont regardés les larmes aux yeux. Puis une autre secousse les a fait regarder à nouveau par la fenêtre.`,
      `Le train se déplaçait très lentement. Ils se sont regardés, les yeux écarquillés de compréhension. "Je suppose," a-t-elle murmuré Creamy, "nous ferions mieux de penser positivement."`,
    ],
    37:[
      `Je pense que tu as raison, Creamy", a-t-il déclaré The Fluff. "Nous pouvons le faire!"`,
      `"Oui nous le pouvons!" a-t-elle crié Creamy.`,
      `Puis ils ont exclamé tous les deux: "Allez, beau train. Allons-y!"`,
      `Et encore une fois, le train était entouré de millions de bulles et de papillons. Il a bondi en avant dans la nuit et semblait presque voler.`,
    ],
    38:[
      `Le Nouveau-Mexique`,
      `Le lendemain matin, Creamy s'est réveillée juste à temps pour voir un panneau disant: "Bienvenue au Nouveau-Mexique"`,
      `"Je me demande pourquoi le train nous a emmenés ici", a-t-elle déclaré Creamy. "Nous devrons attendre pour le savoir," répondit The Fluff.`,
      `Il était plus de minuit quand ils se sont arrêtés devant une vieille porte en bois dans un mur de pisé. "Ne sortons pas encore", a-t-il déclaré The Fluff. "Je pense qu'ils viendront à nous, qui qu'ils soient."`,
    ],
    39:[
      `Dans la maison, Marina a réveillé son amie Joanna. "Je pense qu'il y a quelque chose dehors," a-t-elle murmeré.`,
      `"Aaahh, laisse-moi tranquille," a-t-elle baîllé Joanna.`,
      `''Joanna!" Marina l'a poussée du lit.`,
      `"D'accord, d'accord," a-t-elle dit Joanna en se levant. "Dois-je réveiller mes parents?"`,
      `"Eh bien ..." a-t-elle dit Marina en regardant par la fenêtre. "Je ne pense pas. Mais je pense que nous devons de nous habiller."`,
      `Elle a posé son doigt sur ses lèvres. "Crô!" 									 "Oh, non," a-t-elle dit Joanna. "Nous avons réveillé Inoah. Elle ne se tait jamais."`,
      `"Peut-être que nous devrions l'amener," a-t-elle suggéré Marina.`,
      `Joanna a enlevé tranquillement la couverture du haut de la cage du perroquet femelle.`,
      `"Pas de départ sans moi. Pas de départ sans moi!"`,
      `"Chut," a-t-elle dit Joanna en déverrouillant le crochet de la cage d'Inoah. L'oiseau est remonté prudemment son bras et s'est assis sur son épaule. Les filles ont ouvert silencieusement la porte d'entrée, seulement pour trouver un pingouin debout à côté d'un train qui scintillait dans la nuit.`,
      `"Qui êtes-vous?" ont-ils demandé en même temps le pingouin et les filles. Mais avant que quiconque puisse répondre, le train a commencé à bouger.`,
    ],
    40:[
      `"Allez," a-t-il hurlé The Fluff et il a sauté par la porte ouverte. "Nous avons besoin de vous. Le train vous a`,
      `trouvées parce que vous pouvez aider."`,
      `"Quoi?" a-t-elle appelé Joanna. Le train partait.`,
      `"Cours!" a-t-elle crié Marina. "Allons-y."`,
      `Avant qu'ils ne s'en rendent compte, elles étaient embarquées à bord.`,
      `"Je suis tirée par ... des palmes", a-t-elle déclaré Joanna.`,
      `Et puis elles étaient à l'intérieur du train en marche. Creamy et The Fluff ont raconté aux filles comment ils s'étaient échappés du zoo parce qu'ils voulaient aider d'autres animaux, mais qu'ils ne savaient pas où le train allait.`,
    ],
    41:[
      `"En allant à l'est. En allant à l'est." Inoah a interrompu.`,
      `"Attendez. Peut-être que vous étiez venus nous chercher," s'est exclamée Marina. "Joanna et moi voulons rencontrer le président des États-Unis pour lui dire que le réchauffement climatique fait mal aux animaux et qu'il doit être corrigé dès maintenant."`,
      `"Que fait le président?" a-t-il demandé The Fluff.`,
      `"Il contrôle les États-Unis." a-t-elle répondu Marina. "Eh bien, allons-y ensemble."`,
      `Creamy a dit, "Nous voulons aussi faire un changement."`,
    ],
    42:[
      `L'incendie`,
      `À l'intérieur du train, des volutes de fumée flottaient dans l'air.`,
      `"Quelle est cette odeur?' a-t-il demandé The Fluff.`,
      `"Oh," a-t-elle dit Marina, "C'est la fumée d'un feu de forêt. Nous avons toujours eu des incendies en été, mais ces dernières années, il y en a eu plus. Joanna, ça sent comme si ton père prépare le petit-déjeuner."`,
      `Joanna a sourit."   C'est parce que le réchauffement climatique est à l'origine de terribles sécheresses dans cette région," a-t-elle déclaré, de nouveau grave. "Le Nouveau-Mexique est en période de sécheresse depuis des années."`,
      `Le train est entré dans la forêt nationale de Sante Fe. "Eh bien! " a-t-elle dit Creamy," Est-ce que c'est ça un feu? "`,
      `Dehors, le soleil du matin était parti et le ciel était gris. Des flammes s'élevaient au-dessus d'eux, léchant la montagne couverte d'arbres. Les filles et leurs nouveaux amis le regardaient avec horreur.`,
      `"Je pense," a-t-il dit calmement The Fluff, "que parce que le train est magique, nous ne pouvons pas être blessés. Du moins, je l'espère."`,
    ],
    43:[],
    44:[
      `Juste à ce moment-là, ils ont vu un ourson noir blotti sous un arbre. La couronne de l'arbre était en feu. Le train s'est arrêté si soudainement qu'une porte s'est ouverte. Le groupe a couru vers le petit qui semblait dormir.`,
      `"Allons le chercher et montons-le dans le train, puis sortons d'ici." a-t-il dit The Fluff.`,
      `Les deux filles ont soigneusement ramassé l'ours. Elles pouvaient à peine voir le train même s'il n'était qu'à quelques mètres. Dès qu'ils sont montés dans le train, il a décollé. Les portes se sont fermées alors que de la fumée se déversait dans la voiture, avec quelques bulles du train. Alors que Joanna posait doucement le petit ours sur un siège, elle a remarqué quelque chose dans ses pattes.`,
      `"Il tient quelque chose," a-t-elle dit. "Mais, ses pattes - elles sont brûlées", s'est-elle exclamée Marina.`,
    ],
    45:[
      `Les filles se sont regardées désespérément. Silencieusement, The Fluff a désigné les bulles. Ils flottaient vers l'ours endormi et se posaient sur ses pattes avec une étincelle.`,
      `"C'est incroyable", a-t-elle déclaré Marina. "Ses pattes guérissent."`,
      `Le train était déjà loin du feu de forêt lorsque l'ours s'est réveillé. "Qui êtes-vous?" a-t-il dit, "Et qu'est-ce que je fais ici?"`,
      `"Tu es ... nous étions ...," a-t-elle commencé Creamy, mais s'est arrêtée quand une petite salamandre a sauté sur le plancher. C'était si petit que personne ne l'avait même remarquée dans la patte de l'ours. "Voici Sally. C'est une salamandre des montagnes de Jemez."`,
      `"Et mon nom," a-t-il dit fièrement, "est Tomás Ascension Leyba Gonzalez - mais vous pouvez simplement m'appeler Tomás."`,
    ],
    46:[
      `"Je suis spéciale," a-t-elle couiné la petite salamandre alors que The Fluff la soulevait maladroitement.`,
      `"Je suis en-dan-ger."`,
      `"Oui, mi hita, tu es en danger", a-t-il dit Tomás affectueusement à la salamandre.`,
      `" Mi hita, a-t-elle  hurlé Inoah. "Traduction, cherie."`,
      `Tomás a souri et a expliqué qu'il avait perdu ses parents dans un énorme incendie l'été précédent. La même zone avait été brûlée plusieurs années auparavant et les arbres n'avaient pas eu le temps de récupérer.`,
      `"Maintenant, los arboles ne repousseront probablement jamais," a-t-il dit tristement Tomas. "Sally et moi allons devoir trouver un autre endroit où vivre."`,
      `"Arboles," a-t-elle hurlé Inoah. "Cela signifie des arbres."`,
      `"Oh, c'est horrible!" Creamy s'est exclamée.`,
      `"Pourquoi ne viens-tu pas avec nous?"  a-t-elle dit Marina. "Nous allons à Washington, DC pour dire au président qu'il doit faire quelque chose contre le réchauffement climatique. Du moins, c'est vers cela que nous nous dirigeons. Le train semble avoir son propre esprit."`,
      `"Un président?" a-t-il demandé Tomás. "Qu'est-ce que c'est? Et pendant que vous expliquez, pouvez-vous me trouver de la nourriture? Je meurs de faim."`,
    ],
    47:[
      `L'effet de serre`,
      `Au cours du déjeuner, Creamy a demandé à Marina d'expliquer cette chose appelée le réchauffement climatique dont elle avait tant entendu parler.`,
      `"Le climat de la Terre a changé au cours des vingt dernières années environ", a-t-elle déclaré Marina. "Mais pourquoi si vite?" Crémeux a demandé.`,
    ],
    48:[
      `"En partie à cause de ce que les humains ont fait", a-t-elle déclaré Marina, "comme fabriquer des usines et des voitures. Depuis un siècle environ, les humains utilisent des machines pour à peu près tout. Ils brûlent des matières appelées combustibles fossiles - principalement du charbon, du pétrole et le gaz naturel. "`,
      `"Brûlant? Exprès? a-t-elle demandé Creamy`,
      `"Oui", a-t-elle déclaré Joanna, "Nous brûlons des combustibles fossiles pour produire de l'électricité pour les usines, ainsi que pour chauffer et refroidir les maisons et les bâtiments. Nous les utilisons également comme carburant pour les tracteurs, les camions, les trains, les avions et les voitures. Maintenant, tout notre chemin de la vie fonctionne avec des combustibles fossiles."`,
      `"Est-ce pour cela que l'air et l'eau sont si sales?" a-t-il demandé Tomás.`,
    ],
    49:[
      `"Ouais", a-t-elle déclaré Marina, "mais ce n'est pas tout. Cette combustion des combustibles fossiles est en train de changer toute l'atmosphère."`,
      `"Pardon?" Creamy a demandé.`,
      `"L'atmosphère est l'air qui nous entoure", a-t-elle expliqué Joanna. "Il rend la vie sur Terre possible en retenant la chaleur du soleil. Sans lui, notre planète serait une roche nue et froide ou une cendre brûlée, comme les autres planètes."`,
      `"Des planètes? a-t-il demandé The Fluff.`,
      `"Eh bien, oui. Je vous en dirai plus plus tard." a-t-elle dit Joanna.`,
      `Les animaux se turaient et semblaient perplexes.`,
      `Inoah a rompu le silence. "Il y a des gaz dans l'atmosphère – le dioxyde de carbone, le vapeur d'eau, le méthane, le protoxyde d'azote et autres -`,
    ],
    50:[
      `- qui piègent la lumière du soleil afin qu'elle ne puisse pas rayonner dans l'espace. C'est comme la lumière du soleil dans une serre en verre qui réchauffe les plantes, même en hiver. C'est pourquoi on l'appelle l'effet de serre. "`,
      `"Une serre?" Creamy a demandé. "Où est-ce?"`,
      `Soudain, un son de déchirure est venu du mur du fond et un écran sur lequel on montre des films est tombé en un clin d'œil! Il a commencé à leur montrer une vidéo réelle. L'écran leur montrait comme par magie ce qui se passait à l'extérieur du train, mais de manière plus détaillée.`,
      `"Ca ressemble aux gaz à effet de serre», a-t-elle déclaré Marina. "À un chimiste." a-t-elle ajouté Joanna. L'écran brillait de couleur. Des couches de rouge, de gris et de jaune moutarde coulaient les unes dans les autres.`,
      `"Habituellement, nous ne pouvons pas voir le dioxyde de carbone», a-t-elle expliqué Marina en pointant l'écran.`,
      `"Les plantes absorbent le dioxyde de carbone pour grandir. C'est ainsi qu'elles fabriquent leur nourriture." a-t-elle dit Joanna.`,
      `"Alors c'est bon?" Creamy a regardé d'une fille à l'autre.`,
      `"Oui, c'est bien", a-t-elle répondu Marina, "le problème est que nous produisons trop de CO2."`,
      `" CO2 - symbole pour le dioxyde de carbone" , l'a-t-elle interrompue Inoah.`,
      `"Les plantes ne peuvent pas tout absorber", a-t-elle poursuivi Marina. "Alors maintenant, le CO2 supplémentaire et les autres gaz à effet de serre sont bloqués dans l'atmosphère terrestre et emprisonnent trop de chaleur."`,
      `"Ick," ont-ils dit ensemble Creamy et The Fluff en regardant les gaz tourbillonnants sur l'écran. "Comment vous en débarrassez-vous?" a-t-il demandé Tomás.`,
      `Inoah s'est exclamée à nouveau. "Il existe de nombreuses méthodes de séquestration du carbone ..."`,
    ],
    51:[
      `"Une autre fois, Inoah," a-t-elle dit fermement Joanna. "Il vaut mieux éviter d'en faire trop en premier lieu."`,
      `"Mon ami Flynn m'a dit que l'effet de serre était comme ça." a-t-elle dit Marina. ''Imaginez que vous êtes au lit et que vous avez toutes vos couvertures et que vous avez chaud, alors vous les lancez. Ça fait mieux, non? Eh bien, la Terre ne peut pas enlever ses couvertures, donc il fait de plus en plus chaud. C'est le réchauffement climatique et ce n'est pas bon."`,
    ],
    52:[
      `Flora`,
      `Joanna s'est levé tôt le lendemain matin. Toujours en pyjama avec Inoah sur l'épaule, elle a trouvé le frigo et du jus. Puis elle s'est installée dans une chaise confortable dans la voiture d'observation pour regarder le soleil se lever sur des montagnes spectaculaires à pointe blanche. Un signe a passé à toute vitesse. Il disait: ''Bienvenue au parc national des Glaciers''.`
    ],
    53:[
      `Alors que le train fonçait dans le paysage enneigé, Joanna a vu une grosse boule de neige commencer à rouler sur la colline, provoquant un léger éboulement. Elle l'a vue rouler sur les rails et s'arrêter.`,
      `Le train s'est arrêté brusquement et elle a entendu des bosses et des gémissements provenant du wagon-lits.`,
      `Puis Joanna a entendu le grognement de The Fluff, "Ow!"`,
      `Les filles et les animaux sont tous sortis en masse du train pour regarder la mystérieuse boule de neige alors qu'elle roulait hors des rails et s'est levée!`,
      `"C'est un petit ours polaire," s'est-elle exclamée Joanna, "C'est mignon."`,
      `"Qu'est-ce qu'un ours polaire?" a-t-il demandé The Fluff.`,
      `"Un animal très dangereux," a-t-elle répondu Creamy en se cachant derrière Marina.`,
      `"Salut," a-t-elle dit Marina au nouveau visiteur, "puis-je vous aider?"`,
      `"Eh bien, je ne suis pas sûr. Je suis Flora. Euh ... je peux te rejoindre?" elle a demandé. "Je n'ai plus de famille."`,
      `Elle a baissé la tête. "Et j'ai vraiment faim."`,
      `"Eh bien", a-t-il déclaré The Fluff, "bien sûr, vous pouvez. Aucun de nous n'a plus de famille - à l'exception des humains."`,
      `C'était maintenant au tour de Flora d'avoir peur. "Humains?" a-t-elle hoqueté, "Ici?"`,
      `"C'est bon", a-t-il déclaré The Fluff, "Elles sont là pour nous aider."`,
      `"Attendez une minute," a-t-elle dit Creamy, "je suis un phoque et c'est un ours polaire."`,
      `"Oui," a-t-elle dit Flora, "j'ai été élevé pour manger des phoques, mais ..."`,
      `Le Fluff l'a coupée. "Eh bien, je suis sûr que nous pouvons trouver quelque chose, mais pour le moment, le train est sur le point de partir."`,
      `Des bulles et des papillons commençaient à les entourer, ce qui était la manière silencieuse du train de redémarrer son moteur. Une fois que tout le monde était à bord, Flora a demandé: "Alors, qui sont ces humains?"`,
    ],
    54:[
      `"Ce serait nous." Marina et Joanna se dirigeaient vers l'ours polaire.`,
      `"Oh petits humains," s'est-elle exclamée Flora, "Comme c'est mignon."`,
      `"Eh bien, oui. Je suis Marina et voici Joanna."`,
      `"De toute façon, j'ai vraiment faim," a-t-elle dit Flora, "Quelqu'un a-t-il quelque chose à manger? Je n'ai pas mangé ... "- elle s'est arrêtée pour réfléchir -" deux mois. "`,
      `"Wow," a-t-il dit Tomás, "ça fait longtemps."`,
      `"Les ours polaires chassent les phoques à travers la glace en hiver et au printemps", a répondu Inoah. "Lorsque la glace fond, ils doivent attendre sur terre que l'eau gèle et qu'ils puissent à nouveau manger. "`,
    ],
    55:[
      `Flora regardait fixement Inoah. "Qu'est-ce que c'est?" elle a demandé.`,
      `"C'est Inoah. C'est un perroquet femelle." a-t-elle dit Joanna. "Elle sait tout sur tout, même les ours polaires."`,
      `"Tu ne dis pas," a-t-elle dit sarcastiquement Flora, "De toute façon, mes parents m'ont dit que la glace fondait de plus en plus tôt ces dernières années, réduisant encore plus notre temps de repas.`,
      `"Ici," a-t-elle dit Marina, "nous allons te montrer la voiture-restaurant. Tu peux simplement demander ce que tu veux et il apparaîtra."`,
      `"Oh cool." a-t-elle dit Flora en se dirigeant vers la porte. Quelques minutes plus tard, la bouche pleine de baies, Flora a marmonné: ''Je viens de l'ouest de la baie d'Hudson.''`,
      `"Pardon?" Sally a intervenu.`,
    ],
    56:[
      `Flora a avalé sa bouchée. " Je suis venu ici de l'ouest de la baie d'Hudson. Je nage et patauge depuis des mois, à la recherche de glace parce que le printemps dernier, la glace s'est brisée tôt. Mes parents nageaient et je me suis retrouvée piégée seule sur une petite couche de glace de mer qui s'était détachée de l'écoute principale. C'est arrivé si vite. C'était trop loin pour revenir à la nage. Je ne savais pas quoi faire!"`,
      `Flora a expliqué un peu plus. "Je vis - enfin, je vivais - dans un igloo. C'était très agréable. Mon père dirigeait une entreprise locale et ma mère dirigeait une école maternelle pour les oursons polaires. J'avais beaucoup d'amis. Quoi qu'il en soit, je pensais que si je pouvais simplement continuer à flotter, je finirais par toucher la terre, mais j'avais peur que mon petit morceau de glace fondre trop tôt."`,
    ],
    57:[
      `"J'ai attendu deux couchers de soleil et deux levers de soleil, puis j'ai décidé de sauter et de nager pour trouver un terrain. Je suis descendu une longue rivière, puis sur cette terre. J'avais marché et marché à travers ces montagnes enneigées jusqu'à ce que je suis tombée et roulé jusqu'à vous.`,
      `"Wow, quelle histoire," a-t-elle dit Creamy. Ses yeux étaient remplis de larmes.`,
      `"Je suis tellement désolée que tu aies dû supporter tout ça."`,
      `Elle s'est levée et hésité un moment, puis a fait un gros câlin à Flora.`,
    ],
    58:[
      `Edgar`,
      `Le train montait fortement pendant que Flora parlait. Enfin, il s'est arrêté sur un énorme morceau de glace caillouteux. Tout autour, il y avait de la neige épaisse. Creamy et The Fluff ont pratiquement décollé du train, se renversant dans leur hâte. "Sortons dans la neige" , ont-ils crié.`,
    ],
    59:[
      `Flora les suivait, se dirigeant vers la glace. Marina, Joanna et Inoah sont venues après, toutes frissonnantes. Soudain, Flora a aperçu quelque chose. Sur un rebord rocheux au-dessus de leurs têtes, un grand animal blanc aux cornes pointues les regardait.`,
      `"Salut, je suis Edgar." Il a sauté facilement pour les rejoindre.`,
      `"Je suis une chèvre de montagne. Et vous êtes ...?" il les a regardés d'un air interrogateur.`,
      `Les animaux et les filles ont pris du recul.`,
      `Tout de suite, The Fluff les a présentés.`,
      `"C'est bon de revoir de la glace", a-t-elle sourit Flora.`,
    ],
    60:[
      `"Cette glace est de la neige qui fond et se recongèle chaque année et qui se compresse", a-t-il déclaré Edgar d'une voix grave et grondante. "Le tout rampe dans la montagne, avec de la neige fraîche ajoutée au sommet et de l'eau fondant au bas. Les scientifiques viennent ici chaque été pour le mesurer parce qu'il rétrécit."`,
      `"Tout comme les glaçons dans ma baie," a-t-elle déclaré Flora. "Les scientifiques prédisent que tous les glaciers du parc national des Glaciers disparaîtront dans les dix à vingt prochaines années", a-t-elle annoncé Inoah.`,
      `Edgar a secoué lentement la tête. "Alors les ruisseaux d'été se tariront." Des larmes ont commencé à couler des grands yeux noirs de Creamy alors qu'elle écoutait.`,
    ],
    61:[
      `La voix d'Edgar était triste alors qu'il continuait. "Les animaux et les plantes ici ont l'habitude de vivre dans certains endroits de la montagne. À mesure que les étés se réchauffent, nous continuons tous à nous déplacer plus haut là où il fait un peu plus frais. C'est ce qu'on appelle migrer, mais il n'y a nulle part ailleurs où aller. Nous vivons déjà. au sommet des montagnes, au-dessus de la limite des arbres. Maintenant les arbres poussent ici donc ils font de l'ombre qui empêche l'herbe de recevoir la lumière du soleil dont elle a besoin, donc elle meurt. Bientôt, il n'y aura plus rien à manger. "`,
      `"Donc qu'est ce que tu vas faire?" a-t-il demandé Tomás.`,
      `"Tu pourrais venir avec nous,"  a-t-elle dit Marina.`,
      `"Ah, c'est très gentil de ta part," a-t-il dit Edgar, "mais j'ai vécu ici toute ma vie."`,
      `"Je sais ce que tu ressens," a-t-il dit Tomás. "Je devais aussi quitter ma montagne. Nous devons nous adapter."`,
      `Edgar a regardé son pré et a soupiré.   Le silence est tombé.`,
      `"Nous voyageons tous ensemble parce que nous avons besoin d'aide", a-t-il expliqué The Fluff. "Nous ne pouvons pas rentrer chez nous tant que nous n’aurons pas obtenu d’aide pour sauver nos terres."`,
      `"Et nous-mêmes," a-t-elle ajouté Flora.`,
      `"Je vois ce que tu veux dire," a-t-il dit Edgar. "Je déteste partir mais quelqu'un doit être au courant de nos problèmes."`,
      `"Nous allons voir le président des États-Unis", a-t-elle déclaré Marina. "Il va vous aider."`,
      `"Qu'est-ce qu'un président?" a-t-il demandé Edgar.`,
      `Joanna a sourit. "Nous vous expliquerons au fur et à mesure. Allez. Montons dans le train. Plus tôt nous partirons, plus vite nous aurons de l'aide."`,
      `"Aide, aide, aide," a-t-elle hurlé Inoah alors que tout le monde se dirigeait péniblement vers la porte ouverte du train.`,
    ],
    62:[],
    63:[
      `Le point critique`,
      `Alors que le train recommençait à bouger, The Fluff s'est déplacé pour se tenir à côté de Marina.`,
      `"Où est exactement Washington, DC?" a-t-il demandé pensivement, en regardant passer le paysage montagneux.`,
      `"C'est sur la côte extrême-est de ce pays. C'est une ville immense où travaillent tous les adultes qui dirigent le pays. C'est là que le président vit et travaille."`,
      `"Oh," a-t-il dit The Fluff, "y a-t-il toujours vécu? Ou a-t-il migré?"`,
    ],
    64:[
      `Marina a éclaté de rire. "Eh bien, je suppose que vous pourriez dire qu'il a émigré. Il a été envoyé vivre ici par le peuple des États-Unis. Il s'appelle être élu. Il a été choisi pour diriger le pays, et donc il a déménagé avec sa famille à Washington, DC. Il sera là pendant un moment, puis il déménagera."`,
      `"Et puis quelqu'un d'autre va déménager là-bas et être responsable?" a-t-il demandé The Fluff.`,
      `"Oui," a-t-elle dit Marina, "nous ne savons pas qui ce sera. Et nous ne pouvons pas attendre. Nous devons amener ce président à nous écouter maintenant."`,
      `"On a l'impression que le train se dirige vers le nord", a-t-elle crié Flora.`,
      `"Je me demande où cela nous mène", a-t-elle déclaré Marina. "J'espère que nous tournerons bientôt vers l'est. Nous manquons de temps."`,
      `Le train était descendu des montagnes et traversait une large plaine plate. Marina a repéré une plate-forme pétrolière à l'horizon. "Regarde ça," a-t-elle pointé. C'est une machine pour pomper le pétrole du sol. "`,
    ],
    65:[
      `A-t-il dit Inoah. "Dans les champs pétrolifères conventionnels, le pétrole est mis en commun sous terre et les machines le creusent et le pompent. Le gaz naturel peut également être foré de cette façon."`,
      `"Les humains ont déjà épuisé la plupart des combustibles fossiles qui peuvent être forés ou extraits si facilement", a-t-elle ajouté Marina.`,
      `Inoah ébouriffait ses plumes en continuant bruyamment. "Aujourd'hui, les sociétés énergétiques recherchent des moyens plus difficiles d'obtenir des combustibles fossiles en forant profondément dans l'océan et dans des réserves naturelles éloignées."`,
      `"Je sais ce que sont les conserves - miam", a-t-elle dit Joanna.`,
      `Avec un profond soupir, Inoah l'a corrigée. "Pas de ce genre! Nous parlons de terres qui sont préservées, gardées en sécurité et précieuses. Tu vois?  Les compagnies pétrolières forent dans cette terre spéciale parce que pour elles, le trésor est le pétrole."`,
      `"Comme là où je vivais au milieu des étendues sauvages de l'Arctique", s'est-elle écriée Flora. "Il y a tous ces gens là-bas, avec de grosses machines, creusant des tranchées et construisant des pipelines."`,
      `"S'il vous plaît," a-t-elle crié Creamy. "C'est tellement mauvais. Faut-il en parler?`,
      `Le train ralentit beaucoup."`,
      `"Mais tu as besoin de savoir," a-t-elle répondu Inoah. Il est important de connaître les faits."`,
      `Tout le monde se regardait dans un triste silence.`,
      `"Ensuite, nous avons l'hydro-fracturation et l'extraction de l'huile des sables bitumineux."`,
      `"Ok, Inoah, merci de nous avoir raconté les faits", a-t-elle dit Joanna.`,
      `"De rien," a-t-elle dit fièrement Inoah.`,
      `Il y a eu un autre long silence dans la voiture.`,
      `Enfin, The Fluff a dit: "Marina? Tu as dit que le réchauffement climatique évolue trop vite, mais il semble que les gens travaillent dur pour faire brûler plus de combustibles fossiles, plutôt que de trouver plus de moyens d'obtenir de l'énergie."`,
    ],
    66:[
      `"Oui", a-t-elle déclaré Creamy, "tu as dit que la combustion de combustibles fossiles emprisonnait trop de chaleur dans l'atmosphère terrestre, et il faut l'arrêter."`,
      `"C'est vrai," a-t-elle répondu Marina, "La Terre s'est déjà réchauffée d'environ 0,8 degré au cours du siècle dernier et cela cause beaucoup de dégâts."`,
      `"Cela ne ressemble pas beaucoup", a-t-elle interrompu Sally. "N'est-ce pas .8 encore moins que 1?"`,
      `"Eh bien, c'est en mesure Celsius; c'est 1,5 degré en Fahrenheit, la température à laquelle nous sommes habitués."`,
      `"Mais 1,5, n'est-ce pas moins de 2?" a-t-elle demandé Sally.`,
      `"Oui, Sally, c'est moins de 2, mais pour la Terre, même un degré est un gros problème. C'est comme si la température de mon corps augmentait de 2 degrés."`,
      `"Ta mère t'emmènerait chez le médecin," a-t-elle dit Joanna.'`,
      `"Cela s'appelle une fièvre", a-t-elle ajouté Marina, "et en ce moment, la Terre a de la fièvre. Si elle continue de devenir de plus en plus malade, tous les animaux et plantes qui y vivent vont mourir."`,
      `Tout le monde est devenu silencieux. Le train s'est arrêté.'`,
      `"Et certaines personnes ne sont toujours pas d'accord pour dire que toute cette activité humaine est à l'origine de ce réchauffement rapide", a-t-elle déclaré Joanna.`,
      `"Eh bien, ce n'est certainement pas une activité animale," a-t-elle couiné Sally."`,
      `"Bien," a-t-elle déclaré Joanna, "et pendant qu'ils se disputent avec les gens qui veulent du changement en ce moment, nous continuons à brûler des combustibles fossiles.`,
      `"Ce que les scientifiques comprennent,"  a-t-elle poursuivi Marina, "c'est que même si nous acceptions tous d'arrêter de brûler des combustibles fossiles dès maintenant - aujourd'hui - la Terre continuerait à se réchauffer à cause de tout ce qui se trouve dans l'atmosphère. Nous mettons la planète en danger simplement en brûlant les combustibles fossiles que nous avons déjà, sans parler de ceux qui sont difficiles à obtenir. Bientôt, nous atteindrons le point critique où le changement climatique excès de vitesse si vite qu'il devient incontrôlable.`,
    ],
    67:[
      `"C'est comme ça," a-t-elle expliqué Joanna. "C'est comme si la Terre était une boule qui gravit une montagne. Vous la regardez et vous voyez qu'elle n'est qu'à mi-chemin du sommet."`,
      `"Si elle arrive au sommet, alors nous n'avons vraiment aucun espoir, car elle tombera directement de l'autre côté. Nous pouvons la ralentir, même si nous ne pouvons pas la faire reculer. Mais si nous arriverons au point critique, qui est le sommet de la montagne, nous pouvons oublier de ne jamais la ralentir."`,
      `"C'est horrible, a-t-elle dit Creamy." Comment les humains peuvent-ils faire ça à nous tous? "`,
      `"Comment peuvent-ils se faire eux-mêmes", a-t-il demandé The Fluff."`,
      `"Écoutez, vous tous," a-t-elle dit Marina. "Ce n'est pas parce que nous savons la vérité que nous devons la laisser nous arrêter. Ensemble, nous pouvons le faire! Nous allons aller voir le président. Nous pouvons tous lui raconter nos histoires et il peut aider. Je le sais!"`,
    ],
    68:[
      `Comme si le train l'avait entendue, il soufflait de plus en plus vite, produisant de plus en plus de bulles.`
    ],
    69:[
      `Les sables bitumineux`,
      `"Écoutez!" at-t-il déclaré Tomás, "nous venons de passer un panneau disant: " Bienvenue en Alberta, la capitale pétrolière du Canada"`,
      `"Le train naviguait tranquillement à travers une immense forêt de pins, mais maintenant il ralentiait à ramper. Avec un retournement, l'écran magique a roulé vers le bas. Tout le monde se pressait. Sur l'écran se trouvait une vidéo de la terre vue d'un avion - une forêt verte s'étendant sur des centaines de kilomètres. La vidéo a zoomé et les filles et les animaux ont vu qu'à la lisière de la forêt se trouvait une terre sombre, avec de nombreuses rivières et lacs. Un lac était si grand qu'il semblait n'avoir aucune fin.`,
      `"L'océan!" Creamy a crié.`,
        `Mais bientôt les bords du lac ont disparu. De grands animaux s'en éloignaient, à travers la terre sombre et riche. Ils avaient des bois et ressemblaient à des cerfs, car ils se déplaçaient à la queue leu leu. L'un d'eux a levé les yeux vers le ciel bleu clair.`,
    ],
    70:[
      `Soudain, l'image a changé. Au lieu des forêts et de la terre sombre, il y avait des kilomètres d'arbres morts. À certains endroits, ils avaient été enlevés et le terrain était complètement gris, couleur de béton.`,
      `Les nouveaux «lacs» que les filles et les animaux ont vus étaient jaune-vert comme s'ils étaient malades, au lieu d'être d'un beau vert-bleu, ou ils étaient noirs d'huile ou même d'une étrange couleur orange-vert. Mais le ciel était encore pire. D'épais panaches de fumée provenant d'innombrables cheminées remplissaient l'air autour de kilomètres de tuyaux. Puis l'écran s'est fermé et le train a ralenti.`,
      `Dehors, les passagers regardaient ce qu'ils avaient vu pour la dernière fois à l'écran. Juste en face d'eux, il y avait d'énormes rivières et lacs, mais ils étaient remplis de liquide noir et gluant qui était noir ou orange. Soudain, un gros son tonitruant a secoué le train.`,
    ],
    71:[
      `Des machines géantes et des camions massifs semblaient se déplacer sur la terre vide. Les camions et les machines étaient si gros qu'ils faisaient ressembler le train à un jouet. Ils ressemblaient à des gratte-ciel sur roues, masquant le soleil. Seule la roue d'une machine mesurait plus de 10 mètres de haut. Le bruit est devenu presque insupportable.`,
      `Puis le train s'est arrêté net sur son passage. Tout le monde regardait partout, confus et figé sur place. Creamy pleurait. "Je n'aime pas cet endroit", a-t-elle crié (le seul moyen de se faire entendre). "Faut-il s'arrêter ici?"`,
      `"On dirait que le train veut que nous nous arrêtions ici", a-t-il crié The Fluff, "peut-être pour rencontrer plus d'animaux."`,
      `Personne n'a bougé. Le ciel devant les fenêtres s'assombrisait et un grondement encore plus fort a secoué le train.`,
    ],
    72:[
      `Tout le monde a mis des nageoires, des ailes, des sabots, des mains à leurs oreilles. Ils ont regardé d'où venait le son, d'un énorme trou dans le sol et ont vu un énorme glissement de terre. Cela a duré ce qui semblait être une heure. Finalement, il y eut le silence.`,
      `"Hum," a-t-elle dit Joanna, "nous avons de la compagnie."`,
      `De l'autre côté du train, une grosse tête remplissait la fenêtre. C'était l'un des animaux qu'ils avaient vus du ciel. Ses yeux étaient écarquillés et ses narines flamboyantes.`,
      `The Fluff a été le premier à sortir. "Allez," a-t-il appelé aux autres. En un instant, les filles et les animaux avaient entouré l'animal effrayé. Le Fluff atteignit ses nageoires pour lui tapoter l'épaule.`,
      `"C'est bon," a-t-il dit "Nous pouvons vous aider. Je m'appelle The Fluff et ce sont mes amis. Cela s'appelle un train et cela nous emmène en voyage pour aider d'autres animaux."`,
    ],
    73:[
      `"Voici Creamy et Flora. Il y a aussi d'autres animaux dans le train. Nous avons aussi deux enfants humains, Joanna et Marina. Elles sont géniales. Elles sont de notre côté aussi."`,
      `"Je m'appelle Lauren et je suis un caribou femelle", a-t-elle déclaré le nouveau venu. "Et ça devient vraiment dangereux ici."`,
      `"Comment quelqu'un peut-il vivre ici?" a-t-elle gémi Creamy. "C'est tellement sale et horrible."`,
      `"J'ai de la peine pour toi," a-t-elle crié Flora. "Je pensais que ce qui m'était arrivé était le pire, mais maintenant ..."`,
      `"Ils ont tous perdu leurs parents", a-t-elle expliqué Marina. "Leurs terres sont également en train de se détériorer. Veux-tu venir avec nous et quitter cet horrible endroit?"`,
      `"A moins que ... tu aies de la famille ici que tu ne veux pas quitter," a-t-elle demandé Joanna.`,
      `"Ma famille est maintenant dans le Grand Nord. Je les ai laissés là-bas pour commencer ma propre vie," a-t-elle déclaré Lauren,`,
      `"mais ce n'est pas un endroit où vivre".`,
      `'Le visage de Marina s'est illuminé.  "Nous aurions peut-être vu votre famille", s'est-elle exclamée. "Nous avons vu plus de caribous marcher vers le nord à travers de magnifiques terres."`,
      `"Oui, c'était peut-être mon troupeau," a-t-elle répondu Lauren.  "Ils se déplacent beaucoup maintenant que notre habitat est en voie de disparition. Toute cette région était autrefois la forêt boréale et ce que nous, au Canada, appelons muskeg, qui sont des tourbières. Puis les gens des compagnies énergétiques sont venus et ont commencé à le déchirer. pour atteindre les sables bitumineux ci-dessous. "`,
      `"Les sables bitumineux sont du bitume", a-t-elle expliqué Inoah, "une forme de pétrole très visqueuse. Aussi connu sous le nom d'asphalte, traditionnellement utilisé comme toit noir sur les routes. D'où le terme  sables bitumineux ".`,
      `"Quoi qu'il en soit," dit Lauren avec lassitude, "c'est un truc lourd et gluant. Pour le faire couler dans un pipeline ou dans un camion, les gens le font sauter avec de l'eau de rivière chauffée. Ensuite, ils mettent l'eau sale dans une sorte d'étangs"`,
    ],
    74:[
      `"Les étangs de résidus", a-t-elle intervenu Inoah. "-et ils s'infiltrent parfois dans les petits ruisseaux de la tourbière et éventuellement dans la grande rivière là-bas. C'est l'Athabasca."`,
      `"Je ne comprends pas. Cela semble être un terrible gaspillage de terre", a-t-il déclaré Edgar.`,
      `"J'ai fait un projet scientifique à ce sujet", a-t-elle déclaré Marina. "Les compagnies énergétiques affirment qu'elles le restaureront quand elles auront terminé."`,
      `"Mais il n'y a aucun moyen qu'ils puissent remplacer le muskeg," a-t-elle dit tristement Lauren. "Il faut des milliers d'années pour se développer à partir de plantes en décomposition lente comme la mousse, ce que je mange. Ils vont le remplacer par de fausses petites collines et étangs."`,
      `"Cela ressemble beaucoup à la chambre dans laquelle je vivais au zoo," a-t-elle dit Creamy.`,
      `"Ils ne devraient pas faire ça," a-t-elle dit Marina avec colère. "Ce n'est pas le zoo. C'est la nature sauvage. Nous ne devrions même pas brûler le pétrole qu'ils vont en tirer parce que cela produira trop de CO2. Non seulement cela, mais mes recherches indiquent que quand ils déterrent les tourbières, elles libèrent des tonnes de CO2 directement dans l'air. "`,
      `"Encore du CO2", s'est-elle faite l'écho Joanna. "Le train est arrêté ici depuis longtemps", a-t-elle remarqué soudain Flora.`,
      `"Nous devons rester positifs pour continuer à bouger", a-t-il déclaré The Fluff.`,
    ],
    75:[
      `"Comment pouvons-nous rester positifs," s'est-elle écriée Creamy, "alors que nous savons que tant de choses ne vont pas?`,
      `"Eh bien," a-t-il noté tranquillement The Fluff, "regardez ce que nous avons déjà fait. Nous avons tous parcouru des milliers de kilomètres pour arriver ici, et maintenant nous avons ce train pour nous aider." "Et ça nous a réunis", a-t-elle ajouté Flora. "Ensemble, nous pouvons accomplir ... "`,
    ],
    76:[
      `"...n'importe quoi," a-t-elle dit Sally. "Nous pouvons aider tous les animaux du monde, comme vous le vouliez."`,
      `"Eh bien, je sais que vous m'avez aidé," a-t-il dit Tomás.`,
      `"Vous êtes venu me chercher au bon moment", a-t-elle déclaré Lauren.`,
      `Les animaux se sont souris tous.`,
      `Joanna, qui tenait Sally, a regardé Marina. "Je n'arrive pas à notre place," a-t-elle dit. "Vous avez tous traversé tant de difficultés et nous ne l'avons pas fait.`,
      `"Tout est de notre faute", a-t-elle approuvé Marina. "Les gens ont été stupides. Je ne sais vraiment plus ce que nous faisons dans ce train."`,
      `D'habitude, elle n'était pas du genre à abandonner, et alors qu'elle s'éloignait la tête baissée, les animaux étaient inquiets. Tout était calme. Un petit oiseau jaune flottait vers la fenêtre, mais personne ne l'a remarqué.`,
      `"Je sais ce que tu fais ici," a-t-elle couiné soudain Sally. Elle a fait signe à Joanna de la soulever pour que les autres ont pu l'entendre.`,
      `"Vous nous écoutez. Vous nous soutenez. Vous nous aidez."`,
      `"Oui", a-t-il déclaré The Fluff, "je savais que quand je quitterais ma maison, je devrais trouver des gens et apprendre à leur faire confiance. D'après ceux que j'ai rencontrés en Antarctique, je pouvais voir qu'ils étaient en charge - enfin - peut-être pas en charge, mais ils étaient plus puissants que les animaux."`,
      `"Et nous savions que nous avions besoin d'aide, et le train est arrivé et il nous a amenés à vous deux", a-t- elle déclaré Creamy.`,
      `"Vous allez voir votre grand chef," a-t-elle dit Flora. "Vous nous trouverez l'aide dont nous avons besoin."`,
    ],
    77:[
      `Même Inoah a lâché un cri rassurant. "Oui!" Puis elle a giflé ses ailes sur son bec. Elle s'était même surprise avec sa soudaine explosion émotionnelle.`,
      `Marina a levé les yeux vers ses amis. "Je ne sais pas comment cela va fonctionner, mais si vous pensez qu'il y a de l'espoir après tout ce que vous avez vécu, alors nous devrions voir où ce voyage se termine."`,
      `Tout le monde est remonté dans le train. Encore une fois, des bulles ont commencé à remplir l'air. Le train a commencé à bouger, lentement au début, puis il a pris de la vitesse.`,
      `Soudain, Flora a sursauté. "Hé, où est Creamy?"`,
      `Tout le monde a regardé par la fenêtre pour voir Creamy courir après eux.`,
      `"Les phoques ne peuvent pas courir vite sur leurs nageoires", s'est-elle exclamée Flora.`,
    ],
    78:[
      `Elle a poussé la porte, sauté du train en marche et attrapé Creamy dans ses bras. Puis, avec ses jambes fortes, elle a commencé à bondir pour rattraper le train.`,
      `"Elles n'y arriveront jamais," a-t-elle hurlé Sally.`,
      `The Fluff avait une idée. "Hé," a-t-il crié, "si nous sommes tristes, le train ralentira."`,
      `Tout le monde s'est mis à pleurer, faisant semblant au début, puis de vraies larmes à l'idée de perdre leurs amis. Le train s'est arrêté en hurlant, immobile. Flora a grimpé, portant toujours Creamy.`,
      `Creamy était effrayée et soulagée. Flora était essoufflée. Tout le monde était très content.`,
      `"Flora, merci beaucoup. Tu m'as sauvée. J'aurais été laissée pour compte. Tu es incroyable!" Creamy a-t- elle crié. Flora l'a serrée dans ses bras.`,
      `Une fois de plus, maintenant qu'ils étaient heureux, des bulles sont apparues de la cheminée et le train a commencé à accélérer. Flora et Creamy se sont installées l'un à côté de l'autre.`,
      `"C'est une étrange amitié," a-t-il observé Edgar. "Mais depuis que je suis dans ce train, des choses assez étranges se sont produites."`,
      `Le reste des animaux se sont dirigés au lit. À la tombée de la nuit, ils savaient qu'ils se déplaçaient encore à travers la campagne car il y avait peu de lumières. Bientôt, ils pouvaient entendre l'eau se précipiter, encore plus fort que les roues du train.`,
    ],
    79:[
      `"Nous suivons la rivière Athabasca," a-t-elle dit Lauren d'un ton endormi. "C'est une grande rivière sur laquelle mon troupeau compte pour l'eau. Elle coule vers le nord, alors je suppose que nous nous dirigeons également vers le nord," a-t-elle bâillé.`,
      `Ça a été une longue journée. Seul, The Fluff continuait de regarder pensivement par la fenêtre, le menton sur sa nageoire, écoutant le bruit de l'eau qui passait.`,
    ],
    80:[
      `C'était calme quand les filles et les animaux se sont réveillés à l'aube.`,
      `Lauren a dit, "Je pense que nous approchons de l'embouchure de la rivière Athabasca - la Peace-Athabasca- Delta."`,
      `"Le plus grand delta intérieur d'eau douce au monde", a-t-elle annoncé Inoah. "C'est le parc national Wood Buffalo», a-t-elle poursuivi Lauren, ignorant le perroquet femelle. "Aucune machine n'est autorisée ici. C'est une réserve pour le buffle et les grues blanches ..."`,
      `"Qui a failli disparaître", a-t-elle ajouté Inoah. "C'est leur seul habitat naturel de nidification, mais avec l'aide des humains - beaucoup d'aide - ils ont pu s'adapter à d'autres endroits protégés," a-t-elle déclaré Lauren. "Leur réserve d'hiver sur la côte du golfe du Texas est également un sanctuaire."`,
    ],
    81:[
      `Soudain, le train s'est arrêté juste à temps pour éviter de tomber sur un très grand oiseau blanc qui traversait les voies dans un boa à plumes bleues et un chapeau de plumes violet. Elle leur a fait un signe poliment avec une longue aile.`,
      `"Deux mètres et demi!" a-t-elle crié Inoah. "C'est comme ça que son envergure est longue!"`,
      `"Environ dix fois plus longtemps que le vôtre,"a-t-elle dit sèchement Flora.`,
      `Puis Flora a crié par la fenêtre. "Vous êtes une grue blanche, n'est-ce pas, madame?"`,
      `L'oiseau a eu l'air surpris d'être abordé par un ours polaire.`,
      `Le Fluff s'est approché de la porte du train et l'a ouverte - "Salut! Nous venions parler de toi, ou plutôt de ton peuple, ou, euh, des oiseaux. Entre - je veux dire."`,
    ],
    82:[
      `La grue a gracieusement décliné son offre. Alors The Fluff est descendu et s'est promené avec le grand oiseau. Pendant que la grue parlait, The Fluff faisait de son mieux pour suivre les énormes progrès de la grue.`,
      `Creamy gloussait. Finalement, les deux oiseaux ont secoué des ailes - plutôt une aile et une nageoire - et The Fluff est revenu à bord.`,
      `"Eh bien? Qu'est-ce qu'elle a dit?" Creamy a-t-elle demandé.`,
      `"Je te le dirai bientôt." The Fluff a répondu, "mais pour le moment, nous devons la suivre. Son nom est Lady Athabasca. Je lui ai raconté notre voyage. Elle rejoint son troupeau de migration de dix-neuf autres grues alors qu'ils se dirigent vers le sud. Nous devons les suivre attentivement, car ils ont quelque chose à nous montrer."`,
      `"Mais leur migration prend environ deux à trois semaines!" s'est-elle exclamée Inoah.`,
      `"Je ne pense pas que nous ayons trois semaines à perdre", a-t-elle déclaré Marina. "Tu ferais mieux de lui dire que nous ne pouvons pas y aller."`,
      `"Et si nous les embarquions dans le train à la place et que nous pouvions y être dans quelques heures?" a-t- elle demandé Flora.`,
      `"Euh, Flora? Je ne vois vraiment pas comment nous pourrions installer vingt oiseaux avec une envergure de 2 1/2 mètres sur ce train," a-t-il froncé les sourcils Tomás.`,
      `"Je sais! Je sais! Je sais!" a-t-elle couiné Sally. Elle était tellement excitée qu'elle sautait d'un pied en l'air. "Comment fait-elle ça?" a-t-il demandé Edgar.`,
      `"Qu'y a-t-il, Sally? A-t-elle demandé Joanna, la prenant dans ses bras et la tendant pour qu'ils puissent tous entendre.`,
      `Sally était à bout de souffle à cause de tous ses sauts. "Mettez ... (bouffée, bouffée) .. eux (bouffée, bouffée) .. sur ... (bouffée, bouffée)"`,
      `"Sur quoi?" a-t-elle interrompu Flora. "Sally, qu'est-ce que tu essaies de dire?"`,
    ],
    83:[
      `"En haut du train..." Sally a-t-elle couiné, fronçant ses sourcils miniatures à Flora.`,
      `"En haut du train ..." a-t-il répété tout le monde en se regardant.`,
      `"Bien," a-t-il dit The Fluff. "Ils peuvent dire au train où aller et simplement monter là-haut."`,
      `"Et nous savons tous que le train peut aller aussi vite qu'il le souhaite", a-t-elle déclaré Marina.`,
      `"Surtout quand nous nous sentons heureux et positifs!" Creamy a-t-elle ajouté.`,
      `"Excellente idée, Sally!" a-t-il dit Tomás en lui donnant une version de la taille d'une salamandre d'un high five. Marina est sortie pour expliquer l'idée au gros oiseau. Tout le monde a regardé Lady Athabasca parlait avec son troupeau. À son retour, les filles et les animaux sont tous sortis du train. Ils ont regardé vers le haut en tant que Lady Athabasca, puis dix-neuf autres oiseaux géants ont atterri légèrement sur le toit du train. Ensuite, ils se sont installés en position assise comme s'ils étaient tous des œufs à couver.`,
    ],
    84:[
      `"Hé, si nous allons aller vite, ils vont avoir besoin de ceintures de sécurité!" a-t-il dit Tomás. Au signal, les ceintures sont apparues comme par magie sur le dos de chaque grue et attachées au toit. Lady Athabasca a baissé les yeux de son long nez - ou plutôt de son bec - vers tous les autres. "C'est une circonstance très inhabituelle, mais ... nous sommes prêts," répondit-elle.`,
      `Ensuite, le train a fait un bond en avant et tout le monde s'est entassé à bord alors qu'il prenait de la vitesse. Lauren a tendu un sabot et tiré The Fluff en dernier.`,
      `"Wow," a-t-il appelé, "ça doit être ce à quoi ressemble le vol!"`,
      `"Nous avons besoin d'une lucarne pour les voir!" a-t-il dit Tomás. "Je n'ai jamais vu des grues voler sans voler!" "Tu n'as même jamais vu de grues, Tomás," lui a-t-elle rappelé Sally.`,
    ],
    85:[
      `Un puits de lumière de la taille de tout le toit est apparu. Ci-dessus, les grues se parlaient comme si elles le faisaient tous les jours.`,
      `"Eh bien, je suppose que ce sera la migration la plus facile que les gars aient jamais eue", a déclaré Edgar.`,
    ],
    86:[
      `La côte du golfe`,
      `Alors que les animaux s'installaient pour le voyage, le train a commencé à se déplacer très rapidement. Flora a remarqué que le plancher de la voiture était soudainement devenu transparent. Elle a regardé la terre qui se déplaçait en contrebas, comme si elle regardait par la glace. "Regardez, tout le monde, a-t-elle crié." Nous pouvons voir le sol!"`,
      `Ils ont tous regardé en bas, puis au-delà du train, des tuyaux géants qui traversaient le pays.`,
    ],
    87:[
      `"Cela ressemble à des milliers de Tinkertoys," a-t-elle observé Joanna. "Ouais, mais ils sont réels", répondit Marina, "et beaucoup plus dangereux que les Tinkertoys. Ils apportent du pétrole dans les ports de la côte du Golfe, et à partir de là, il est envoyé partout dans le monde pour être brûlé! J'ai étudié ces choses."`,
      `"Lady Athabasca m'en parlait," a-t-il déclaré The Fluff. "On l'appelle le réseau de pipelines Keystone. Les grues le survolent deux fois par an lorsqu'elles migrent, donc elles le connaissent bien. Elle a dit que parfois, les tuyaux se brisent et fuient du pétrole sur la terre et dans les ruisseaux et les lacs. C'est particulièrement dangereux. pour Lady Athabasca et d'autres oiseaux aquatiques, car s'ils sont couverts d'huile, ils ne peuvent ni nager ni se réchauffer, et ils meurent. " Il a baissé les yeux tristement.`,
    ],
    88:[
      `Le train suivait l'un des grands tuyaux jusqu'à ce qui ressemblait à une immense usine, crachant de la fumée.`,
      `"Qu'est-ce que c'est?" a-t-elle demandé Flora.`,
      `"C'est une raffinerie" , a-t-elle expliqué Marina. "C'est là que le pétrole brut est transformé en essence pour les voitures, et d'autres choses."`,
      `"Cela implique-t-il de brûler?" Creamy a demandé.`,
      `"Oui," a-t-elle crié Inoah. "Ils doivent chauffer l'huile à 200 degrés."`,
      `"Celsius," a-t-elle ajouté Joanna.`,
      `Juste à ce moment-là, l'écran s'est abaissé pour leur montrer des images de pétrole brûlé dans tout le pays, créant un nuage de CO2 et de méthane qui semblait remplir l'air jusqu'à l'espace extra-atmosphérique.`,
      `"Tu vois?" a-t-elle dit Marina. «C'est pourquoi nous n'avons plus de temps. Nous devons convaincre le president d'arrêter tout cela. Maintenant! Il n'y a plus de place pour cela."`,
    ],
    89:[
      `"Oui, mais nous nous dirigeons vers le sud maintenant, pas vers l'est", a-t-elle fait remarquer Flora.`,
      `Ils voyageaient depuis seulement une heure, mais ils pouvaient voir par le plancher qu'ils étaient maintenant sur le sable. Par la fenêtre, alors que le train commençait à ralentir, la vue était sur un rivage qui approchait. "Ce doit être la côte du golfe du Texas, où les oiseaux migrent pour l'hiver", a-t-elle déclaré Joanna.`,
      `En un instant, le train s'est immobilisé sur une belle plage. C'était le coucher du soleil.`,
      `Les ceintures de sécurité disparurent dans les airs, et les grues se sont levées gracieusement, se sont étirées et descendues vers l'eau pour boire et pêcher. Les autres animaux suivirent.`,
      `"Oh, c'est magnifique," a-t-elle dit Marina. "Les plages sont ma chose préférée!"`,
      `Tout le monde a regardé la scène paisible d'un troupeau de grues pataugeant dans l'eau.`,
      `"Allons nager!" Flora a dit.`,
      `Tous ceux qui savaient nager l'ont fait. Creamy, Flora et The Fluff ont passé un si bon moment qu'ils ne voulaient pas sortir de l'eau.`,
      `Alors que l'obscurité tombait sur le golfe, ils ont été les derniers à revenir sur le rivage.`,
      `"C'était parfait!" a-t-il dit The Fluff. "Même s'il faisait un peu chaud."`,
      `"Voyez,"a-t-elle dit Joanna. "Les grues veulent que nous les suivions."`,
      `Au crépuscule, les grues s'étaient toutes levées de la mer et leur faisaient signe. Lady Athabasca a atterri et a expliqué: "Si nous nous dépêchons, nous pouvons vous montrer autre chose que nous voulions que vous voyiez."`,
      `Elle a commencé à sauter avec ses longues jambes et a pris Sally dans ses ailes. Tout le monde a couru après elle. Certains animaux étaient vraiment rapides. Marina et Joanna chevauchaient Tomás et Flora, tandis que Creamy et The Fluff faisaient du stop sur Lauren.`,
    ],
    90:[
      `"Vous ébouriffez mes plumes!" a-t-elle hurlé Inoah de l'épaule de Joanna, en se tenant à son chapeau de paille. Quelques minutes plus tard, ils avaient rejoint les grues au bord du golfe qui s'assombrissait. La pleine lune scintillait sur les vagues, sauf dans les endroits où l'eau était complètement sombre.`
    ],
    91:[
      `Lauren a eu l'air surprise, "Hé, qu'est-ce que c'est dans l'eau? J'ai déjà vu ce truc! J'en ai même bu une fois, par accident. Ne me dis pas que c'est comme les Tar Sands."`,
      `"Eh bien, oui, c'est un peu. C'est le problème," a-t-elle dit Lady Athabasca. "C'est ce que nous voulions que vous voyiez. Il s'agit de pétrole, déversé par une énorme marée noire. Onze millions de litres de pétrole. Il provenait de l'explosion d'une plate-forme pétrolière offshore appelée Deepwater Horizon. Il a tué de nombreux êtres, y compris des humains. "`,
      `"De l'huile, encore une fois," a-t-elle dit malheureusement Lauren. "Je pensais que je m'échappais du pétrole." La lune est passée derrière les nuages et la nuit était complètement sombre. Les animaux, qui avaient été joyeux, ont marché maintenant tristement vers le train.`,
    ],
    92:[
      `Seul, The Fluff est resté au bord de la mer. Il pouvait voir la façon dont l'océan a été affecté ici a rappelé des souvenirs de sa patrie en voie de disparition. "D'abord, le plastique, puis la glace fondante, maintenant l'huile", a-t-il dit à haute voix. "S'il y avait un moment pour que tout s'arrête, c'est maintenant. Je dois dire à Marina que nous devons aller voir son président tout de suite! Nous devons lui dire que tout cela est à cause de ces choses appelées combustibles fossiles! C'est tout leur faute! Il a besoin d'aider! "`,
      `"Eh bien, je pourrais avoir besoin d'aide," a-t-il dit quelqu'un d'une petite voix.`,
      `Regardant d'où venait le son, The Fluff a vu un petit canard vert et brun illuminé au clair de lune, flottant sur les douces vagues du rivage. Le Fluff s'est penché pour voir la petite créature.`,
      `"Zolo! Je m'appelle Zolo! Tu es le plus gros canard que j'aie jamais vu! Es-tu venu pour me sauver? Ma famille est partie! Je n'ai plus personne! Il y a encore de l'huile sur mes plumes ou je rentrerais là où ma grand-mère vit! Pouvez-vous m'aider? Avez-vous aussi de l'huile sur vous? Et pourquoi votre bec est-il si long? " a-t-il dit le petit bonhomme en un seul souffle. Puis, finissant sa pensée, il ajouta: "J'aime vraiment ton chapeau."`,
      `Souriant, The Fluff n'a rien dit. Il a pris doucement le petit canard et s'est assis, le tenant dans sa nageoire. Bientôt, le petit gars s'est endormi, mais The Fluff est resté éveillé pendant un long moment, regardant pensivement la mer.`,
      `Le lendemain matin, Marina, Joanna, Tomás et Sally se sont dirigés vers la crique. (Creamy et Flora n'ont pas pu résister à une baignade de plus, et avaient été loin dans l'océan pendant des heures.) Sur le chemin de la crique, les filles et les animaux ont rencontré The Fluff, en se parlant. Alors qu'il se rapprochait, ils se sont rendus compte qu'il parlait avec un très petit être à côté de lui.`,
    ],
    93:[
      `"Ne le laisse pas parler," a-t-il averti The Fluff dans un murmure. "Il ne s'arrête jamais."`,
      `Soudain, un sifflet a sifflé de plus loin sur la plage. Le train commençait à bouger sans eux! Hors de l'eau en un éclair, tout le monde a couru pour prendre le train. Joanna a été la dernière à monter à bord. Elle s'est tournée pour voir les grues, toutes debout dans les vagues peu profondes. Lady Athabasca a levé une aile. "Au revoir!" elle a appelé. "Maintenant que vous le savez, vous pouvez aider à trouver une solution pour tous les oiseaux aquatiques. Bonne chance!"`,
      `"Bonne chance à toi!" Joanna a crié. La larme aux yeux, elle a grimpé sur le train en marche.`,
    ],
    94:[
      `"C'était vraiment agréable d'être à nouveau dans l'océan", a-t-elle déclaré Creamy avec nostalgie. Elle a regardé Flora, qui a regardé The Fluff, qui aidait le petit canard à enlever un vieux bandage très sale.`,
      `"Ce qui t'est arrivé?" a-t-elle demandé Flora.`,
      `"Oh, ce n'est rien," a-t-il répondu le petit canard des bois. "Ce n'est qu'une vieille blessure et de l'huile séchée. Ma famille avait bien pire. Je n'ai pas été avec eux là où ils nageaient quand l'huile est arrivée si vite. Ils sont tous morts dans cette explosion dont ton ami, le gros oiseau, parlait."`,
      `"C'est horrible," a-t-elle dit Flora. "Je sais ce que ça fait. J'ai perdu mes parents aussi."`,
      `"Je n'ai pas vu la grosse explosion, mais je l'ai entendue!" le canard a continué.`,
      `"Je nageais, et je plongeais, et quand je suis venu prendre l'air, je ne pouvais pas voir. Tout ce dont je me souviens après cela, c'est l'obscurité. Puis, quand je me suis réveillé, j'étais dans une petite chambre en verre avec quelques autres canards des bois. Il y avait des gens là-bas. J'ai cherché mes parents, mais ils n'ont pas été là. J'ai entendu un humain qui a dit qu'il n'y avait qu'un seul canard qui avait survécu de cette région. Puis j'ai réalisé qu'ils parlaient de moi, que ma famille avait disparu. J'ai été submergé de tristesse! J'ai pleuré et pleuré! Je suis toujours triste même si je suis beaucoup plus âgé. J'ai deux ans maintenant - pratiquement adulte! Eh bien, je n'ai pas l'air si grand, parce que je suis petit pour mon âge, mais je peux prendre soin de moi tout seul! Je ne sais justement pas comment j'arracherai jamais tous ces trucs noirs de mes ailes. J'essaye et j'essaye, mais c'est collé! "`,
      `"Zolo," a-t-il dit The Fluff. "Laissez-moi te faire visiter."`,
      `Joyeusement, Zolo a sauté du siège pour suivre son nouveau 'Big Brother'. "J'ai de la peine pour ce canard, mais il peut certainement parler", a-t-il déclaré Edgar.`,
    ],
    95:[
      `Le ruisseau Acorn`,
      `Deux jours plus tard, le train roulait à travers des champs verdoyants puis des collines fortement boisées. Les filles et les animaux ont vu de profonds ravins avec des rivières tumultueuses. Alors qu'ils commençaient à se lever, Tomás a pris une profonde inspiration et soupiré, "Montagnes".`,
      `Juste à ce moment, dans un virage serré, ils ont vu devant eux un bac à sable géant et vallonné avec des camions qui rampaient partout.`,
      `"Que se passe-t-il là-bas? Où est la montagne?" a-t-il demandé Tomás.`,
    ],
    96:[
      `"C'est une mine de charbon", a-t-elle déclaré Marina. ''Auparavant, le charbon était creusé dans de profonds tunnels à l'intérieur des montagnes, mais maintenant, les sociétés d'énergie coupent simplement les sommets de montagnes entières pour obtenir le charbon.''`
    ],
    97:[
      `"Couper les sommets des montagnes?" Sally a couiné.`,
      `"Comment peuvent-ils faire ça?" a-t-il crié Tomás. "S'ils coupaient ma montagne, ils détruiraient toutes les maisons que ma famille a jamais construites, depuis mes grands, grands abuelitos - je veux dire, grands- pères. Ma montagne est notre maison depuis très, très longtemps!"`,
      `"Beurk, c'était brusque. Quel atterrissage brutal!" a-t-elle fait remarquer Joanna en se levant vertigineusement. "Je suppose que nous ferions mieux de voir pourquoi nous nous sommes arrêtés." Tomás et Sally étaient déjà dehors, scrutant un ruisseau.`,
    ],
    98:[
      `C'était une étrange couleur orange rougeâtre.`,
      `"Berk," a-t-elle dit Lauren. "C'est la même couleur que l'eau chez moi."`,
      `"Oh, regardez les pauvres petits vairons! Ils sont si immobiles. Pourquoi ne nagent-ils pas?" a-t-il dit Tomás. Il voulait les ramasser, mais ne voulait pas leur faire peur.`,
      `Quelques mètres en aval, ils ont vu un poisson femelle en robe d'été, avec un chapeau et un parasol. Sally a couru pour lui parler.`,
      `"Quel est ton nom?" elle a demandé. Le poisson femelle s'est arrêtée de nager et a regardé la salamandre. "Eh bien, bonne journée à tous! Bienvenue au ruisseau Acorn," a-t-elle dit avec un air traînant. "Je m'appelle Bobbi Sue Sunfish. Puis-je vous offrir des rafraîchissements?" Elle a indiqué l'herbe de la rivière. "Pardonnez-moi si c'est un peu amer. On me dit que c'est ce qu'on appelle 'toxique'."`,
      `"Toxique?" Est-ce que ça va? "a-t-il demandé Tomás avec inquiétude.`,
      `"Est-ce que je vais bien?" Bobbi Sue a éclaté. "Bien sûr, je ne vais pas bien! Regardez cette eau! Regardez- les!" Elle a poussé son parasol vers les vairons immobiles.`,
      `"Euh, je suis désolé! Au fait, je suis Tomás," a-t-il dit.`,
      `"Et je suis Sally," a-t-elle dit la salamandre. "Nous sommes venus ici sur un train magique qui nous amène d'un endroit à l'autre où il y a des problèmes avec la terre."`,
      `"Et de l'eau," a-t-il ajouté Tomás.`,
      `"Et l'air," a-t-elle dit Sally. "Il semble qu'il y ait des problèmes partout."`,
      `''Ce sont nos amis'', a-t-elle dit alors que les autres apparaissaient. ''Nous avons tous rejoint le train. Nous nous dirigeons vers Washington, DC, pour parler au Monsieur le Président des États-Unis des problèmes que nous rencontrons à cause de...''`,
      `"Changement climatique et pollution!" Inoah a-t-elle intervenue.`,
    ],
    99:[
      `"Au fait," a-t-il demandé Edgar, "pourquoi cette eau est-elle rose?"`,
      `"Parce que quelqu'un a versé quelque chose appelé fluide de fracturation dans mon ruisseau", a-t-elle déclaré Bobbi Sue.`,
      `"Oh, non," a-t-elle dit Creamy. "Quelle horreur."`,
      `"Oui, mais qu'est-ce que la fracturation hydraulique?" a-t-il demandé Edgar.`,
      `"Cela se produit lorsque les gens utilisent beaucoup trop d'eau pour pousser les produits chimiques profondément dans le sol pour obtenir de l'huile sale, et lorsque l'eau remonte, tout est grossier et rouge." a- t-elle dit Bobbi Sue."Tout pour gagner de l'argent!"`,
      `"Attendez ... pour qu'ils obtiennent de l'argent du terrain?" Sally a couiné.`,
        `Inoah a soupiré bruyamment. "Non, non, non," a-t-elle braillé, "incorrect!" - puis a ajouté, sous son souffle, "Pourquoi dois-je tout expliquer?"`,
      `Joanna a déplacé Inoah sur le sol, mais l'oiseau a simplement ébouriffé ses plumes et a poursuivi: "La fracturation hydraulique est un processus par lequel les sociétés d'énergie injectent de l'eau et des produits chimiques dans le sol à haute pression pour expulser le gaz naturel - ou le méthane - de la roche."`,
      `"Oh, pour qu'ils puissent le vendre pour de l'argent?" a-t-elle demandé Bobbi Sue.`,
      `"C'est vrai!" a-t-elle dit Inoah.`,
      `"Mais quels sont ces produits chimiques?" Edgar ia ntervenu.`,
      `''C'est, mon ami, un secret,'' a-t-elle braillé Inoah.`,
    ],
    100:[
      `"Quoi qu'il en soit, cela rend mes amis et moi très malades", a-t-elle déclaré Bobbi Sue. «Cette rivière était appelée une 'ressource en eau exceptionnelle. Cela signifie qu'elle était autrefois la plus propre de l'État. Ma maman et mon papa étaient toujours si fiers que nous vivions ici. Nous avons vraiment eu de la chance que les mines de charbon ne l'ont pas fait. polluer l'eau. "`,
      `Le Fluff réfléchit un instant. ''Bobbi Sue'', at-il demandé, ''aimeriez-vous nous rejoindre et nous aider? Nous avons tous des histoires comme la vôtre, et nous pourrions vous utiliser pour en raconter plus.'' ' 'Et bien, tant que le Washington monsieur peut aider mon ruisseau, je le suis!'' a-t-elle déclara en lui tendant son parasol.`,
    ],
    101:[
      `"J'ai une idée!" a-t-il dit Zolo en tournant en rond et en sautant dans le train. Quelques instants plus tard, il est revenu haletant. "Je viens de demander au train de faire une piscine d'eau douce, et c'est ce qu'il a fait! Alors, Bobbi Sue, si vous souhaitez vous joindre à nous, nous avons une place pour vous."`,
      `Creamy a agité follement sa nageoire et a dit, "Nous devrions faire un bar de nourriture à côté de la piscine pour que Bobbi Sue puisse obtenir de la nourriture quand elle le veut."`,
      `"Oooooo!" a-t-il dit Zolo. "Nous pouvons tous aussi utiliser la piscine. C'est-à-dire ceux qui aiment nager," a-t-il ajouté rapidement, jetant un coup d'œil à Edgar.`,
      `"Je suppose qu'il se fait tard," a-t-il dit Edgar, "et nous devrions remonter dans le train."`,
      `Les animaux ont accepté et sont monté à bord.`,
      `Tomás a souri au poisson. "Très bien, Bobbi Sue, je vais te chercher, d'accord?"`,
    ],
    102:[
      `"Très bien", a-t-elle dit, "mais fais attention à ces griffes."`,
      `Quelques instants plus tard, alors que Bobbi Sue nageait dans sa nouvelle piscine, elle a vu The Fluff, Flora, Zolo, Creamy, Joanna et Marina arriver au bord.`,
      `"Ça va si nous nageons avec toi?" a-t-elle demandé Flora.`,
      `"Tant que vous n'oubliez pas vos bonnes manières et que vous ne me mangez pas," a-t-elle répondu Bobbi Sue. Les animaux ont acquiescé.`,
      `Zolo a été le premier à entrer, éclaboussant alors que Bobbi Sue s'écartait. De retour dans le wagon, Edgar et Tomás se sont assis ensemble. Tomás a fait signe à Edgar en voyant un petit oiseau jaune passer. Ils ont commencé à somnoler.`,
    ],
    103:[],
    104:[
      `New York`,
      `Alors que le train traversait la campagne, Marina était heureuse de voir des panneaux solaires, mais il y avait aussi de nombreux puits de fracturation. Elle s'est rassie sur son siège et a soupiré, "Nous devons aller voir le président, et vite."`,
      `À cette pensée, le train a commencé à se déplacer si rapidement que la vue est devenue floue. En moins d'une heure, tout le monde à bord pouvait voir les toits de la ville. Le train a commencé à ralentir.`,
      `"Oh regardez!" s'est exclamée Joanna. "Je pense que nous nous dirigeons vers New York!"`,
    ],
    105:[
      `"Eh bien, puisque nous sommes ici, allons faire un tour!" a-t-elle ajouté Marina avec enthousiasme. "D'accord," a-t-il dit The Fluff, "Allons-y!"`,
      `Les filles et les animaux ont eu un pique-nique dans Central Park, puis, à la suggestion de Flora, ont assisté à la comédie musicale de Broadway, The Lion King. En sortant de la pièce de Times Square, ils se sont arrêtés pour regarder des artistes de rue faire du breakdance et des figures de gymnastique.`,
      `Sally n'était pas impressionnée. ''Je ne vois pas pourquoi les gens leur donnent de l'argent. Je pourrais le faire facilement les yeux fermés. Regardez."`,
      `Elle a fait un saut sur la main de Marina. "Attends," a-t-elle dit Joanna. "Je vais chercher quelque chose pour que les gens peuvent vous voir."`,
      `Elle s'est dirigeée vers une boîte abandonnée appuyée contre un parcmètre. En ramassant le carton poussiéreux, elle a vu une forme sombre en dessous.`,
      `"Arghhhhh! Un rat!" a-t-elle hurlé.`,
    ],
    106:[
      `Debout sur ses pattes de derrière, le rat avait l'air détaché, calme et posé. Il portait une chemise et un pantalon teintue au noeud avec un grand signe de paix sur la jambe gauche. Des dreadlocks descendaient sur ses épaules et, dans une oreille pointue, brillait une seule boucle d'or.`,
      `"Qu'est-ce que c'est que tout le problème, petite amie? Je croirais que tu n'as jamais vu un rat. Jeeeesshhh," a-t-il dit le rat, croisant les bras et fronçant les sourcils avec patience, regardant Joanna une fois de plus. "Tu as du coulot! Tu as emporté ma parfaite cachette, soigneusement placée au milieu de toute la nourriture des humains. Je te demande de la remplacer immédiatement et de me donner un peu de ce bretzel dans ta main pour mon problème." Sous le choc, Joanna lui a rapidement donné la moitié de son bretzel géant, marmonnant des excuses.`,
      `"Merci," a-t-il dit le rat. "Au fait, Zingo est le nom. Et qui êtes-vous et ce zoo ambulant?"`,
      `"Hey!' Creamy a dit: "Ce n'est pas un zoo."`,
    ],
    107:[
      `"Ouais," a-t-il dit Edgar. "et nous sommes bien plus grands que toi!" Parle pour soi ", a-t-elle couiné Sally. "Mais tu as ruiné ma performance de rue! "`,
      `"Spectacle de rue?" Zinog a dédaigné. "Qu'est-ce que vous faites ici?"`,
      `"Nous sommes ici parce que le réchauffement climatique menace toutes nos maisons, et nous voulons en parler au président", a-t-elle déclaré Flora.`,
      `"C'est Flora", a-t-il déclaré Tomás. "Je suis Tomás, et voici ma meilleure amie, Sally, et voici Lauren, Creamy, Edgar, Marina, The Fluff, Joanna, et-"`,
      `"Salut! Je suis Zolo!" Le petit canard a couru avec enthusiasm vers Zingo. "Je suis un canard des bois. J'ai deux ans! Tu as l'air bizarre. Oh regarde! J'ai l'impression d'être finalement plus grand que quelqu'un! Sauf Sally, bien sûr. C'est une salamandre. Nous sommes venus ici par magie. train. Il nous a fallu beaucoup de temps pour aller de Texas à ici. N'est-ce pas incroyable? The Fluff and Creamy a commencé à San Diego, je pense que c'est vraiment cool. Et toi? J'aime ta chemise. Tu sais où je peux en acheter un comme ça? Y a- t-il un endroit où aller nager près d'ici? "`,
      `Booom! le fracas du tonnerre a roulé dans le ciel. En quelques secondes, tout le groupe a été trempé alors que la pluie tombait en draps. Les gens couraient se mettre à l'abri, hélaient des taxis et montaient dans les bus.`,
      `Zingo a pris les choses en main. "Suivez-moi, les gars! J'ai un super endroit pour attendre la fin de la tempête".`,
      `Les animaux et les filles ont suivi en courant. Zingo les a emennés dans une allée entre deux restaurants où une fuite au-dessus de leur tête les a protégés. Zingo a froncé les sourcils et a regardé vers le ciel. "Hmmmm," a-t-il dit. "Ce n'est pas bon. Pouvez-vous sentir l'air? Il se passe quelque chose.`,
    ],
    108:[
      `Je ne pense pas que nous voyons bientôt la fin de ce temps. "`,
      `"Oui, c'est comme ça juste avant une énorme tempête de neige!" a-t-elle dit Flora.`,
      `Les animaux ont tous commencé à parler à la fois.`,
      `Zolo a interrompu, "Je ne sais pas ce qu'est la neige, mais je connais bien les tempêtes! Une fois quand je--" Le Fluff l'a pris à la hâte, parlant toujours et a plaqué une nageoire sur sa bouche.`,
      `''Nous devons regagner le train'', a-t-elle crié Marina.`,
      `Un autre grondement a rrésonné dans les bâtiments de Times Square. Alors que la foudre éclairait le ciel, un véhicule a apparuu devant eux, ruisselant de pluie mais entouré de bulles et de papillons. C'était le train! "Ohhh ... cool. Quel train," a-t-il dit Zingo avec approbation.`,
    ],
    109:[
      `"Allons-y!" a-t-elle crié Marina.`,
      `"Allons-y!" a-t-il dit Zingo. "Comme ça? Génial! C'est encore mieux que ces autobus Flower Power qu'ils avaient dans les années 60."`,
      `Il suivit les autres, les yeux brillants.`,
    ],
    110:[
      `La tempête`,
      `À minuit, la tempête faisait rage. Le train se balançait d'avant en arrière comme un bateau sur une mer en colère.`,
      `"Tenez fermement, tout le monde!" a-t-il crié The Fluff.`,
    ],
    111:[
      `En même temps, de mauvaises choses ont commencé à se produire dans toute la Big Apple.`
    ],
    112:[
      `Les lumières se sont éteintes dans le Lower Manhattan. Des vents de 200 km/h ont détruit les lampadaires, abattu les poteaux électriques et arraché des arbres; des torrents de pluie ont inondé les toits et les auvents. Mais le pire était l'océan.`,
      `Le train filait vers le sud de l'île de Manhatten. En moins d'un minute, le train avait atteint Battery Park, où l'eau de mer affluait. Instantanément, un énorme mur d'eau a surgi au-dessus du train, le mettant à l'arrêt. Mais au lieu d'être submergé, le train ... flottait!`,
      `Tous les animaux se sont assis sur le plancher sous le choc. Marina et Joanna regardaient par les fenêtres dans le ciel sombre.`,
      `Sally a été la première à parler. "Qu'est-ce qui se passe?" elle a demandé.`,
      `Zolo a sauté sur un siège et a commencé à danser en disant: "Wow! C'est génial et incroyable! J'adore vraiment ça! Où sommes-nous? Wow! Tellement d'eau!"`,
      `Le Fluff la rassi sur son siège. "Zolo, nous sommes au milieu d'une énorme tempête. Ne danse pas!" a-t-il dit le Fluff en lui mettant la ceinture de sécurité de Zolo.`,
      `Au moment où The Fluff a levé les yeux, un rugissement a empli les wagons. Tout le monde regardait avec horreur.`,
      `"Oh, non!" a-t-elle crié Joanna. "Ce n'est pas juste une tempête. C'est un ouragan! Nous devons tous nous asseoir et nous attacher, maintenant!"`,
    ],
    113:[
      `Alors que l'ouragan a emporté le train dans le ciel, l'écran roulait vers le bas, se balançant sauvagement à travers les fenêtres. Le train était ballotté de haut en bas, fait demi-tour et jeté d'avant en arrière. Des objets étaient jetés partout. Zolo s'est accroché à The Fluff les yeux fermés.`,
      `Soudainement, la porte du congélateur s'est ouverte et du poisson congelé a traversé le wagon, frappant The Fluff à la tête. Il a perdu connaissance.`,
      `Les filles et les animaux étaient tous terrifiés. Les plus jeunes, comme Sally et Zolo, ont commencé à paniquer. Cela a rendu les autres encore plus effrayés et la plupart d'entre eux ont commencé à pleurer. "Crôoooooooooooh!"`,
      `"Ça c'était quoi?"a-t-elle demandé Creamy, les yeux écarquillés. Immédiatement, tout le monde est devenu très calme et les jeunes animaux ont cessé de courir.`,
    ],
    114:[
      `''Tout ira bien'', ont-ils entendu Marina dire. Tous les yeux étaient rivés sur elle. Les animaux ont vu que Joanna réconfortait Inoah. Ce bruit horrible était Inoah pleurant? Ils ne l'avaient jamais entendue pleurer. "Je sais ... tous les ... faits, mais je ... n'ai jamais vraiment ... compris ... comment ... comment ...," a-t-elle haleté Inoah.`,
      `"Comment quoi?" a-t-elle demandé Joanna en serrant Inoah dans ses bras.`,
      `"Comme c'est vraiment mauvais!" a-t-elle finalement dit Inoah.`,
      `Zingo caressait sa barbe ébouriffée, plongé dans ses pensées.`,
      `Alors que Joanna réconfortait Inoah, les autres animaux ont remarqué un mouvement à l'autre bout du wagon. The Fluff se réveillait.`,
      `Quand il a repris connaissance, il a ressentit une vive douleur dans la tête et vu des formes floues. Alors que sa vision s'éclaircissait, il a vu les autres animaux le regarder, inquiets.`,
      `"Heureusement!" a-t-elle dit Flora. "Je pensais que tu étais mort!"`,
      `Le Fluff s'est assis et désigné l'écran. "Voyez-lea!"`,
      `L'écran diffusait des informations télévisées disant: "Super Storm Sandy vient de frapper New York City à Battery Park avec une vague de 4 mètres. C'est la tempête vue de l'espace."`,
      `Ensuite, l'écran a montré des séquences vidéo d'un satellite. Quand cela avait fini, Marina s'est rendue compte que le train ne basculait plus.`,
      `"Wow," a-t-elle dit. "C'est tellement paisible ici. Nous devons être dans l'œil de la tempête."`,
      `"Quel est l'œil de la tempête?" a-t-il demandé The Fluff avec de grands yeux en grignotant le poisson congelé.`,
      `"C'est l'intérieur de l'ouragan," cria Inoah en essuyant les larmes de ses plumes.`,
      `"Bizarre," a-t-elle dit Flora. "Regarde! Ciel bleu! L'œil est different du reste de la tempête." Elle a débouclé sa ceinture de sécurité pour se diriger vers la fenêtre.`,
    ],
    115:[
      `Creamy l'a rejoint. "Regarde les nuages tout autour de nous. Ils sont si gros!"`,
      `Les deux se sont rassises, les yeux remplis d'émerveillement. Distraitement, elles ont bouclé leur ceinture de sécurité à nouveau - juste à temps.`,
      `Boum! Ils avaient heurté le mur de l'œil de la tempête. Le train a été soudainement jeté en l'air comme s'il ne pesait rien. Puis, tout aussi soudainement, il a volé vers la Terre, a atterri sur ce qui restait du trottoir et a recommencé à traverser New York.`,
    ],
    116:[
      `La tempête était terminée, mais une grande partie de la ville était en ruines. L'écran leur montrait des images de destruction de tout New York. Même le Statue de la Liberté et Ellis Island avaient été touchés.`,
      `"Aïe!" s-t-elle écrié Flora. ''Quel terrible gâchis!''`,
      `Creamy a recommencé à pleurer quand tout le monde a vu les maisons de la ville, les bâtiments, les métros et les parcs qui avaient été inondés ou ruinés. Inoah a recommencé à pleurer - ou hurler. "Ça ne sert à rien," a-t-elle sangloté. "Je ne pense pas que cela puisse être réglé par le président."`,
      `Zingo s'est levé sur une chaise et a effrayé: "Vous avez raison! Vous ne pouvez pas laisser cela au président. Il est peut-être en charge, mais il n'est qu'un mec. Le vrai changement prend un mouvement, un événement, un tout nouvel état d'esprit . Vous devez agir. En raison du changement climatique, cette tempête est l'une des plus importantes à avoir jamais frappé la côte Est ", a-t-il déclaré, en agitant son poing. ''Il est de votre responsabilité de créer votre propre avenir merveilleux.`,
    ],
    117:[
      `Vous pouvez tous faire cela! Je sais que vous pouvez! Regardez ce qu vous avez déjà fait! " Il a serré son poing en l'air et les animaux ont poussé tous des cris d'accord.`,
      `''Maintenant, je dois vous quitter'', a-t-il dit. "Je dois voir comment ma maison a tenu. Mais rappelez-vous: vous avez le pouvoir de résoudre ce problème. Croyez-moi sur parole comme quelqu'un qui sait comment changer pour survivre. Au revoir!"`,
      `"Au revoir!" ont-ils crié tous les animaux. "Bonne chance, Zingo!" Ils l'ont regardé se glisser à travers une fissure près de la porte et disparaître.`,
      `"Oui!" a-t-elle dit Marina. "Zingo a raison! Nous devons lancer un mouvement! Nous avons besoin que tous les animaux et les enfants se joignent à nous, partout dans le pays!"`,
      `"Oui!" The Fluff était d'accord. "C'est pourquoi nous devons agir! C'est pourquoi je suis venu vous trouver!" Il a désigné Marina et Joanna. ''Vous tous! a-t-il ajouté.`,
      `"Et c'est pourquoi le train nous a réunis!" Creamy a observé, les yeux brillants.`,
      `À présent, les animaux et les filles étaient tous debout. Il y eut un moment de silence alors qu'ils se regardaient, remplis de pensées et d'émotions différentes. Soudainement, ils ont tous crié un collectif "Ouais!" De minuscules feux d'artifice remplissaient l'intérieur du train. Dehors, les papillons et les bulles ont commencé à pulluler si épais que personne ne pouvait voir par les fenêtres. "Tenez fermement!" a-telle crié Joanna. Un tourbillon de couleurs, une rafale de vent et puis - pouf - ils étaient dans le jardin de la Maison Blanche.`,
    ],
    118:[],
    119:[
      `La Maison Blanche`,
      `Lorsque les animaux et les enfants sont descendus du train, ils ont vu un chien bondir vers eux. En un mouvement, il a sauté sur Joanna, la jetant au sol avec beaucoup de léchage joyeux.`,
      `''Chipper!'' a-t-elle crié Joanna. "Tu m'as tellement manqué!"`,
      `Maintenant, quatre autres personnages couraient vers eux.`,
      `'Les mamans et les papas!'' Marina et Joanna ont crié aussitôt. Ils ont étreint et étreint les filles et les six humains étaient entourés par les animaux.`,
    ],
    120:[
      `Tout le monde, sauf Bobbi Sue, qui regardait l'action, avec son visage contre le verre de son wagon.`,
      `Une fois que Joanna et Marina ont salué leurs parents, elles ont présenté les animaux. "Où avez-vous trouvé tous ces amis?" a-t-il demandé le père de Marina, regardant les animaux se rapprocher des parents, manifestement curieux mais un peu incertain.`,
      `"Eh bien, le train a trouvé un moyen de nous emmener à tous ces animaux parce qu'ils ont besoin d'aide," a- t-elle expliqué Joanna. "Ils ont tous perdu leur habitat ou leur famille - ou les deux - à cause du changement climatique." Chipper s'est dirigé vers Creamy, qui, prise par surprise, s'est renversée. Après qu'elle se soit levée, Chipper lui a donné une inclinaison sympathique. Creamy a souri et a fait un câlin au chien. Les adultes avaient tous l'air inquiet et la mère de Joanna a demandé,`,
    ],
    121:[
      `"Est-ce qu'ils vont bien maintenant?"`,
      `"Eh bien, c'est pourquoi nous sommes ici," Marina a indiqué la Maison Blanche. "Ils ont encore besoin d'aide pour leurs maisons."`,
      `"Alors, voulez-vous nous dire que vous avez voyagé avec tous ces animaux à travers le pays dans un train?" a-t-elle demandé la mère de Joanna.`,
      `"Aussi le Canada!" est-elle intervenue Inoah.`,
      `La mère de Joanna avait l'air surprise. "C'est pour ça que ça a été si calme à la maison", s'est-elle exclamée. ''Inoah était avec toi!''`,
      `"Une question," a-t-elle dit Marina. "Comment avez-vous su que nous étions ici?"`,
      `"Eh bien," a-t-elle répondu la mère de Marina, "P'tit Croissant nous a dit où vous étiez."`,
      `"Qui est Croissant?" a-t-elle demandé Joanna.`,
      `Avant que sa mère n'ait pu dire quoi que ce soit, un canari jaune portant un petit béret bleu s'est posé sur la tête de Joanna. Les animaux se sont tous regardés avec surprise, et Joanna a tenu sa main pour le laisser s'asseoir là.`,
      `"Oh attendez! Nous l'avons vu!" a-t-elle crié Marina. ''Joanna, tu te souviens? Chaque fois que nous nous sommes arrêtés à un nouvel endroit, nous avons vu un flou de flash jaune derrière la fenêtre!''`,
    ],
    122:[
      `"Croissant, c'était toi? Est-ce que tu nous suivais tout ce temps et tu aidais nos parents à tout savoir?" Joanna a-t-elle éclaté de rire, le visage proche du petit oiseau. Croissant a pépié bruyamment, hoché la tête assez vigoureusement pour secouer son petit béret, puis gonflé sa poitrine avec fierté. Tout le monde a rigolé.`,
      `"Il a dû te suivre," a-t-il dit le père de Marina. ''Ce petit oiseau nous a tous donné des notes sur votre voyage, à partir du jour de votre départ. Ils étaient tous signés 'Croissant, du Pastry Express'.''`,
      `''Oh, alors vous n'étiez pas inquiets? a-t-elle dit Marina.`,
      `"Eh bien, nous étions toujours inquiets," a-t-elle répondu la mère de Marina.`,
    ],
    123:[
      `"Je veux dire, ce n'est pas tous les jours que vos enfants décollent dans un train propulsé par des bulles!" ''Et les papillons'', a-t-elle ri Joanna, désignant les centaines de papillons monarques volant dans le jardin à la recherche d'asclépiades.`,
      `"Quoi qu'il en soit, quand nous avons appris que vous étiez à New York pendant la tempête, nous étions vraiment inquiets, alors nous avons décidé de partir immédiatement", a-t-il déclaré le père de Joanna.`,
    ],
    124:[
      `''Tu vois, nous avons gardé les notes que Croissant nous a apportées'', a-t-elle dit la mère de Marina en brandissant un épais tas de petits post-it jaune vif.`,
      `Soudain, les parents se sont rendu compte que l'ours polaire avait commencé à grogner. "Qu'a-t-elle dit?" a- t-il demandé le père de Joanna. "Elle a demandé: 'Que faisons-nous maintenant que nous sommes ici?'" a-t-elle répondu Marina.`,
      `"Comment pouvez-vous les comprendre?" La mère de Joanna a dit et a regardé les animaux.`,
      `"Je n'en suis pas sûre," a-t-elle dit Joanna.`,
      `À ce moment, Sally s'est mise à pleurer. 'Oh, qu'est-ce qu'il y a?'' le père de Marina a demandé, l'inquiétude se répandant sur son visage`,
      `"Elle dit," a-t-elle traduit Marina, "qu'elle n'arrive pas à croire qu'elle a eu la chance de-" ses yeux se sont larmoyés, et Joanna a continué la traduction-`,
    ],
    125:[
      `"pour trouver un groupe d'amis qui se soucient tellement d'elle et qui ont risqué leur vie pour l'aider." a-t-elle finit Joanna, essayant de retenir ses larmes puis elle courut chercher Sally. Les parents ont souri gentiment pendant que Marina séchait ses larmes.`,
      `"Je ne peux pas non plus croire à quel point j'ai de la chance de vous avoir trouvés les gars!" s'est-elle exclamée Lauren. "Attendez," dit la mère de Joanna. "Je comprends cela!" Les parents étaient tous d'accord et se souriaient.`,
      `"J'ai de la chance aussi! s'est-il écrié The Fluff.`,
      `"Moi aussi!" tous les animaux se sont écriés et se sont serrés dans leurs bras, les filles et les parents. Quand tout le monde avait séché leurs larmes de bonheur, les animaux ont commencé à se donner un coup de coude.`,
      `Tout le monde a regardé vers l'endroit où les animaux pointaient avec excitation. C'était l'entrée de la Maison Blanche.`,
      `"Ils disent, ils disent ..." Oh là là! C'est le président! ''s'est-elle exclamée Marina. Et elle avait raison. Le président des États-Unis se tenait sur les marches de la Maison Blanche, souriant. Il faisait un signe de la main et tenait dans sa main un papier collant jaune vif - Croissant lui avait rendu visite aussi.`,
    ],
    126:[]
  },
  buriedSunlight:{
    1:[
      `L'ensoleillement enterré`,
      `Comment les combustibles fossiles ont changé la Terre`,
    ],
    2:[
      `Je suis le soleil, ton étoile d'or.`,
      `Même de 93 millions de miles, je rechauffe la terre, les mers, l'atmosphère, et je chasse le noir des journées.`,
      `Mon énergie donne la lumière et la vie à ta petite planète.`,
    ],
    3:[
      `Ah, oui, toutes les choses vivantes – y compris toi – ont besoin de l'enégie pour survivre et se déveloper.`,
      `Et on se sert de l'énergie de réchauffer les foyers et de faire cuire la nourriture, de bouger les voitures, les`,
      `trains et les navires, de faire marcher les ordinateurs et les machines.`,
      `Main d'où vient l'énergie?`,
    ],
    4:[
      `Ah, oui, toutes les choses vivantes – y compris toi – ont besoin de l'enégie pour survivre et se déveloper.`,
      `Et on se sert de l'énergie de réchauffer les foyers et de faire cuire la nourriture, de bouger les voitures, les trains et les navires, de faire marcher les ordinateurs et les machines.`,
      `Main d'où vient l'énergie?`,
    ],
    5:[
      `Voici ce qui se passe:`,
      `Les plantes vertes partout attrapent ma lumière et l'emploient de prendre le dioxide de carbon (CO2) de l'air.`,
      `Elles emploient mon ensoleillement-énergie de construire des chaines du charbon – du sucre – qui constitue leurs feuilles, leurs tiges et leurs fruits.`,
      `Comme elles réalisent la photosynthèse, elles libèrent l'oxygène (O2) dans l'air – l'air que tu inspires.`,
    ],
    6:[
      `Tu engloutes les plantes!  Tu manges leurs chaines du charbon!`,
      `Tu inspire l'oxygène que les plantes expiraient.`,
      `Tu l'emploies de casser les chaines d'absorber leur énergie pour que tu puisses bouger, vivre et grandir.`,
      `Tu expires les chaines cassées du charbon comme le dioxide de carbon (CO2).`,
      `C'est le cycle de vie.`,
    ],
    7:[
      `Le cycle de vie est presque en équilibre parfait.`,
      `Toutes les plantes ensembles font un petit peu plus que tous les animaux et les microbes mangent.`,
      `Et les plantes expirent un petit peu plus d'oxygène que les animaux et les microbes inspirent.`,
      `La différence est petite mais au fil des millions d'années, un petit peu chaque année fait une grande différence.`,
      `En fait, ces deux petites différences t'ont donné les deux plus grands trésors: tout l'oxygène que tu inspires et les combustibles fossiles.`,
      `Voici ce qui s'est passe:`,
    ],
    8:[
      `Il y a des milliards d'années, bien avant les dinosaures traînaient la Terre, il n'y avait pas de vie sur la terre.`,
      `Il n'y avait pas d'oxygène. Rien du tout.`,
      `À l'épo	que, la seule vie sur la Terre était une petite microbe qui flottait dans les mers et de petits animaux les mangaient.`,
      `Ensuite, certaines de ces petites microbes ont évolué en les petites plantes vertes.`,
      `Elles attrapaient ma lumière, faisaient des chaines du charbon-sucre!-et respiraient de l'oxygène dans la mer.`,
      `Chaque année, elles dirigeaient un peu plus d'oxygène que les petits animaux inspiraient.`,
      `Au fil des millions et millions d'années, l' oxygène supplémentaire faisait de bulles jusqu'à ce que l'eau et l'air étaient enrichés de l'oxygène.`,
      `Chaque année, les petites plantes faisaient un peu plus chaines de charbon que les petits animaux de mer pouvaient manger.`,
      `Après des millions et millions d'années, les chaines supplémentaires de charbon s'accumulaient au fond de l'océan.`,
    ],
    9:[
      `Au fil de plus en plus des millions d'années, les plus grands animaux et plantes ont évolué et bougé`,
      `de l'océan à la terre.`,
      `Et comme les petites plantes dans la mer, les plantes terrestres faisaient plus de chaines de charbon que les animaux et les microbes pouvaient manger.`,
      `Chaque année ces chaines non-mangées de charbon – les plantes mortes – s'accumulaient.`,
      `Comme encore plus de millions et millions d'années passaient, toutes ces plantes mortes (avec mon énergie attrapée) étaient enterrées sous des couches de sol, sur la terre et au fond de l'océan.`,
      `Enterrées de plus en plus profondément, elles étaient écrasées...et transformées en charbone et gaz combustible.`,
      `Le combustibles fossiles – mon ancienne ensoleillement enterré.`,
    ],
    10:[
      `Ces combustibles fossiles restaient profondément dans la terre pendant des millions d'années, comme les nouveaux créatures évoluaient.`,
      `Il y a 150 millions d'années  Il y a 60 millions d'années  Il y a 15 million d'années`,
      `Certaines plantes ont disparu et plus de nouveaux ont évolué, mais le cycle de la vie continuait.`,
      `Éventuellement, seulement il y a plusieurs cent milles d'années – vous, les humains, vous avez évolué et vous vous dispersiez sur la Terre.`,
      `Vous vous serviez des feux pour vous rechauffer et pour faire cuire la nourriture.`,
      `Un jour vous avez découvert...`,
    ],
    11:[
      `des combustibles fossiles!`,
      `Mon ensoleillement enterré!`,
      `Vous avez très vite commencé à brûler ces combustibles fossiles, le charbone, le pétrole et le gaz combustible que le cycle de vie a fait pendant des millions et millions d'années.`,
      `De nos jours, vous vous servez de mon ancien ensoleillement-énergie d'alimenter votre monde.`,
      `Quand vous brûlez les combustibles fossiles, vous cassez leurs chaines de charbon, prenez de l'oxygène de l'atmosphère et dispersez le dioxide de carbon (CO2).`,
    ],
    12:[
      `Chaque année, comme on brûle de plus en plus des combustibles fossiles, de plus en plus du`,
      `CO2  lève dans l'atmosphère de la Terre.`,
      `Les arbres, les plantes et les océans attrapent ce qu'ils peuvent, mais ce n'est pas assez d'empêcher le CO2 de s'accumuler dans le ciel.`,
      `Les scientifiques le mesurent.  Ils remarquent que le CO2 s'augment de plus en plus vite.`,
      `Et vous, les humains, vous détruisez les forêts et déblayez le terrain pour planter les cultures.`,
      `Ça met de plus en plus de CO2 dans l'air.`,
      `Certains gens disent, “Et alors?”`,
    ],
    13:[
      `Et voici:  Le CO2 est partie de la couche des gaz autour de la Terre.`,
      `Mon ensoleillement passe par la couche et rechauffe la Terre, mais beaucoup de chaleur rebondit à l'espace.`,
      `Certains des gaz, comme le CO2, attrape la chaleur et la garde.`,
      `Ça aide à contrôler la température de la Terre`,
      `Quand ces gaz sont perdus de la couche, la Terre se refroidit.`,
      `Quand ils s'augmente dans la couche, la Terre se rechauffe.`,
      `Quand on brûle les combustibles fossiles et détruit les forêts, on met de plus en plus de CO2 dans la couche de la Terre chaque année.`,
      `Cela attrape de plus en plus de chaleur et rechauffe la terre et la mer.`,
    ],
    14:[
      `Ton monde a commencé de sentir ces changements.`,
      `La glace de l'Arctique et les glaciers sont en train de fondre.`,
      `Les mers se rechauffent et certaines commencent à grimper sur la terre.`,
      `Le rechauffement cause les plus féroces orages, les sécheresses et les inondations.`,
      `Partour sur la Terre, le temps est un peu différent du passé récent.`,
      `Dans l'avenir, ces changements seront plus sévères....`,
    ],
    15:[
      `Certains gens disent toujours, “Et alors?”`,
      `La Terre a beaucoup changé pendant les milliards d'années depuis sa naissance.`,
      `Autrefois, il faisait beaucoup plus chaud et beaucoup plus froid, qu'aujourd'hui.`,
      `“Pourquoi pas brûler les combustibles fossiles?”`,
      `Ces gens ont partiellement raison.`,
      `Moi, ton soleil, j'ai regardé ta planète se rechauffer et se refroidir beaucoup de fois.`,
      `La Terre était, autrefois, si froide que la glace couvrait les plages où tu nages de nos jours.`,
      `La Terre était, autrefois, si chaude que les palmiers poussaient où il neige de nos jours.`,
      `Le froid et la chaleur, ta planète traverse ces changements et ce processus va continuer.`,
      `MÊME SANS LES HUMAINS!`,
    ],
    16:[
      `Mais ces changements se sont passés au fil des centaines de milliers d'années.`,
      `Les créatures vivantes avaient assez de temps de s'adapter au changement du climat.`,
      `Mais le résultat de brûler des combustibles fossiles, c'est des énormes quantités du CO2 dans l'atmosphère dans seulement un peu de centaines d'années.`,
      `C'est très, très, très, très vite!`,
      `Combien de créatures vivantes pouvront s'adapter aux changements si rapides?`,
      `Comment va-t-il changer la vie des humains?`,
      `Il y a 400 000 années`,
      `Il y a 300 000 années`,
      `Il y a 200 000 années`,
      `Il y a 100 000 années`,
      `Maintenant`,
    ],
    17:[
      `Des milliards de tonnes des combustibles fossiles – mon ensoleillement-énergie – sont toujours enterrées dans les voûtes de la Terre.`,
      `Si on va les brûler, le CO2 de la Terre, la couche deviendra de plus en plus épaisse.`,
      `La Terre va se rechauffer tellement que personne ne peut prédire comment elle va changer.`,
      `Mais elle va changer.`,
    ],
    18:[
      `Est-ce que vous, les humains, continuerez-vous à brûler de plus en plus de combustibles fossiles chaque année et risquerez-vous les changements que cela va apporter?`,
      `Ou travaillerez-vous ensemble pour utiliser plus lentement ma lumière solaire ancienne, trouverez-vous d’autres sources d’énergie, et inventerez-vous des façons d’éclaircir la couverture de CO2?`,
      `Le choix vous appartient.`,
    ]
  }
};