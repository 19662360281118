import {useLocation} from "react-router-dom";
import {parseParams} from "../../../utilities/parseQueries";
import curriculum from "../../../data/curriculum/curriculum";
import {NextUp} from "../../views/nextUp/nextUp";
import {Read} from "../../views/read/read";
import {Video} from "../../views/video/video";
import {Experiment} from "../../views/experiment/experiment";
import React from "react";

export function SessionRouter(props){

  let location = useLocation();
  let pathname = location.pathname;
  let queryStr = location.search;

  let queries = parseParams(queryStr);

  function materialRouter(){
    if (pathname.indexOf('/session') !== -1){

      let sessionId = queries.id;
      let partNo = queries.partNo || 1;

      let session = curriculum.sessions[sessionId - 1];
      if (session){
        let material = session.material[partNo - 1];
        if (queries.next){

          let status;

          if (material.partNo < session.material.length) {
            partNo++;
            status = "materialComplete";
            material = session.material[partNo - 1];

          } else if (partNo === session.material.length) {

            if (queries.id === curriculum.sessions.length) {
              status = "courseComplete";

            } else {
              sessionId ++;
              partNo = 1;
              status = "sessionComplete";
              session = curriculum.sessions[sessionId - 1]
              material = session.material[partNo - 1]
            }
          }
          return <NextUp status={status} material={material} language={props.language} />;
        } else {
          let {content, sessionInfo} = material;
          switch (material.content.format){
            case 'book':
            case 'discussion':
              return (
                <Read
                  content={content}
                  sessionInfo={sessionInfo}
                  page={queries.page}
                  language={props.language}
                />
              )

            case "video":
              return <Video content={content} sessionInfo={sessionInfo} language={props.language} />;

            case "experiment":
              return(
                <Experiment
                  content={content}
                  sessionInfo={sessionInfo}
                  language={props.language}
                />
              );

            default:
              break;
          }
        }
      }
    }
  }

  return (
    <div className={'w-100 d-flex justify-content-center'}>{materialRouter()}</div>
  )
}