import React from "react";

let parse = require('html-react-parser');

export function TextBox(props) {

  function processText(line) {
    if (Array.isArray(line)) {

      if (line.length === 1 && typeof line[0] === 'string') {
        return <p style={{
          backgroundColor: 'var(--light-blue)',
          marginBottom: '0.5rem',
          padding: '0.5rem',
          borderRadius: '1rem',
          width: '100%',
          textAlign: 'center'
        }}>{parse(line[0])}</p>

      } else {
        let compiled = '';
        line.forEach(segment => {
          if (segment.hasOwnProperty('text')) {
            compiled += `<span class="discussSpeechBubble">${segment.text}</span>`
          } else {
            compiled += segment
          }
        })
        return <p style={{
          backgroundColor: 'var(--light-blue)',
          marginBottom: '0.5rem',
          padding: '0.5rem',
          borderRadius: '1rem',
          width: '100%',
          textAlign: 'center'
        }}>{parse(compiled)}</p>
      }
    } else if (line.hasOwnProperty('speech')) {
      return <span>{line}</span>
    } else if (typeof line === 'string') {
      return <p style={{
        backgroundColor: 'var(--light-blue)',
        marginBottom: '0.5rem',
        padding: '0.5rem',
        borderRadius: '1rem',
        width: '100%',
        textAlign: 'center'
      }}>{parse(line)}</p>
    } else {
      return line;
    }
  }

  return (
    props.textData ?
      <div className={'w-100'}>
        {props.textData.map(line => {
          return (processText(line)
          )
        })}
      </div>
      : null
  )
}