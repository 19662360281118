import {InfoBubble} from "../../components/infoBubble/infoBubble";
import React, {useEffect} from "react";
import Loading from "../../components/loading/loading";
import pageText from "../../../data/pageText";
import {Button} from "../../components/buttons/buttons";
import {ReadClose} from "./readClose";

export function ReaderControlBar(props) {

  function NarrationControl() {
    function handlePlay() {
      props.narrationControl("play");
    }

    function handlePause() {
      props.narrationControl("pause");
    }

    function handleRestart() {
      props.narrationControl("restart");
    }

    function showButtons() {
      function PlayButton() {
        //if (!props.narrationState) {
        return (
          <div className={"position-relative"}>
            <button className="playButton" id="play" onClick={handlePlay}>
              <div className="playSymbol"/>
            </button>
            <InfoBubble
              message={"click for audio"}
              type={""}
              id={"playInfo"}
              infoOff={props.infoOff}
              clearAll={props.setInfoOff}
            />
          </div>
        );
        //} else {
        //return loadingButton();
        //}
      }

      function PauseButton() {
        useEffect(() => {
          //const pause = document.getElementById('pause').focus();
        })
        return (

          <button className="pauseButton" id="pause" onClick={handlePause}>
            <div className="pauseSymbol"/>
            <div className="pauseSymbol"/>
          </button>
        );
      }

      function replayButton() {
        return (
          <button className="replayButton" id="restart" onClick={handleRestart}>
            <div className="stopSymbol"/>
          </button>
        );
      }

      function loadingButton() {
        return (
          <button
            className="loadingButton"
            id="restart"
            onClick={handleRestart}
          >
            <Loading class={"noBg"}/>
          </button>
        );
      }

      if (props.status === "playing") {
        if (props.audioBubble) {
          setTimeout(props.hideAudioBubble, 3000);
        }

        return (
          <div className="narrControls position-relative">
            {!props.narrationState ? <PauseButton/> : loadingButton()}

            {replayButton()}


          </div>
        );
      } else if (props.status === "pause") {
        return (
          <div className="narrControls">
            <PlayButton/>
            {replayButton()}
          </div>
        );
      } else if (props.status === "stop") {
        if (props.page !== props.content.startPage) {
          return <div className="narrControls"><PlayButton/></div>;
        }
      }
    }

    useEffect(() => {
      if (props.content.narration.get(props.language, false) && props.page !== props.content.startPage && !props.showedAudioBubble) {
        props.showAudioBubble();
      }
    })


    if (props.content.narration.get(props.language, false)) {
      return (
        <div className="NarrationControl controlBox" id="controls">
          <div className="narrControls" onMouseEnter={props.showAudioBubble}>
            {showButtons()}
            <InfoBubble
              message={pageText.labels.readLabels.controlReader.get(props.language)}
              type={""}
              id={"playInfo"}
              visible={props.audioBubble}
            />
          </div>
        </div>
      );
    }
  }
  function readerProgress() {
    function pageCounter() {
      /*

            function inputPage(){
                const pageInput = document.getElementById('pageInput');

                if (pageInput.value > 0 && pageInput.value <= props.content.endPage){

                    pageInput.classList.remove('error');
                    props.changePage(Number(pageInput.value),true);

                } else {

                    //pageInput.classList.add('error');
                }
            }

             */

      return (
        <div
          className="pageCounter darkAccent label col-auto"
          id={"pageCounterWrap"}
        >
          <p>{`${pageText.labels.readLabels.page.get(props.language)} ${props.page} / ${
            props.content.endPage
          }`}</p>
        </div>
      );
    }

    function pageSlider() {
      function slidePage() {
        const progress = document.getElementById("progressSlider");

        props.changePage(Number(progress.value), true);
      }

      return (
        <div
          className="progressSliderWrap label col-lg"
          id={"progressSliderWrap"}
        >
          <input
            type="range"
            min={props.content.startPage}
            max={props.content.endPage}
            value={props.page}
            id="progressSlider"
            onChange={slidePage}
          />
        </div>
      );
    }

    return (
      <div className={"col-md-auto readerProgressWrap"}>
        <div className="ReaderProgress controlBox row" id={"readerProgress"}>
          {pageCounter()}
          {pageSlider()}
        </div>
      </div>
    );
  }

  useEffect(()=>{
    const langMenu = document.getElementsByClassName('langDrop')[0];
    if (langMenu){
      langMenu.addEventListener('mouseover', function(){
        const langButtons = document.getElementsByClassName('langButton');
        Array.from(langButtons).forEach( button =>{
          button.addEventListener('click', function(){
            props.narrationControl('pause');
          });
        })
      })
    }
    window.addEventListener('keydown', ()=>{
      if (props.status === 'playing'){
        props.narrationControl('pause');
      }
    });
  })

  return (
    <div className="ReaderControlBar row" id="controlBar">
      <div className="mobileTop col-auto no-gutters">
        <div className={"col-sm-auto no-gutters"}>{NarrationControl()}</div>

        <div className={"mobile-close col-auto"}>
          <Button type={"close"} link={"/library"} id={"mobileClose"}/>
        </div>
      </div>

      {readerProgress()}

      <div className={"col-auto standard-close no-gutters"}>
        <ReadClose sessionInfo={props.sessionInfo} language={props.language} librarySection={props.content.format}/>
      </div>
    </div>
  );
}