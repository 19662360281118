import React from "react";

export function DiscussionCharacter(props) {
  function handleLoad() {
    props.imgLoaded(props.characterData.img);
  }

  return (
    <div className={`discGridCharacter of${props.gridSize} ${props.zoom ? 'zoom' : null}`}>
      <img
        src={props.characterData.img}
        onLoad={handleLoad}
        id={"discGridImg"}
        alt={props.characterData.label}
      />
      <p>{props.characterData.label}</p>
    </div>
  )
}