import { Video } from "../model/Video";
import { MultiLingual } from "../model/multiLingual";
import { RunTime } from "../model/RunTime";

const videos = {
  gweBackstory: new Video(
    "gweBackstory",
    new MultiLingual('The History of The Global Warming Express','La Historia del Tren del Calentamiento Global',"L'histoire de <i>L'express de réchauffement climatique</i>"),
    new MultiLingual("Learn the history of The Global Warming Express."),
    new MultiLingual(new RunTime(1, 6)),
    new MultiLingual(
      "XPXeCcqsMBg",
      "JCDB4Gu4k30",
      "AI9E3Dtx5gQ"
    )
  ),
};

export default videos;
