// React
import React from "react";
import pageText from "../../../data/pageText";

// Style Sheet
import "./congrats.css";
import { Button } from "../buttons/buttons";
import {buildNextUpSearch, buildSearch, } from "../../../utilities/buildURL";
import {parseParams} from "../../../utilities/parseQueries";
import {useLocation} from "react-router-dom";


export function Congrats(props){

  let queries = parseParams(useLocation().search);

  function readAgain(){
    //Set State
    props.changePage(1, true);

    //Remove Congrats Window
    const congrats = document.getElementById("complete");
    congrats.style.display = "none";
  }
  return (
    <div id="complete">
      <div className="completeWindow controlBox">
        <h1 className="label">{pageText.messages.materialComplete.get(props.language)}</h1>

        <div className="congratsOptions label">
          {props.sessionInfo ? (
            <Button
              text={pageText.buttons.completions.continue.get(props.language)}
              link={{
                pathname: 'session',
                search: buildNextUpSearch(queries)
              }}
              language={props.language}
              iconType={"rightArrow"}
            />
          ) : (
            ""
          )}

          <div className={"otherOptions"}>
            <Button
              text={pageText.buttons.completions.readAgain.get(props.language)}
              click={readAgain}
              language={props.language}
              iconType={"leftArrow"}
            />

            {props.sessionInfo ? (
              <Button
                text={pageText.buttons.completions.backSessions.get(props.language)}
                link={{
                  pathname: 'map',
                  search: buildSearch(
                    {
                      id: props.sessionInfo.sessionId,
                      partNo: props.sessionInfo.partNo
                    })
                }}
                language={props.language}
                iconType={"leftArrow"}
              />
            ) : (
              <Button
                text={pageText.buttons.completions.backLibrary.get(props.language)}
                link={{
                  pathname: 'library',
                  search: buildSearch({
                    section: props.format,
                    lang: props.language
                  })
                }}
                language={props.language}
                iconType={"leftArrow"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Congrats;
