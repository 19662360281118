import React, {useEffect} from "react";

import "./preloader.css";

export function Preloader(props) {
  function handleImgLoad() {
    props.preloadCached("img", props.preloadingImg);
  }
  function handleAudioLoad() {
    props.preloadCached("audio", props.preloadingAudio);
  }

  useEffect(()=>{
    document.getElementById('audioPreload').load();
  }, [props.preloadingAudio]);

  return (
    <div className={"preloader"}>
      <img src={`${props.preloadingImg}`} onLoad={handleImgLoad} alt={""} />
      <audio id={'audioPreload'} src={props.preloadingAudio} onCanPlayThrough={handleAudioLoad} />
    </div>
  );
}
