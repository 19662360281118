export function parseParams(str){
  let queryObj = {};
  str.slice(1).split('&').forEach( query =>{
    let splitQuery = query.split('=');
    if (splitQuery[0] && splitQuery[1]){
      if ( parseInt( splitQuery[1] ) ){
        splitQuery[1] = parseInt(splitQuery[1]);
      }
      queryObj[splitQuery[0]] = splitQuery[1];
    }
  });
  return queryObj;
}

export function parsePage(pathname){
  if (pathname.indexOf('guide') !== -1) {
    return 'guide'

  } else if (pathname.indexOf('map') !== -1 ){
    return 'sessions'

  } if (pathname.indexOf('library') !== -1){
    return 'library'

  } if (pathname.indexOf('resources') !== -1){
    return 'resources'

  } else if (pathname.indexOf('about') !== -1) {
    return 'aboutGWE'

  } else if (pathname === '/'){
    return 'splash'

  } else {
    return ''
  }
}